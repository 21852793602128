import { useRef, useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';

function VerificarCodigo({ codigoVerificacion, ejecutarAvisoPago, resetValues }) {
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(null); 
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef(null);

    const {
        nextStep,
        goToStep,
        handleStep
    } = useWizard();

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setError(null);
    };

    const isButtonDisabled = inputValue.trim() === '';

    const handleVerificarCodigo = async () => {
        if (inputValue === codigoVerificacion) {
            setIsLoading(true);
            setError(null);
            await ejecutarAvisoPago()
            setIsLoading(false);
            handleStep(); // Solo se ejecutará después de que 'ejecutarAvisoPago' se complete
            nextStep();
        } else {
            setError('El código ingresado no coincide con el código enviado al correo electrónico');
        }
    }

    const handleCancelStep = () => {
        resetValues();
        goToStep(0);
    }

    return (
        <div className='container'>
            <h2 className="mb-4 font-bold">Verificar código</h2>
            <p className='mb-4 text-sm'>Por favor ingrese el código de verificación enviado al correo electrónico: </p>
            <input type="text" required ref={inputRef} className={`input input-sm bg-gray-200 mb-4 focus:outline-none text-gray-500 ${error ? "border-red-500 border-1" : ""}`} placeholder='12345...' onChange={handleInputChange} />
            {error && <p className='text-red-500 mb-4 text-sm'>{error}</p>}
            <div role="alert" className="alert justify-start mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <p className='text-sm'>IMPORTANTE<br/>No debe cerrar ni refrescar esta página </p>
            </div>
            <div className='flex gap-3'>
                <button onClick={handleCancelStep} className='btn bg-transparent hover:bg-accent capitalize' disabled={isLoading} >Cancelar</button>
                <button className='btn bg-transparent hover:bg-accent capitalize' onClick={handleVerificarCodigo} disabled={isButtonDisabled | isLoading}>{!isLoading ? "Continuar" : "Por favor espere..."}</button>
            </div>
        </div>
    )
}

export default VerificarCodigo