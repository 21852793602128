import { useContext, useEffect } from "react";
import AsadaContext from "../../../context/asada/asadaContext";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";

// Framer motion variants
const pageVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  duration: 0.5,
};

function Historia() {
  const { asada } = useContext(AsadaContext);

  const { historia } = asada;

  return (
    <motion.div
      exit="out"
      animate="in"
      initial="out"
      variants={pageVariants}
      transition={pageTransition}
      className="flex flex-col justify-center"
    >
      <div className="px-4">
        <div className="text-center py-6 text-3xl lg:text-7xl">
          <h1 className="border-b pb-5">Nuestra Historia</h1>
        </div>
      </div>
      <div className="container mx-auto px-4">
        <div className="grid place-items-start mb-8 mt-1 mockup-window bg-base-300 border-0">
          {!historia ? (
            <div className="w-full text-center py-4">
              <p>Cargando...</p>
            </div>
          ) : (
            <pre className="whitespace-pre-wrap text-justify bg-base-200 px-6 py-4">
              <span className="font-sans">{historia}</span>
            </pre>
          )}
        </div>
      </div>

      <div className="mb-28"></div>
    </motion.div>
  );
}

export default Historia;
