import { useContext } from "react";
import ReglamentosContext from "../../../context/reglamentos/ReglamentosContext";
import { motion } from "framer-motion";
import { FaDownload } from "react-icons/fa";

function Reglamentos({ reglamentos }) {
  const { downloadReglamentoDocumentClient } = useContext(ReglamentosContext);

  const handleReglamentoDownload = (id) => downloadReglamentoDocumentClient(id); // Download reglamento

  return (
    <>
      <div className="sm:justify-center mt-12 ">
        <div className="text-center px-12  py-6">
          <div className="text-center py-6 text-3xl md:text-5xl lg:text-6xl mt-8">
            <motion.h2
              className="pb-5"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: {
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.5,
                  },
                },
                hidden: { opacity: 0 },
              }}
            >
              Reglamentos
            </motion.h2>
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 xl:grid-cols-3 sm:justify-center gap-5 px-12 my-12">
        {reglamentos.length !== 0 &&
          reglamentos.map((doc, i) => {
            return (
              <motion.div
                className="card bg-base-100 shadow-xl min-h-64"
                key={doc.id}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ delay: i * 0.4, duration: 0.7 }}
                variants={{
                  visible: {
                    opacity: 1,
                    x: 0,
                    y: 0,
                  },
                  hidden: { opacity: 0, x: "-200px", y: "-200px" },
                }}
              >
                <div className="card-body max-h-64 ">
                  <motion.h2
                    className="card-title justify-center"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={{
                      visible: {
                        opacity: 1,

                        scale: 1,
                        transition: {
                          delay: 1.1,
                          duration: 0.7,
                        },
                      },
                      hidden: { opacity: 0, scale: 0 },
                    }}
                  >
                    {doc.nombre}
                  </motion.h2>
                  <motion.p
                    className="flex justify-center"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={{
                      visible: {
                        opacity: 1,

                        transition: {
                          delay: 1.4,
                          duration: 0.7,
                        },
                      },
                      hidden: { opacity: 0 },
                    }}
                  >
                    {doc.descripcion}
                  </motion.p>
                  <motion.div
                    className="justify-center mt-3 card-actions"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={{
                      visible: {
                        opacity: 1,

                        transition: {
                          delay: 1,
                          duration: 1,
                        },
                      },
                      hidden: { opacity: 0 },
                    }}
                  >
                    <button
                      className="btn btn-accent btn-outline capitalize btn-sm"
                      onClick={() => {
                        handleReglamentoDownload(doc.id);
                      }}
                    >
                      Descargar&nbsp;
                      <FaDownload />
                    </button>
                  </motion.div>
                </div>
              </motion.div>
            );
          })}
      </div>
    </>
  );
}

export default Reglamentos;
