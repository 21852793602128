const getCISAInformacionAbonado = async (abonado) => {
  let xmls = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Body>
        <Consulta_ObtenerInformacion xmlns="https://www.cisaweb.com/AcueductosRecibos">
            <token>${sessionStorage.getItem("cisaToken")}</token>
            <empresa>${process.env.REACT_APP_ASADA_NUMBER}</empresa>
            <abonado>${abonado}</abonado>
        </Consulta_ObtenerInformacion>
      </soap:Body>
    </soap:Envelope>`;

  const url =
    process.env.REACT_APP_CISA_ENV === "production"
      ? "https://www.cisaweb.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx?op=Consulta_ObtenerInformacion"
      : "https://shlcisa.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx?op=Consulta_ObtenerInformacion";

  const response = await fetch(url, {
    body: xmls,
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "text/xml; charset=utf-8",
    },
  });

  const text = await response.text();
  let parser = new DOMParser();
  try {
    let xmlDoc = parser.parseFromString(text, "application/xml");

    // Extract data from the XML node 'Consulta_ObtenerInformacionResult'
    const resultNode = xmlDoc.querySelector("Consulta_ObtenerInformacionResult");

    if (resultNode) {
      return {
        id: resultNode.children[0].textContent,
        nombre: resultNode.children[1].textContent,
        telefono: resultNode.children[2].textContent,
        correo: resultNode.children[3].textContent,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const getCISABuscarRecibosPendientes = async (abonado) => {
  let xmls = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Body>
          <Consulta_BuscarRecibosPendientes xmlns="https://www.cisaweb.com/AcueductosRecibos">
            <token>${sessionStorage.getItem("cisaToken")}</token>
            <empresa>${process.env.REACT_APP_ASADA_NUMBER}</empresa>
            <abonado>${abonado}</abonado>
          </Consulta_BuscarRecibosPendientes>
        </soap:Body>
      </soap:Envelope>`;

  const url =
    process.env.REACT_APP_CISA_ENV === "production"
      ? "https://www.cisaweb.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx?op=Consulta_BuscarRecibosPendientes"
      : "https://shlcisa.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx?op=Consulta_BuscarRecibosPendientes";

  try {
    const response = await fetch(url, {
      body: xmls,
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "text/xml; charset=UTF-8",
      },
    });

    const text = await response.text();

    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(text, "application/xml");

    // Extract data from the XML node 'Table'
    const nodes = xmlDoc.querySelectorAll("Table");

    if (nodes) {
      const facturasArray = [];

      nodes.forEach((node) => {
        facturasArray.push({
          numFactura: node.querySelector("factura").textContent,
          monto: node.querySelector("monto").textContent,
          fecha: node.querySelector("periodo_char").textContent,
          vencimiento: node.querySelector("vencimiento").textContent,
          facturacion: node.querySelector("facturacion").textContent,
        });
      });
      return facturasArray;
    }
  } catch (error) {
    console.log(error);
  }
};

const postCISAGenerarToken = async (abonado, montoTotal) => {
  let xmls = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Body>
        <A_SINPE_GenerarToken xmlns="${
          process.env.REACT_APP_CISA_ENV === "production"
            ? "https://www.cisaweb.com/AcueductosRecibos"
            : "http://www.cisaweb.com/wsSINPE"
        }">
          <entrada>
            <codigo_empresa>${process.env.REACT_APP_ASADA_NUMBER}</codigo_empresa>
            <numero_abonado>${abonado}</numero_abonado>
            <monto_total>${montoTotal}</monto_total>
          </entrada>
        </A_SINPE_GenerarToken>
      </soap:Body>
    </soap:Envelope>`;

  const url =
    process.env.REACT_APP_CISA_ENV === "production"
      ? "https://www.cisaweb.com/wsSINPE/PagosSinpe.asmx?op=A_SINPE_GenerarToken"
      : "https://shlcisa.com/wsSINPE/PagosSinpe.asmx?op=A_SINPE_GenerarToken";

  const response = await fetch(url, {
    body: xmls,
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "text/xml; charset=UTF-8",
    },
  });

  const text = await response.text();
  let parser = new DOMParser();

  try {
    let xmlDoc = parser.parseFromString(text, "application/xml");

    // Extract data from the XML node 'Consulta_ObtenerInformacionResult'
    const resultNode = xmlDoc.querySelector("A_SINPE_GenerarTokenResult");

    if (resultNode) {
      return {
        codRespuesta: resultNode.children[0].textContent,
        message: resultNode.children[1].textContent,
        codEmpresa: resultNode.children[2].textContent,
        nombreEmpresa: resultNode.children[3].textContent,
        codAbonado: resultNode.children[4].textContent,
        nombreAbonado: resultNode.children[5].textContent,
        montoTotal: resultNode.children[6].textContent,
        correo: resultNode.children[7].textContent,
        token: resultNode.children[8].textContent,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const sinpeEjecutarAvisoPago = async (avisoPagoObj) => {
  const {
    origin,
    amount,
    phoneNumber,
    documentId,
    description,
    invoiceNumber,
    additionalInfo,
    userName,
    codEmpresa,
    apiToken,
    apiKeyEstablishment,
  } = avisoPagoObj;

  let xmls = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Body>
        <B_SINPE_EjecutarAvisoPago xmlns="${
          process.env.REACT_APP_CISA_ENV === "production"
            ? "https://www.cisaweb.com/AcueductosRecibos"
            : "http://www.cisaweb.com/wsSINPE"
        }">
          <entrada>
            <origin>${origin}</origin>
            <amount>${amount}</amount>
            <phone_number_source>${phoneNumber}</phone_number_source>
            <document_id>${documentId}</document_id>
            <description>${description}</description>
            <invoice_number>${invoiceNumber}</invoice_number>
            <additional_info>${additionalInfo}</additional_info>
            <user_name>${userName}</user_name>
            <api_token>${apiToken}</api_token>
            <api_key_establishment>${apiKeyEstablishment}</api_key_establishment>
            <cod_empresa>${codEmpresa}</cod_empresa>
          </entrada>
        </B_SINPE_EjecutarAvisoPago>
      </soap:Body>
    </soap:Envelope>`;

  const url =
    process.env.REACT_APP_CISA_ENV === "production"
      ? // ? "https://www.cisaweb.com/wsSINPE/PagosSinpe.asmx?op=B_SINPE_EjecutarAvisoPago"
        "https://shlcisa.com/wsSINPE/PagosSinpe.asmx?op=B_SINPE_EjecutarAvisoPago" // AsadasCR consulta a shlcisa solamente. Quitar de los demas clientes y usar cisa
      : "https://shlcisa.com/wsSINPE/PagosSinpe.asmx?op=B_SINPE_EjecutarAvisoPago";

  const response = await fetch(url, {
    body: xmls,
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "text/xml; charset=UTF-8",
    },
  });

  const text = await response.text();

  let parser = new DOMParser();
  try {
    let xmlDoc = parser.parseFromString(text, "application/xml");

    // Extract data from the XML node 'B_SINPE_EjecutarAvisoPagoResult'
    const resultNode = xmlDoc.querySelector("B_SINPE_EjecutarAvisoPagoResult");
    if (resultNode) {
      return {
        codRespuesta: resultNode.children[0].textContent, // 0 = Success, 1 = Error
      };
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const tarjetaEjecutarAvisoPago = async (avisoPagoObj) => {
  const { login, secretKey, codAbonado, nombreAbonado, urlRetorno, codEmpresa, reference, description, total } =
    avisoPagoObj;

  let xmls = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Body>
        <E_TARJETA_EjecutarPago xmlns="http://www.cisaweb.com/wsSINPE">
          <entrada>
            <login>${login}</login>
            <secretkey>${secretKey}</secretkey>
            <cod_abonado>${codAbonado}</cod_abonado>
            <nombre_abonado>${nombreAbonado}</nombre_abonado>
            <url_retorno>${urlRetorno}</url_retorno>
            <cod_empresa>${codEmpresa}</cod_empresa>
            <reference>${reference}</reference>
            <description>${description}</description>
            <total>${total}</total>
          </entrada>
        </E_TARJETA_EjecutarPago>
      </soap:Body>
    </soap:Envelope>`;

  const url =
    process.env.REACT_APP_CISA_ENV === "production"
      ? "https://www.cisaweb.com/wsSINPE/PagosSinpe.asmx?op=E_TARJETA_EjecutarPago"
      : "https://shlcisa.com/wsSINPE/PagosSinpe.asmx?op=E_TARJETA_EjecutarPago";

  const response = await fetch(url, {
    body: xmls,
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "text/xml; charset=UTF-8",
    },
  });

  const text = await response.text();

  let parser = new DOMParser();
  try {
    let xmlDoc = parser.parseFromString(text, "application/xml");

    // Extract data from the XML node 'E_TARJETA_EjecutarPagoResult'
    const resultNode = xmlDoc.querySelector("E_TARJETA_EjecutarPagoResult");

    if (resultNode) {
      return {
        codRespuesta: resultNode.children[0].textContent, // 0 = Success, 1 = Error
        status: resultNode.children[1].textContent,
        request: resultNode.children[2].textContent,
        processUrl: resultNode.children[3].textContent,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const consultarEstadoTransaccion = async (referenceId) => {
  let xmls = `<?xml version="1.0" encoding="utf-8"?>
  <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
      <F_Consulta_Estado_Pago xmlns="http://www.cisaweb.com/wsSINPE">
        <entrada>
          <referenceid>${referenceId}</referenceid>
        </entrada>
      </F_Consulta_Estado_Pago>
    </soap:Body>
  </soap:Envelope>`;

  const url = "https://www.cisaweb.com/wsSINPE/PagosSinpe.asmx?op=F_Consulta_Estado_Pago";

  const response = await fetch(url, {
    body: xmls,
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "text/xml; charset=UTF-8",
    },
  });

  const text = await response.text();
  let parser = new DOMParser();

  try {
    let xmlDoc = parser.parseFromString(text, "application/xml");

    // // Extract data from the XML node 'F_Consulta_Estado_PagoResult'
    const resultNode = xmlDoc.querySelector("F_Consulta_Estado_PagoResult");

    if (resultNode) {
      return {
        codRespuesta: resultNode.children[0].textContent, // 0 = Success, 1 = Error
        requestId: resultNode.children[1].textContent,
        estado: resultNode.children[2].textContent,
        mensaje: resultNode.children[3].textContent,
        fecha: resultNode.children[4].textContent,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return { msg: "Ha ocurrido un error al consultar el estado de la transacción" };
  }
};


const crearTokenCISA = async () => {
  let xmls = `<?xml version="1.0" encoding="utf-8"?>
      <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Body>
          <Token_CrearToken xmlns="https://www.cisaweb.com/AcueductosRecibos">
            <usuario>usuario_cisa</usuario>
            <clave>F6DD8281-95D6-497A-9E8A-C631E5B1D215</clave>
          </Token_CrearToken>
        </soap:Body>
      </soap:Envelope>`;

  const url = "https://www.cisaweb.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx?op=Token_CrearToken";
  try { 
    // Realizar la solicitud fetch
    const response = await fetch(url, {
      body: xmls,
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "text/xml; charset=utf-8",
      },
    });

    // Verificar si la respuesta es exitosa
    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.statusText}`);
    }

    const text = await response.text();

    // Parsear la respuesta XML
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(text, "text/xml");
    const tokenElement = xmlDoc.getElementsByTagName("Token_CrearTokenResult")[0];

    // Verificar si el elemento token existe en la respuesta
    if (!tokenElement) {
      throw new Error("No se encontró el token en la respuesta XML");
    }

    const token = tokenElement.textContent;
    return token;

  } catch (error) {
    console.error("Error al generar token CISA:", error);
    return { msg: "Ha ocurrido un error al generar token CISA" };
  }

}

export {
  getCISABuscarRecibosPendientes,
  postCISAGenerarToken,
  sinpeEjecutarAvisoPago,
  getCISAInformacionAbonado,
  tarjetaEjecutarAvisoPago,
  consultarEstadoTransaccion,
  crearTokenCISA
};
