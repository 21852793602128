import { useState, useContext, useEffect } from "react";
import { useWizard } from "react-use-wizard";
import bancoProcesadorContext from "../../context/bancoProcesadorContext";

function EjecutarPagoTarjeta({ resetValues, asada, ejecutarAvisoPagoTarjeta }) {
  const { bancoProcesadorId } = asada;
  const { banco, getSingleBancoProcesador } = useContext(bancoProcesadorContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getSingleBancoProcesador(bancoProcesadorId);
    };
    fetchData();
  }, []);

  const { handleStep, nextStep, goToStep } = useWizard();

  const handleCancelStep = () => {
    resetValues();
    goToStep(0);
  };

  const handleRealizarAvisoPago = async () => {
    setIsLoading(true);
    await ejecutarAvisoPagoTarjeta();
    setIsLoading(false);
    handleStep(); // Solo se ejecutará después de que 'ejecutarAvisoPago' se complete
    nextStep();
  };

  return (
    <div className="container">
      <h2 className="mb-4 font-bold">Pago con tarjeta de crédito/débito</h2>
      <p className="mb-4 text-sm">
        Estimado usuario, los pagos en línea se efectúan a través de{" "}
        <span className="font-bold">{banco ? `${banco.nombre} (${banco.acronimo})` : "Sin banco"}</span>
        .<br />
        Al hacer clic en el botón “Continuar” será redirigido al sitio web de pago seguro para completar la transacción.
      </p>
      <p className="mb-4 text-sm">¿Desea continuar?</p>
      <div className="flex gap-3">
        <button onClick={handleCancelStep} className="btn bg-transparent hover:bg-accent capitalize">
          Cancelar
        </button>
        <button
          onClick={handleRealizarAvisoPago}
          className="btn bg-transparent hover:bg-accent capitalize"
          disabled={isLoading}
        >
          {!isLoading ? "Continuar" : "Redirigiendo..."}
        </button>
      </div>
      <figure className="w-28 h-10 mt-4">
        <img src="https://static.placetopay.com/placetopay-logo.svg" alt="Evertec logo"></img>
      </figure>
    </div>
  );
}

export default EjecutarPagoTarjeta;
