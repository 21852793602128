import { createContext, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LoginContext from "../login/LoginContext";
import { toast } from "react-toastify";
import baseURL from '../../utils/baseUrl';
import axios from 'axios';

const TanksContext = createContext();

export const TanksProvider = ({ children }) => {
  const { user } = useContext(LoginContext);

  const [isLoading, setIsLoading] = useState(true);
  const [tanks, setTanks] = useState([]);
  const [tank, setTank] = useState({});
  const [tanques, setTanques] = useState([]);
  const [tanque, setTanque] = useState({});

  const params = useParams();
  const navigate = useNavigate();

  // Get all water tanks
  const getTanks = async () => {
    setIsLoading(true);
    const response = await axios.get('/api/waterTanks', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.data.tanks;
    setTanks(data);
    setIsLoading(false);
  };

  // Get all tanques
  const getAllTanques = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${baseURL}/api/v1/tanques?aid=${asadaId}`
    );
    const data = await response.data;
    const tanques = data.tanques;
    setTanques(tanques);
    setIsLoading(false);
  };

  // Get all tanques private
  const getAllTanquesPrivate = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/api/v1/tanques/private?aid=${asadaId}`,
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      const tanques = data.tanques;
      setTanques(tanques);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Get single tanque
  const getSingleTanque = async (tanqueId) => {
    setIsLoading(true);
    const response = await axios.get(`${baseURL}/api/v1/tanques/${tanqueId}`);
    const data = await response.data;
    const tanque = data.tanque;
    setTanque(tanque);
    setIsLoading(false);
  };

  // Get tank by Id
  const getTank = async (id) => {
    setIsLoading(true);
    const response = await axios.get(`/api/waterTanks/${id}`);
    const data = await response.data;
    setTank(data);
    setIsLoading(false);
  };

  // Create a tanque
  const createTanque = async (newTanque, asadaId) => {
    setIsLoading(true);
    try {
      let response;
      if (asadaId) {
        response = await axios.post(
          `${baseURL}/api/v1/tanques?aid=${asadaId}`,
          newTanque,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
          }
        );
      } else {
        response = await axios.post(
          `${baseURL}/api/v1/tanques?aid=${process.env.REACT_APP_ASADA_ID}`,
          newTanque,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
      }

      const data = await response.data;
      const tanque = data.tanque;

      setTanques((prevTanques) => [...prevTanques, tanque]);

      toast.success(`Nuevo tanque de almacenamiento agregado.`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (asadaId) await getAllTanquesPrivate(asadaId);
      else await getAllTanques(process.env.REACT_APP_ASADA_ID);
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al agregar el tanque de almacenamiento.`,
        {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Add tank
  const addTank = async (newTank) => {
    try {
      setIsLoading(true);
      const response = await axios.post('/api/waterTanks', newTank, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
        },
      });

      const data = await response.data;

      await getTanks();
      setTanks(data);
      setIsLoading(false);
      toast.success(`¡Nuevo tanque de almacenamiento agregado!`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al agregar el tanque de almacenamiento. Error: ${error}`,
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      throw new Error(error);
    }
  };

  // Update tanque
  const updateTanqueAdmin = async (newTanque, tanqueId, restore) => {
    try {
      setIsLoading(true);

      const response = await axios.patch(
        `${baseURL}/api/v1/tanques/${tanqueId}${
          restore ? '?restore=true' : ''
        }`,
        newTanque,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }
      );

      const data = await response.data;
      const tanque = data.tanque;

      setTanque((prevTanque) => ({ ...prevTanque, ...tanque }));

      await getSingleTanque(tanqueId);

      toast.info(`Tanque de almacenamiento actualizado`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al editar el tanque de almacenamiento. Error: ${error}`,
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      throw new Error(
        `Error al actualizar tanque de almacenamiento: ${newTanque}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Update tank
  const updateTank = async (tank) => {
    try {
      setIsLoading(true);
      const { id } = params;
      await axios.put(`/api/waterTanks/${id}`, tank, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
        },
      });

      await getTanks();
      await getTank(id);
      setIsLoading(false);
      toast.info(
        `Se ha actualizado la información del tanque de almacenamiento`,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch (error) {
      console.log(error);
      throw new Error(
        `Error al actualizar informacion del tanque de almacenamiento: ${tank.name}`
      );
    }
  };

  // Update a tanque
  const updateTanque = async (tanque, tanqueId) => {
    try {
      const response = await axios.patch(
        `${baseURL}/api/v1/tanques/${tanqueId}`,
        tanque,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      const tank = data.tanque;
      setTanque((prevTanque) => ({
        ...prevTanque,
        ...tank,
      }));
      toast.info(
        `Se ha actualizado la información del tanque de almacenamiento`,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch (error) {
      console.log(error);
      throw new Error(
        `Error al actualizar informacion del tanque de almacenamiento: ${tanque.nombre}`
      );
    } finally {
      setIsLoading(true);
    }
  };

  // Delete photo
  const deleteTankPhoto = async (fotoId, tanqueId) => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/fotos/${fotoId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      await getSingleTanque(tanqueId);
      setIsLoading(false);
      toast.info(`Foto borrada del tanque de almacenamiento`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(
        `Error al actualizar informacion del tanque de almacenamiento: ${tank.name}`
      );
    }
  };

  // Delete tank
  const deleteTank = async (tanqueId, force) => {
    setIsLoading(true);
    try {
      await axios.delete(
        `${baseURL}/api/v1/tanques/${tanqueId}${force ? '?force=true' : ''}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );

      await getAllTanques(process.env.REACT_APP_ASADA_ID);

      toast.info(`Se ha borrado el registro del tanque de almacenamiento`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      throw new Error(`Error al borrar tanque de almacenamiento`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <TanksContext.Provider
      value={{
        getTanks,
        isLoading,
        setIsLoading,
        tank,
        tanks,
        addTank,
        getTank,
        setTank,
        updateTank,
        deleteTankPhoto,
        deleteTank,
        getAllTanques,
        tanques,
        tanque,
        setTanque,
        setTanques,
        createTanque,
        getSingleTanque,
        updateTanque,
        getAllTanquesPrivate,
        updateTanqueAdmin,
      }}
    >
      {children}
    </TanksContext.Provider>
  );
};

export default TanksContext;
