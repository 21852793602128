import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FaReceipt, FaUserPlus, FaWpforms } from "react-icons/fa";
import volcanoImg from "../../../assets/arenalVolcano.jpg";

function HomeServicesHome({ asada }) {
  const { fotoEstaticas } = asada;
  const [serviciosImg, setServiciosImg] = useState("");

  useEffect(() => {
    if (fotoEstaticas !== undefined) {
      if (fotoEstaticas.length > 0) {
        const serviciosImg = fotoEstaticas.filter(
          (item) => item.isServiciosImg === true
        )[0];

        if (serviciosImg !== undefined)
          setServiciosImg(serviciosImg.path.split("/")[4]);
      }
    }
  }, [fotoEstaticas]);

  return (
    <section className="services-home flex justify-center my-12 ">
      <div
        className={`volcano-img-container grid align-center max-h-[100%] max-w-[100%] h-[100%] w-[100%] bg-fixed bg-cover`}
        style={{
          backgroundImage: `url('${
            serviciosImg !== ''
              ? `${process.env.REACT_APP_BASE_URL}/uploads/${serviciosImg}`
              : volcanoImg
          }')`,
        }}
      >
        <div className="card my-12 md:h-[80vh]">
          <div className="card-body justify-center">
            <h2 className="card-title justify-center lg:text-6xl md:text-4xl text-3xl ">
              Nuestros Servicios
            </h2>

            <div className="service-items my-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-3 mt-8">
              <div className="service-item cursor-pointer hover:shadow-xl transition  duration-300 ease-in-out p-2 flex justify-center align-center flex-col text-center">
                <NavLink to="servicios" className="flex justify-center">
                  <FaReceipt className=" lg:text-9xl md:text-8xl sm:text-7xl text-6xl" />
                </NavLink>
                <p className="mt-5  font-bold text-2xl">Consulta de Recibos</p>
              </div>
              <div className="service-item cursor-pointer hover:shadow-xl transition duration-300  ease-in-out p-2 flex justify-center align-center flex-col text-center">
                <NavLink
                  to="documentacion#formularios"
                  className="flex justify-center"
                >
                  <FaUserPlus className=" lg:text-9xl md:text-8xl sm:text-7xl text-6xl" />
                </NavLink>
                <p className="mt-5  font-bold text-2xl">
                  Solicitud de Afiliación
                </p>
              </div>

              <div className="service-item cursor-pointer hover:shadow-xl transition duration-300 ease-in-out p-2 flex justify-center align-center flex-col text-center">
                <NavLink
                  to="documentacion#formularios"
                  className="flex justify-center"
                >
                  <FaWpforms className=" lg:text-9xl md:text-8xl sm:text-7xl text-6xl" />
                </NavLink>
                <p className="mt-5 font-bold text-2xl">Formularios</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeServicesHome;
