import { useState, useEffect } from "react";
import "./Spinner.css";
const Spinner = () => {
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    const loadingTimeoutId = setTimeout(() => {
      setShowLoadingMessage(true);
    }, 4000);

    const errorTimeoutId = setTimeout(() => {
      setShowLoadingMessage(false);
      setShowErrorMessage(true);
    }, 10000);

    return () => {
      clearTimeout(loadingTimeoutId);
      clearTimeout(errorTimeoutId);
    };
  }, []);

  return (
    <>
      <div className='flex flex-col items-center'>
        <div className='loadingio-spinner-ripple-hcx7mfygf2l'>
          <div className='ldio-ukf4q3mvfan'>
            <div></div>
            <div></div>
          </div>
        </div>
        {showLoadingMessage && (
          <p className='text-sm text-center'>Cargando...</p>
        )}
        {showErrorMessage && (
          <p className='text-sm text-center'>
            Algo ha salido mal. <br /> Por favor contacte al administrador del
            sitio.
          </p>
        )}
      </div>
    </>
  );
};

export default Spinner;
