import { createContext, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import LoginContext from "../login/LoginContext";
import FileDownload from "js-file-download";

import { toast } from "react-toastify";

const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const { user } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(true);
  const [forms, setForms] = useState([]);
  const [formularios, setFormularios] = useState([]);
  const [formulario, setFormulario] = useState({});
  const [form, setForm] = useState({});

  const params = useParams();
  const navigate = useNavigate();

  // Get all formularios
  const getFormularios = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/formularios?aid=${asadaId}`);
    const data = await response.data;
    setForms(data.formularios);
    setIsLoading(false);
  };

  // Get all formularios
  const getAllFormularios = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/formularios?aid=${asadaId}`
          : `http://localhost:5050/api/v1/formularios?aid=${asadaId}`,
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      const formularios = data.formularios;
      setFormularios(formularios);
    } catch (error) {
      console.log("Error fetching data: ", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Get all formularios private
  const getAllFormulariosPrivate = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/private?aid=${asadaId}`
          : `http://localhost:5050/api/v1/formularios/private?aid=${asadaId}`,
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      const formularios = data.formularios;
      setFormularios(formularios);
    } catch (error) {
      console.log("Error fetching data: ", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Get single formulario
  const getSingleFormulario = async (formularioId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/${formularioId}`
          : `http://localhost:5050/api/v1/formularios/${formularioId}`,
        { withCredentials: true }
      );
      const data = await response.data;
      const formulario = data.formulario;
      setFormulario(formulario);
    } catch (error) {
      console.log("Error fetching data: ", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Download form
  const downloadFormDocumentClient = async (id) => {
    setIsLoading(true);
    try {
      const download = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/${id}/download`
          : `http://localhost:5050/api/v1/formularios/${id}/download`,
        {
          responseType: "blob",
          withCredentials: true,
        }
      );
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/${id}`
          : `http://localhost:5050/api/v1/formularios/${id}`,
        { withCredentials: true }
      );
      const data = await response.data;
      const { file } = data.formulario;

      FileDownload(download.data, `${file.split("/")[5]}`);
    } catch (error) {
      console.log("Error downloading formulario: ", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Display/show reglamento in client view in different tab
  const displayFormClient = async (formularioId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/${formularioId}/display`
          : `http://localhost:5050/api/v1/formularios/${formularioId}/display`,
        {
          responseType: "blob",
          withCredentials: true,
        }
      );
      const data = await response.data;

      const file = new Blob([data], {
        type: "application/pdf",
      });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      console.log("Error displaying formulario: ", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Create formulario
  const createFormulario = async (newForm, asadaId) => {
    setIsLoading(true);
    try {
      let response;
      if (asadaId) {
        response = await axios.post(
          process.env.REACT_APP_NODE_ENV === "production"
            ? `${process.env.REACT_APP_BASE_URL}/api/v1/formularios?aid=${asadaId}`
            : `http://localhost:5050/api/v1/formularios?aid=${asadaId}`,
          newForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      } else {
        response = await axios.post(
          process.env.REACT_APP_NODE_ENV === "production"
            ? `${process.env.REACT_APP_BASE_URL}/api/v1/formularios?aid=${process.env.REACT_APP_ASADA_ID}`
            : `http://localhost:5050/api/v1/formularios?aid=${process.env.REACT_APP_ASADA_ID}`,
          newForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      }

      const data = await response.data;
      const formulario = data.formulario;
      setForms(...formularios, formulario);
      toast.success(`Se ha agregado un nuevo formulario a la ASADA. Nombre: ${formulario.nombre}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (asadaId) await getAllFormulariosPrivate(asadaId);
    } catch (error) {
      console.log("Error creating formulario: ", error);
      toast.error(`Ha ocurrido un error al agregar el formulario. Error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Update form
  const updateForm = async (newForm, formularioId) => {
    setIsLoading(true);
    try {
      const response = await axios.patch(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/${formularioId}`
          : `http://localhost:5050/api/v1/formularios/${formularioId}`,
        newForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      const data = await response.data;
      const formulario = await data.formulario;

      // await getForms();
      await getSingleFormulario(formularioId);

      toast.info(`Formulario actualizado: "${formulario.nombre}"`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);

      toast.error(`Ha ocurrido un error al editar el formulario. Error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(`Error al actualizar formulario: ${newForm}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Update formulario
  const updateFormularioAdmin = async (newFormulario, formularioId, restore) => {
    setIsLoading(true);
    try {
      const response = await axios.patch(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/${formularioId}${restore ? "?restore=true" : ""}`
          : `http://localhost:5050/api/v1/formularios/${formularioId}${restore ? "?restore=true" : ""}`,
        newFormulario,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      const data = await response.data;
      const formulario = data.formulario;

      // await getInformes();
      await getSingleFormulario(formularioId);
      toast.info(`Informe actualizado: "${formulario.nombre}"`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(`Ha ocurrido un error al editar el informe. Error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(`Error al actualizar informe: ${newFormulario}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete form
  const deleteFormulario = async (formularioId, force) => {
    setIsLoading(true);
    try {
      await axios.delete(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/formularios/${formularioId}${force ? "?force=true" : ""}`
          : `http://localhost:5050/api/v1/formularios/${formularioId}${force ? "?force=true" : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      await getAllFormularios(process.env.REACT_APP_ASADA_ID);
      toast.info(`Se ha borrado el registro del formulario`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      throw new Error(`Error al borrar formulario: ${form.fname}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormContext.Provider
      value={{
        createFormulario,
        isLoading,
        setIsLoading,
        forms,
        form,
        setForm,
        updateForm,
        downloadFormDocumentClient,
        displayFormClient,
        deleteFormulario,
        getFormularios,
        formularios,
        formulario,
        setFormulario,
        setFormularios,
        getAllFormularios,
        getSingleFormulario,
        getAllFormulariosPrivate,
        updateFormularioAdmin,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
