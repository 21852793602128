import { useEffect, useContext, useState, useLayoutEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LoginContext from "../../context/login/LoginContext";
import TransparenciaContext from "../../context/transparencia/TransparenciaContext";
import Modal from "../shared/Modal";

function TransparenciaItem() {
  const {
    transparencia,
    isLoading,
    setDocumento,
    displayDocumento,
    updateTransparencia,
    updateTransparenciaAdmin,
    getSingleTransparencia,
    deleteTransparencia,
  } = useContext(TransparenciaContext);
  const { user } = useContext(LoginContext);

  const params = useParams(),
    navigate = useNavigate(),
    { id } = params,
    [newDname, setNewDname] = useState(""),
    [newDdesc, setNewDdesc] = useState(""),
    [newDdoc, setNewDdoc] = useState("");
  const [forceDelete, setForceDelete] = useState(false);
  const [restore, setRestore] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const { nombre, descripcion, file, deletedAt } = transparencia;

  useEffect(() => {
    const fetchTransparencia = async () => await getSingleTransparencia(id);

    fetchTransparencia();
  }, []);

  useEffect(() => {
    if (transparencia) {
      setNewDname(transparencia.nombre);
      setNewDdesc(transparencia.descripcion);
      setNewDdoc(transparencia.file);
    }
  }, [transparencia]);

  // This is like 'componentWillUnmount'
  useLayoutEffect(() => {
    return () => {
      setDocumento({});
    };
  }, []);

  const onNewDnameChange = (e) => {
      setNewDname(e.target.value);
    },
    onNewDdescChange = (e) => {
      setNewDdesc(e.target.value);
    },
    onNewDdocChange = (e) => {
      setNewDdoc(e.target.files[0]);
    };

  const displayDocument = async (e) => {
    e.preventDefault();
    await displayDocumento(id);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fd = new FormData();
    // If newDname is not modified, use the current transparencia document's dname
    if (newDname === "") fd.set("nombre", nombre);
    // If is modified
    else fd.set("nombre", newDname);

    // If newDdesc is not modified, use the current transparencia document's ddesc
    if (newDdesc === "") fd.set("descripcion", newDdesc);
    // If is modified
    else fd.set("descripcion", newDdesc);

    if (newDdoc !== "") fd.append("file", newDdoc);
    else fd.set("file", null);

    if (user.role === "admin") await updateTransparenciaAdmin(fd, id, restore);
    else updateTransparencia(fd, id);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "Se eliminará de la base de datos el registro del documento de transparencia. Esta acción no se puede revertir.\n ¿Seguro que desea continuar?"
      )
    ) {
      await deleteTransparencia(id, forceDelete);
    }
  };

  const handleDeleteChange = () => {
    setForceDelete((prev) => !prev);
  };
  const handleRestoreChange = () => {
    setRestore((prev) => !prev);
  };

  return (
    <>
      <h2 className='text-2xl my-4 font-bold card-title'>
        <button
          onClick={() => navigate(-1)}
          className='btn btn-outline btn-accent btn-sm hover:text-white'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-6 w-6 '
            viewBox='0 0 20 20'
            fill='currentColor'
          >
            <path
              fillRule='evenodd'
              d='M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z'
              clipRule='evenodd'
            />
          </svg>
        </button>
        Editar documento de transparencia
      </h2>

      <div className='flex justify-center text-lg'>
        <form className='w-full max-w-lg' onSubmit={handleSubmit}>
          <div className='w-full px-3'>
            <label
              className='block tracking-wide  text-xs font-bold mb-2'
              htmlFor='grid-nombre'
            >
              Nombre *
            </label>
            <input
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              id='grid-nombre'
              type='text'
              required
              name='nombre'
              placeholder='Nombre del documento de transparencia'
              defaultValue={nombre}
              onChange={onNewDnameChange}
            />
          </div>
          <div className='w-full px-3'>
            <label
              className='block tracking-wide  text-xs font-bold mb-2'
              htmlFor='grid-descripcion'
            >
              Descripción
            </label>
            <textarea
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              id='grid-descripcion'
              type='text'
              name='descripcion'
              placeholder='Descripción breve del documento de transparencia (opcional)'
              defaultValue={descripcion}
              onChange={onNewDdescChange}
            />
          </div>
          <div className='w-full px-3'>
            <label
              className='block tracking-wide text-xs font-bold mb-2'
              htmlFor='grid-ddoc'
            >
              Documento *
            </label>
            <input
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              id='grid-ddoc'
              type='file'
              name='ddoc'
              onChange={onNewDdocChange}
            />
            <small className='text-xs'>
              Documento actual: {file ? file.split("/")[5] : ""}
              <br />
              <button
                className='btn btn-accent btn-xs capitalize'
                onClick={displayDocument}
                type='button'
              >
                Ver&nbsp;
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-4 w-4'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path d='M10 12a2 2 0 100-4 2 2 0 000 4z' />
                  <path
                    fillRule='evenodd'
                    d='M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z'
                    clipRule='evenodd'
                  />
                </svg>
              </button>
            </small>
          </div>
          <div className='w-full px-3 flex gap-2 mt-4'>
            {user && user.role === "admin" && (
              <>
                <input
                  type='checkbox'
                  id='force'
                  className='h-5 w-5'
                  onChange={handleDeleteChange}
                />
                <label
                  htmlFor='force'
                  className='cursor-pointer tracking-wide  text-xs font-bold mb-2'
                >
                  &nbsp;Borrar permanentemente
                </label>

                {deletedAt && (
                  <>
                    <input
                      type='checkbox'
                      id='restaurar'
                      className='h-5 w-5'
                      onChange={handleRestoreChange}
                    />
                    <label
                      htmlFor='restaurar'
                      className='cursor-pointer tracking-wide  text-xs font-bold mb-2'
                    >
                      &nbsp;Restaurar
                    </label>
                  </>
                )}
              </>
            )}
          </div>
          <br />
          <small className='ml-1 text-2xs text-gray-500'>
            * Campos requeridos
          </small>
          <div className='divider'></div>

          <div className='card-actions justify-start'>
            <button
              type='submit'
              className={`btn btn-success hover:bg-green-700 capitalize ${
                isLoading ? "loading" : ""
              }`}
            >
              {!isLoading ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path d='M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z' />
                </svg>
              ) : (
                <></>
              )}
              &nbsp;Guardar cambios
            </button>
            <button
              className='btn btn-error hover:bg-red-700 capitalize'
              type='button'
              onClick={handleDelete}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                viewBox='0 0 20 20'
                fill='currentColor'
              >
                <path
                  fillRule='evenodd'
                  d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
                  clipRule='evenodd'
                />
              </svg>
              &nbsp;Borrar documento
            </button>
            <button
              type='button'
              className='btn btn-ghost ml-2 justify-self-end'
              onClick={openModal}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z'
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
      <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
        <div>
          <p className='font-light text-lg'>Consideraciones</p>
          <ul className='list-disc'>
            <li className='font-light text-sm my-1'>
              Los campos requeridos están marcados con un asterisco (*).
            </li>
            <li className='font-light text-sm my-1'>
              El nombre debe tener más de 3 y menos de 70 caracteres.
            </li>
            <li className='font-light text-sm my-1'>
              La descripción debe tener menos de 100 caracteres.
            </li>
            <li className='font-light text-sm my-1'>
              El documento estará disponible en la página pública, en la pestaña
              'Documentación'. Desde aquí se puede descargar.
            </li>
            <li className='font-light text-sm my-1'>
              El documento debe ser en formato pdf.
            </li>
            <li className='font-light text-sm my-1'>
              El documento no debe pesar más de 5MB.
            </li>
            <li className='font-light text-sm my-1'>
              El documento de transparencia se descarga desde la pestaña que se
              abre al hacer clic en el botón 'Ver'.
            </li>
            {user && user.role === "admin" && (
              <>
                <li className='font-light text-sm my-1'>
                  Para borrar un registro permanentemente de la base de datos
                  marque la casilla 'Borrar permanentemente' y luego presione el
                  botón 'Borrar Documento'. Para hacer un 'soft-delete'
                  desmarque la casilla.
                </li>
                <li className='font-light text-sm my-1'>
                  Para restaurar un registro que fue 'soft-deleted' marque la
                  casilla 'Restaurar' y luego presione el botón 'Guardar
                  Cambios'. Esta casilla solamente aparece cuando un registro ha
                  sido 'soft-deleted'.
                </li>
              </>
            )}
          </ul>
        </div>
      </Modal>
    </>
  );
}

export default TransparenciaItem;
