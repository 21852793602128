import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OPTIONS = {
  position: "top-right",
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const mostrarNotificacionEstadoTransaccion = (response) => {
  const { estado, fecha, mensaje, requestId } = response;
  const mensajePersonalizado = (
    <React.Fragment>
      <span className="font-bold">Request ID:</span>&nbsp;
      {requestId}
      <br />
      <span className="font-bold">Fecha:</span> {fecha}
      <br />
      {mensaje}
    </React.Fragment>
  );

  switch (estado) {
    case "PENDING":
      toast.info(mensajePersonalizado, OPTIONS);
      break;
    case "REJECTED":
      toast.error(mensajePersonalizado, OPTIONS);
      break;
    case "APPROVED":
      toast.success(mensajePersonalizado, OPTIONS);
      break;
    default:
      toast.warn("Estado desconocido. Por favor contactar al administrador de la Asada", OPTIONS);
  }
};

export default mostrarNotificacionEstadoTransaccion;
