import documentationImg from "../../../assets/documentation-img-1.jpg";
import { useEffect, useState } from "react";

function Header({ fotoEstaticas }) {
  const [principalImg, setPrincipalImg] = useState("");

  useEffect(() => {
    if (fotoEstaticas !== undefined && fotoEstaticas.length > 0 && fotoEstaticas[0].isDocumentacionImg === true) {
      const imgPath = fotoEstaticas[0].path.split("/");
      if (imgPath.length >= 5) {
        const img = imgPath[4];
        setPrincipalImg(img);
      } else {
        console.error("Invalid path format:", fotoEstaticas[0].path);
      }
    }
  }, [fotoEstaticas]);

  return (
    <>
      <div>
        <div className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
          <div className="card lg:w-full image-full">
            <figure>
              <img
                src={
                  principalImg !== "" ? `${process.env.REACT_APP_BASE_URL}/uploads/${principalImg}` : documentationImg
                }
                alt="Imagen documentacion"
              />
            </figure>
            <div className="flex justify-center items-center z-30">
              <h3 className="text-3xl md:text-5xl xl:text-7xl font-black text-white tracking-wider">Documentación</h3>
            </div>
          </div>

          {/*   <motion.div
            className='capitalize'
            style={{ backgroundColor: "rgba(61, 68, 81, 0.75)" }}
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: {
                opacity: 1,
                y: "0",
                scale: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.4,
                },
              },
              hidden: { opacity: 0, y: "100px", scale: 0 },
            }}
          >
            <motion.h1
              className='p-12 text-white '
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: {
                  opacity: 1,
                  transition: {
                    delay: 0.7,
                    duration: 0.5,
                  },
                },
                hidden: { opacity: 0 },
              }}
            >
              Documentación
            </motion.h1>
          </motion.div> */}
        </div>
      </div>
    </>
  );
}

export default Header;
