import { useCallback, useRef } from "react";
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import Spinner from "../shared/Spinner";

const SABANA_COORDS = {
  lat: 9.935971987170971,
  lng: -84.10414285160856
}

function Map({ googleMapsApiKey, googleMapsLatitude, googleMapsLongitude }) {
  const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey })
  const mapRef = useRef();
  
  const center = {
    lat: Number(googleMapsLatitude) || SABANA_COORDS.lat, // Must be casted to number or errors
    lng: Number(googleMapsLongitude) || SABANA_COORDS.lng
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map
    center.lat = googleMapsLatitude
    center.lng = googleMapsLongitude
  }, [])

  if(loadError) return "Error loading Google Maps"
  if(!isLoaded) return <Spinner />

  return (
    <div className="w-full h-full">
      <GoogleMap 
        mapContainerStyle={{ height: '100%'}}
        center={center}
        zoom={18}
        onLoad={onMapLoad}
      >
        <Marker 
          position={center}
          className='cursor-pointer'
        />
      </GoogleMap>
    </div>
  );
}

export default Map;
