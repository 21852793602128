import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="container grid place-content-center h-[450px] w-full mx-auto">
            <h1 className="text-4xl md:text-6xl text-center mb-4">
                ¯\_(ツ)_/¯
            </h1>
            <h1 className="text-3xl md:text-6xl text-center mb-4">
                <span className="text-accent">4</span>0
                <span className="text-accent">4</span> - Not Found
            </h1>
            <p className="text-center mb-4">
                La página que estás buscando no existe.{" "}
            </p>
            <Link to="/" className="btn hover:btn-accent">
                Volver a la página de inicio
            </Link>
        </div>
    );
};

export default NotFound;
