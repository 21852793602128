import { createContext, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoginContext from "../login/LoginContext";
import axios from "axios";

import { toast } from "react-toastify";

const JuntaDirectivaContext = createContext();

export const JuntaDirectivaProvider = ({ children }) => {
  const { user } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [integrantes, setIntegrantes] = useState([]);
  const [integrante, setIntegrante] = useState({});
  const [member, setMember] = useState({});
  const params = useParams();

  const navigate = useNavigate();

  // Get all integrantes from junta directiva
  const getIntegrantes = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/integrantes?aid=${asadaId}`
          : `http://localhost:5050/api/v1/integrantes?aid=${asadaId}`
      );
      const data = await response.data;
      const integrantes = data.integrantes;
      setIntegrantes(integrantes);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Get all integrantes private
  const getAllIntegrantesPrivate = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/integrantes/private?aid=${asadaId}`
          : `http://localhost:5050/api/v1/integrantes/private?aid=${asadaId}`,
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      const integrantes = data.integrantes;
      setIntegrantes(integrantes);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Get integrante by Id
  const getSingleIntegrante = async (integranteId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/integrantes/${integranteId}`
          : `http://localhost:5050/api/v1/integrantes/${integranteId}`
      );
      const data = await response.data;
      const integrante = data.integrante;
      setIntegrante(integrante);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Add an integrante
  const addIntegrante = async (newIntegrante, asadaId) => {
    setIsLoading(true);
    try {
      let response;
      if (asadaId) {
        response = await axios.post(
          process.env.REACT_APP_NODE_ENV === "production"
            ? `${process.env.REACT_APP_BASE_URL}/api/v1/integrantes?aid=${asadaId}`
            : `http://localhost:5050/api/v1/integrantes?aid=${asadaId}`,
          newIntegrante,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      } else {
        response = await axios.post(
          process.env.REACT_APP_NODE_ENV === "production"
            ? `${process.env.REACT_APP_BASE_URL}/api/v1/integrantes?aid=${process.env.REACT_APP_ASADA_ID}`
            : `http://localhost:5050/api/v1/integrantes?aid=${user.idAsada}`,
          newIntegrante,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      }

      const data = await response.data;
      const integrante = data.integrante;
      setIntegrantes(...integrantes, integrante);

      toast.success(`Nuevo integrante agregado a la Junta Directiva`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (asadaId) await getAllIntegrantesPrivate(asadaId);
      else await getAllIntegrantesPrivate(process.env.REACT_APP_ASADA_ID);
    } catch (error) {
      console.log(error);
      toast.error(`Ha ocurrido un error al agregar integrante a la Junta Directiva. Error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Update an integrante
  const updateIntegrante = async (integrante, id, restore) => {
    setIsLoading(true);
    try {
      const response = await axios.patch(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/integrantes/${id}${restore ? `?restore=${restore}` : ""}`
          : `http://localhost:5050/api/v1/integrantes/${id}${restore ? `?restore=${restore}` : ""}`,
        integrante,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      setIntegrante(data.integrante);

      toast.info(`Se ha actualizado la información del integrante de la Junta Directiva`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      throw new Error(`Error al actualizar informacion de integrante: ${integrante.nombre}`);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteIntegrante = async (integranteId, force) => {
    setIsLoading(true);
    try {
      await axios.delete(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/integrantes/${integranteId}${force ? "?force=true" : ""}`
          : `http://localhost:5050/api/v1/integrantes/${integranteId}${force ? "?force=true" : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      await getIntegrantes(user.asadaId);

      toast.info(`Se ha borrado el registro del integrante de la Junta Directiva`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      throw new Error(`Error al borrar integrante: ${integrante.nombre} ${integrante.apellidos}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <JuntaDirectivaContext.Provider
      value={{
        deleteIntegrante,
        isLoading,
        setIsLoading,
        members,
        setMember,
        member,
        getIntegrantes,
        integrantes,
        integrante,
        setIntegrante,
        getSingleIntegrante,
        addIntegrante,
        updateIntegrante,
        getAllIntegrantesPrivate,
      }}
    >
      {children}
    </JuntaDirectivaContext.Provider>
  );
};

export default JuntaDirectivaContext;
