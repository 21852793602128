import { createContext, useState, useContext } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import LoginContext from '../login/LoginContext';
import axios from 'axios';
import FileDownload from 'js-file-download';
import baseURL from '../../utils/baseUrl';
import { toast } from 'react-toastify';

const JobContext = createContext();

export const JobProvider = ({ children }) => {
  const { user } = useContext(LoginContext);
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [job, setJob] = useState({});
  const [solicitudes, setSolicitudes] = useState([]);
  const [solicitud, setSolicitud] = useState({});

  // Get all jobs
  const getJobs = async () => {
    setIsLoading(true);
    const response = await axios.get('/api/jobs', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = await response.data;

    setJobs(data.jobs);
    setIsLoading(false);
  };

  // Get all solicitudes
  const getAllSolicitudes = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/api/v1/solicitudes?aid=${asadaId}`,
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      const solicitudes = data.solicitudes;
      setSolicitudes(solicitudes);
    } catch (error) {
      console.error('Error fetching solicitues:', error);
      throw new Error(`Error al obtener solicitudes de empleo: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Get all solicitudes private
  const getAllSolicitudesPrivate = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/api/v1/solicitudes/private?aid=${asadaId}`,
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      const solicitudes = data.solicitudes;
      setSolicitudes(solicitudes);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  // Get single solicitud
  const getSingleSolicitud = async (solicitudId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/api/v1/solicitudes/${solicitudId}`,
        { withCredentials: true }
      );
      const data = await response.data;
      const solicitud = data.solicitud;
      setSolicitud(solicitud);
    } catch (error) {
      console.error('Error fetching solicitud:', error);
      throw new Error(`Error al obtener solicitud de empleo: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Get job by Id
  const getJob = async (id) => {
    setIsLoading(true);
    const response = await axios.get(`/api/jobs/${id}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = await response.data;
    setJob(data);
    setIsLoading(false);
    return data;
  };

  // Add job
  const createSolicitud = async (newSolicitud) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseURL}/api/v1/solicitudes?aid=${process.env.REACT_APP_ASADA_ID}`,
        newSolicitud,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const data = await response.data;
      const solicitud = data.solicitud;

      setJobs((prevSolicitudes) => [...prevSolicitudes, solicitud]);
      setSolicitudes((prevSolicitudes) => [...prevSolicitudes, solicitud]);
    } catch (error) {
      console.error(error);
      toast.error(
        `Ha ocurrido un error al enviar la solicitud. Error: ${error}`,
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Download curriculum
  const downloadCurriculum = async () => {
    setIsLoading(true);
    const { id } = params,
      download = await axios.get(`/api/jobs/${id}/download`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }),
      response = await axios.get(`/api/jobs/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }),
      file = await response.data.curriculum,
      fileName = file.split('-')[0],
      fileExt = file.split('.')[1];

    FileDownload(download.data, `${fileName}.${fileExt}`);
    setIsLoading(false);
  };

  // Display/show curriculum in a different tab
  const displayCurriculum = async (curriculumId) => {
    const response = await axios.get(
      `${baseURL}/api/v1/solicitudes/${curriculumId}/display`,
      {
        responseType: 'blob',
      }
    );
    const data = await response.data;

    const file = new Blob([data], {
      type: 'application/pdf',
    });

    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const updateSolicitudAdmin = async (newSolicitud, solicitudId, restore) => {
    try {
      setIsLoading(true);

      const response = await axios.patch(
        `${baseURL}/api/v1/solicitudes/${solicitudId}${
          restore ? '?restore=true' : ''
        }`,
        newSolicitud,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const data = await response.data;
      const solicitud = data.solicitud;

      await getSingleSolicitud(solicitudId);
      toast.info(`Solicitud de empleo actualizada`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      toast.error(
        `Ha ocurrido un error al editar la solicitud de empleo. Error: ${error}`,
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      throw new Error(
        `Error al actualizar soicitud de empleo: ${newSolicitud}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Update job
  const updateJob = async (job) => {
    try {
      setIsLoading(true);
      const { idjobs } = job;

      const response = await axios.put(`/api/jobs/${idjobs}`, job, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
      });

      const data = await response.data;

      setIsLoading(false);

      return data;
    } catch (error) {
      console.log(error);
      toast.error(`Error al actualizar solicitud de empleo. Error: ${error}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(`Error al actualizar sugerencia: ${job}`);
    }
  };

  // Update solicitud
  const updateSolicitud = async (newSolicitud, solicitudId) => {
    try {
      setIsLoading(true);
      await axios.patch(
        `${baseURL}/api/v1/solicitudes/${solicitudId}`,
        newSolicitud,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );

      // await getReglamentos();
      await getSingleSolicitud(solicitudId);
      toast.info(`Solicitud actualizada.`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      toast.error(`Ha ocurrido un error al editar la solicitud: ${error}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(`Error al actualizar solicitud`);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete solicitud
  const deleteSolicitud = async (solicitudId, force) => {
    try {
      setIsLoading(true);

      await axios.delete(
        `${baseURL}/api/v1/solicitudes/${solicitudId}${
          force ? '?force=true' : ''
        }`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      await getAllSolicitudes(process.env.REACT_APP_ASADA_ID);

      toast.info(`Se ha borrado el registro de la solicitud`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.error(error);
      toast.info(`Ha occurrido un error al borrar la solicitud: ${error}`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(
        `Error al borrar solicitud de: ${solicitud.nombre} ${solicitud.apellidos}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <JobContext.Provider
      value={{
        getJobs,
        getJob,
        jobs,
        job,
        isLoading,
        setJob,
        updateJob,
        downloadCurriculum,
        displayCurriculum,
        solicitudes,
        solicitud,
        setSolicitud,
        setSolicitudes,
        getAllSolicitudes,
        createSolicitud,
        getSingleSolicitud,
        updateSolicitud,
        getAllSolicitudesPrivate,
        deleteSolicitud,
        updateSolicitudAdmin,
      }}
    >
      {children}
    </JobContext.Provider>
  );
};

export default JobContext;
