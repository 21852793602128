import { useState, useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
import ocultarCorreo from '../../utils/ocultarCorreo';

function EnviarCodigo({ abonado, generarToken, numAbonado, montoAPagar, resetValues }) {
    const [tiempoRestante, setTiempoRestante] = useState(5);
    const [isLoading, setIsLoading] = useState(false);
    const { correo } = abonado;
    const correoOculto = ocultarCorreo(correo);

    const {
        nextStep,
        goToStep,
        handleStep
    } = useWizard();

    useEffect(() => {
        const intervalo = setInterval(() => {
            setTiempoRestante((prevTiempo) => (prevTiempo > 0 ? prevTiempo - 1 : 0));
        }, 1000);

        // Limpia el intervalo cuando el componente se desmonta
        return () => clearInterval(intervalo);
    }, []);

    const handleGenerarToken = async (numAbonado, montoAPagar) => {
        await generarToken(numAbonado, montoAPagar);
    };

    const handleStepAndGenerarToken = async () => {
        setIsLoading(true);
        await handleGenerarToken(numAbonado, montoAPagar);
        handleStep(); // Solo se ejecutará después de que 'handleGenerarToken' se complete
        nextStep();
        setIsLoading(false);
    };

    const handleCancelStep = () => {
        resetValues();
        goToStep(0);
    }


    const habilitarBoton = tiempoRestante === 0;

    return (
        <div className='container'>
            <h2 className="mb-4 font-bold">Pago con SINPE</h2>
            <p className='mb-4 text-sm'>Estimado usuario, por razones de seguridad, nuestro sistema enviará un correo electrónico a “<span className='whitespace-nowrap font-bold'>{correoOculto}</span>”. <br /> El mismo contendrá un código único que será solicitado para proceder a cancelar las facturas.</p>
            <p className='mb-4 text-sm'>¿Desea continuar?</p>
            <div className='flex gap-3'>
                <button onClick={handleCancelStep} className='btn bg-transparent hover:bg-accent capitalize'>Cancelar</button>
                <button onClick={handleStepAndGenerarToken} className='btn bg-transparent hover:bg-accent capitalize' disabled={!habilitarBoton | isLoading}>{!isLoading ? "Continuar" : "Enviando correo..."} {tiempoRestante === 0 ? "" : `${tiempoRestante}...`}</button>
            </div>
        </div>
    )
}

export default EnviarCodigo