import { useEffect, useState } from "react";
import defaultImg from "../../../assets/defaultImg.png";
import baseURL from '../../../utils/baseUrl';

function Cards({ asada }) {
  const { extension, usuarios, tanques, gradientes, fotoEstaticas } = asada;

  const [extensionImgFilename, setExtensionImgFilename] = useState('');
  const [usuariosImgFilename, setUsuariosImgFilename] = useState('');
  const [tanqueCardImgFilename, setTanqueCardImgFilename] = useState('');
  const [gradienteCardImgFilename, setGradienteCardImgFilename] = useState('');

  useEffect(() => {
    if (fotoEstaticas !== undefined) {
      // console.log(fotoEstaticas);
      if (fotoEstaticas.length > 0) {
        const extensionImg = fotoEstaticas.filter(
          (item) => item.isExtensionImg === true
        )[0];
        const usuariosImg = fotoEstaticas.filter(
          (item) => item.isUsuariosImg === true
        )[0];
        const tanqueCardImg = fotoEstaticas.filter(
          (item) => item.isTanqueCardImg === true
        )[0];
        const gradienteCardImg = fotoEstaticas.filter(
          (item) => item.isGradienteCardImg === true
        )[0];

        if (extensionImg !== undefined)
          setExtensionImgFilename(extensionImg.path.split('/')[4]);
        if (usuariosImg !== undefined)
          setUsuariosImgFilename(usuariosImg.path.split('/')[4]);
        if (tanqueCardImg !== undefined)
          setTanqueCardImgFilename(tanqueCardImg.path.split('/')[4]);
        if (gradienteCardImg !== undefined)
          setGradienteCardImgFilename(gradienteCardImg.path.split('/')[4]);
      }
    }
  }, [fotoEstaticas]);

  return (
    <>
      <section className="my-12 py-8">
        <div className="lg:flex mt-2 gap-3 mx-1 px-6 h-full">
          <div className="card sm:w-full h-96 lg:w-full bg-base-100 shadow-xl image-full bg-opacity-90">
            <figure>
              <img
                src={
                  extensionImgFilename !== ''
                    ? `${baseURL}/uploads/${extensionImgFilename.split(
                        'uploads/'
                      )}`
                    : defaultImg
                }
                alt="Extensión de la ASADA"
              />
            </figure>
            <div className="card-body text-center flex justify-center ">
              <div className="stat whitespace-normal font-black text-white tracking-wider ">
                <p className="stat-title text-3xl">{extension}</p>
                <h3 className="stat-title font-thin">Extensión</h3>
              </div>
            </div>
          </div>
          <div className="card sm:w-full h-96 lg:w-full bg-base-100 shadow-xl image-full">
            <figure>
              <img
                src={
                  usuariosImgFilename !== ''
                    ? `${baseURL}/uploads/${usuariosImgFilename}`
                    : defaultImg
                }
                alt="Usuarios abastecidos"
              />
            </figure>
            <div className="card-body  text-center flex justify-center">
              <div className="stat font-black text-white tracking-wider flex flex-col justify-center ">
                <p className="stat-title text-3xl ">{usuarios}</p>
                <h3 className="stat-title font-thin">Usuarios Abastecidos</h3>
              </div>
            </div>
          </div>
          <div className="card sm:w-full h-96 lg:w-full bg-base-100 shadow-xl  image-full">
            <figure>
              <img
                src={
                  tanqueCardImgFilename !== ''
                    ? `${baseURL}/uploads/${tanqueCardImgFilename}`
                    : defaultImg
                }
                alt="Tanque de almacenamiento"
              />
            </figure>
            <div className="card-body text-center flex justify-center">
              <div className="stat font-black text-white tracking-wider">
                <div className="stat-title text-3xl">{tanques}</div>
                <h3 className="stat-title font-thin">
                  Tanques de <br />
                  Almacenamiento
                </h3>
              </div>
            </div>
          </div>
          {asada.gradientes === 0 ? null : (
            <div className="card sm:w-full h-96 lg:w-full bg-base-100 shadow-xl image-full">
              <figure>
                <img
                  src={
                    gradienteCardImgFilename !== ''
                      ? `${baseURL}/uploads/${gradienteCardImgFilename}`
                      : defaultImg
                  }
                  alt="Quiebragradientes"
                />
              </figure>
              <div className="card-body text-center flex justify-center">
                <div className="stat font-black text-white tracking-wider">
                  <div className="stat-title text-3xl">{gradientes}</div>
                  <div className="stat-title  font-thin">Quiebragradientes</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Cards;
