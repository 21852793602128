import { useContext } from "react";
import { FaDownload } from "react-icons/fa";
import { motion } from "framer-motion";
import TransparenciaContext from "../../../context/transparencia/TransparenciaContext";

function Transparencia({ documentos }) {
  const { downloadTransparenciaDocumentClient } = useContext(TransparenciaContext);

  const handleDocDownload = (id) => downloadTransparenciaDocumentClient(id); // Download transparencia doc

  return (
    <>
      {/* Transparencia */}
      <div className="text-center py-6 mt-8">
        <motion.h2
          className="pb-5 text-3xl md:text-5xl lg:text-6xl"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: {
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.5,
              },
            },
            hidden: { opacity: 0 },
          }}
        >
          Transparencia
        </motion.h2>
        <div className="flex justify-center px-2">
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={{
              visible: {
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.5,
                },
              },
              hidden: { opacity: 0 },
            }}
            className="max-w-xl"
          >
            En esta sección podrás encontrar todos los documentos relacionados al trabajo que se realiza en la ASADA y
            cómo se utilizan los fondos públicos para el bienestar de nuestra comunidad.
          </motion.p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 xl:grid-cols-3 sm:justify-center gap-5 px-12">
        {documentos.length !== 0 &&
          documentos.map((documento, i) => {
            return (
              <motion.div
                className="card bg-base-100 shadow-xl"
                key={documento.id}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ delay: i * 0.3 }}
                variants={{
                  visible: {
                    opacity: 1,
                  },
                  hidden: { opacity: 0 },
                }}
              >
                <div className="card-body max-h-64">
                  <h2 className="card-title justify-center text-center">{documento.nombre}</h2>
                  <p className="flex justify-center overflow-y-auto">{documento.descripcion}</p>
                  <motion.div
                    className="justify-center mt-3 card-actions"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ delay: 0.5 }}
                    variants={{
                      visible: {
                        opacity: 1,
                      },
                      hidden: { opacity: 0 },
                    }}
                  >
                    <div className="card-actions">
                      <button
                        className="btn btn-accent btn-outline btn-sm capitalize"
                        onClick={() => {
                          handleDocDownload(documento.id);
                        }}
                      >
                        Descargar&nbsp;
                        <FaDownload />
                      </button>
                    </div>
                  </motion.div>
                </div>
              </motion.div>
            );
          })}
      </div>
    </>
  );
}

export default Transparencia;
