import { useState, useEffect } from "react";
import formatearMonto from "../../utils/formatearMonto";

const FacturaItem = ({ factura, handleAgregarFactura, handleQuitarFactura }) => {
  // const { isSeleccionable } = factura;
  const [isSelected, setIsSelected] = useState(null);

  const handleToggleSelection = () => {
    setIsSelected(!isSelected);
  };

  // useEffect(() => {
  //  console.log("Is seleccionable:", isSeleccionable);
  // }, [isSeleccionable])

  useEffect(() => {
    if (isSelected !== null) {
      if (isSelected) {
        handleAgregarFactura(factura);
      } else {
        handleQuitarFactura(factura);
      }
    }
  }, [isSelected]);

  return (
    <div
      className={`card border w-full md:w-52 lg:w-72 p-4 mb-4 justify-between cursor-pointer relative hover:bg-accent-focus active:bg-accent 
             ${isSelected ? "bg-accent" : ``} `}
      onClick={handleToggleSelection}
    >
      <div className="w-5 h-5 absolute right-[2px] border border-gray-500 rounded-full flex items-center justify-center mr-4">
        {isSelected ? "✔" : ""}
      </div>
      <div>
        <p>Factura #{factura.numFactura}</p>
        <p>Monto: ₡{formatearMonto(factura.monto)}</p>
        <p>Periodo: {factura.fecha.split("T")[0]}</p>
        <p>Vencimiento: {factura.vencimiento.split("T")[0]}</p>
      </div>
    </div>
  );
};

export default FacturaItem;
