function ocultarCorreo(correo) {
    const partes = correo.split('@');
    const username = partes[0];
    const dominio = partes[1];
    
    const longitudUsername = username.length;
    
    // Mostrar los primeros 5 caracteres, luego 4 asteriscos y los últimos 4 caracteres antes del arroba
    const correoOculto = '*****' + username.substring(longitudUsername - 4, longitudUsername) + '@' + dominio;
    
    return correoOculto;
  }
  
export default ocultarCorreo;