import { createContext, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import LoginContext from "../login/LoginContext";
import FileDownload from "js-file-download";

import { toast } from "react-toastify";

const InformesContext = createContext();

export const InformesProvider = ({ children }) => {
  const { user } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(true);

  const [informes, setInformes] = useState([]);
  const [informe, setInforme] = useState({});

  const params = useParams();
  const navigate = useNavigate();

  // Get all informes
  const getAllInformes = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/informes?aid=${asadaId}`
          : `http://localhost:5050/api/v1/informes?aid=${asadaId}`,
        {
          withCredentials: true, // Include credentials (cookies) in the request
        }
      );
      const data = await response.data;
      setInformes(data.informes);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Get all informes
  const getAllInformesPrivate = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/informes/private?aid=${asadaId}`
          : `http://localhost:5050/api/v1/informes/private?aid=${asadaId}`,
        {
          withCredentials: true, // Include credentials (cookies) in the request
        }
      );
      const data = await response.data;
      setInformes(data.informes);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Get informe by Id
  const getSingleInforme = async (informeId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/informes/${informeId}`
          : `http://localhost:5050/api/v1/informes/${informeId}`,
        {
          withCredentials: true, // Include credentials (cookies) in the request
        }
      );
      const data = await response.data;
      const informe = data.informe;
      setInforme(informe);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Get informe by Id
  const getInforme = async (id) => {
    setIsLoading(true);
    const response = await axios.get(`/api/informes/${id}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = await response.data;
    setInforme(data);

    setIsLoading(false);
    return data;
  };

  // Download informe
  const downloadInformeDocumentClient = async (id) => {
    setIsLoading(true);
    try {
      const download = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/informes/${id}/download`
          : `http://localhost:5050/api/v1/informes/${id}/download`,
        {
          responseType: "blob",
          withCredentials: true,
        }
      );
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/informes/${id}`
          : `http://localhost:5050/api/v1/informes/${id}`,
        { withCredentials: true }
      );
      const data = await response.data;
      const { file } = data.informe;

      FileDownload(download.data, `${file.split("/")[5]}`);
    } catch (error) {
      console.error("Error downloading data:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Display/show informe in different tab
  const displayInforme = async (informeId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/informes/${informeId}/display`
          : `http://localhost:5050/api/v1/informes/${informeId}/display`,
        {
          responseType: "blob",
          withCredentials: true,
        }
      );

      const data = await response.data;

      let file = new Blob([data], {
        type: "application/pdf",
      });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      console.error("Error downloading data:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }

    // const ext = response.data.type.split("/")[1];
    // if (ext === "pptx" || ext === "ppt") {
    //   file = new Blob([response.data], {
    //     type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    //   });
    // }

    // if (ext === "pdf") {
    //   file = new Blob([response.data], {
    //     type: "application/pdf",
    //   });
    // }
  };

  // Create informe
  const createInforme = async (newInforme, asadaId) => {
    setIsLoading(true);
    try {
      let response;
      if (asadaId) {
        response = await axios.post(
          process.env.REACT_APP_NODE_ENV === "production"
            ? `${process.env.REACT_APP_BASE_URL}/api/v1/informes?aid=${asadaId}`
            : `http://localhost:5050/api/v1/informes?aid=${asadaId}`,
          newInforme,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/informes?aid=${process.env.REACT_APP_ASADA_ID}`,
          newInforme,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      }

      const data = await response.data;
      const informe = data.informe;

      setInformes(...informes, informe);
      toast.success(`Se ha agregado un nuevo informe a la ASADA.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (asadaId) await getAllInformesPrivate(asadaId);
    } catch (error) {
      console.log(error);
      toast.error(`Ha ocurrido un error al agregar el informe. Error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Update informe
  const updateInforme = async (newInforme, informeId) => {
    setIsLoading(true);
    try {
      const response = await axios.patch(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/informes/${informeId}`
          : `http://localhost:5050/api/v1/informes/${informeId}`,
        newInforme,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      const data = await response.data;
      const informe = data.informe;

      // await getInformes();
      await getSingleInforme(informeId);
      setIsLoading(false);
      toast.info(`Informe actualizado: "${informe.nombre}"`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(`Ha ocurrido un error al editar el informe. Error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      throw new Error(`Error al actualizar informe: ${newInforme}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Update informe
  const updateInformeAdmin = async (newInforme, informeId, restore) => {
    setIsLoading(true);
    try {
      const response = await axios.patch(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/informes/${informeId}${restore ? "?restore=true" : ""}`
          : `http://localhost:5050/api/v1/informes/${informeId}${restore ? "?restore=true" : ""}`,
        newInforme,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      const data = await response.data;
      const informe = data.informe;

      // await getInformes();
      await getSingleInforme(informeId);

      toast.info(`Informe actualizado: "${informe.nombre}"`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(`Ha ocurrido un error al editar el informe. Error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(`Error al actualizar informe: ${newInforme}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete informe
  const deleteInforme = async (informeId, force) => {
    setIsLoading(true);
    try {
      await axios.delete(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/informes/${informeId}${force ? "?force=true" : ""}`
          : `http://localhost:5050/api/v1/informes/${informeId}${force ? "?force=true" : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      await getAllInformes(process.env.REACT_APP_ASADA_ID);
      toast.info(`Se ha borrado el registro del informe`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.info(`Ha occurrido un error al borrar el informe: ${error}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(`Error al borrar informe: ${informe.nombre}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <InformesContext.Provider
      value={{
        informes,
        informe,
        isLoading,
        setIsLoading,
        setInforme,
        getInforme,
        updateInforme,
        downloadInformeDocumentClient,
        displayInforme,
        deleteInforme,
        getAllInformes,
        createInforme,
        getAllInformesPrivate,
        getSingleInforme,
        updateInformeAdmin,
      }}
    >
      {children}
    </InformesContext.Provider>
  );
};

export default InformesContext;
