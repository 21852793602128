import { useEffect, useState, useContext } from "react";
import { Wizard } from "react-use-wizard";
import { motion } from "framer-motion";
import { NavLink, useLocation } from "react-router-dom";
import {
  getCISAInformacionAbonado,
  getCISABuscarRecibosPendientes,
  postCISAGenerarToken,
  sinpeEjecutarAvisoPago,
  tarjetaEjecutarAvisoPago,
} from "../utils/cisaWS";
import FacturasPendientesList from "../components/servicios/FacturasPendientesList";
import MetodosDePago from "../components/servicios/MetodosDePago";
import EnviarCodigo from "../components/servicios/EnviarCodigo";
import VerificarCodigo from "../components/servicios/VerificarCodigo";
import PagoConfirmado from "../components/servicios/PagoConfirmado";
import formatearMonto from "../utils/formatearMonto";
import AsadaContext from "../context/asada/asadaContext";
import EjecutarPagoTarjeta from "../components/servicios/EjecutarPagoTarjeta";
import RedireccionarPagoTarjeta from "../components/servicios/RedireccionarPagoTarjeta";
import { useSessionStorage } from "../components/hooks/useSessionStorage";

// Framer motion variants
const pageVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};
const pageTransition = {
  duration: 0.5,
};

// Example: Wrap steps in an `<AnimatePresence` from framer-motion
// const Wrapper = () => <AnimatePresence exitBeforeEnter />;

function Pagar() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const abonado = params.get("abonado");
  const { getSingleAsada, asada } = useContext(AsadaContext);

  const [abonadoInfo, setAbonadoInfo] = useState(null);
  const [facturasPendientes, setFacturasPendientes] = useState(null);
  const [facturasSeleccionadas, setFacturasSeleccionadas] = useState([]); // [factura1, factura2, ...
  const [montoAPagar, setMontoAPagar] = useState(0);
  const [metodoDePagoSeleccionado, setMetodoDePagoSeleccionado] = useState(null); // 1 = "SINPE" | 2 = "TARJETA"
  const [generarTokenRespuesta, setGenerarTokenRespuesta] = useState(null);
  const [respuestaAvisoDePago, setRespuestaAvisoDePago] = useState(1);
  const [processUrl, setProcessUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAccepted, setModalAccepted] = useSessionStorage("modalAccepted", false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };
  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
    setModalAccepted(true); // Set the modalAccepted flag to true when the modal is closed or continued
  };

  useEffect(() => {
    if (!modalAccepted) {
      openModal();

      const handleKeyDown = (event) => {
        if (event.key === "Escape") {
          closeModal();
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      // Cleanup: remove event listener when component unmounts
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const info = await getCISAInformacionAbonado(abonado);
        const facturas = await getCISABuscarRecibosPendientes(abonado);
        await getSingleAsada(process.env.REACT_APP_ASADA_ID);
        setAbonadoInfo(info);
        setFacturasPendientes(facturas);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [abonado]);

  // useEffect(() => {
  //     console.log("Facturas seleccionadas actualizadas:", facturasSeleccionadas);
  // }, [facturasSeleccionadas]);

  // useEffect(() => {
  //     console.log("Respuesta:", generarTokenRespuesta);
  // }, [generarTokenRespuesta]);

  // useEffect(() => {
  //     console.log("Método de pago seleccionado:", metodoDePagoSeleccionado);
  // }, [metodoDePagoSeleccionado])

  const agregarFactura = (factura) => {
    setFacturasSeleccionadas((prevFacturas) => [...prevFacturas, factura]);
    setMontoAPagar((prevMonto) => (prevMonto += parseFloat(factura.monto.replace(/,/g, ""))));
  };

  const quitarFactura = (factura) => {
    setFacturasSeleccionadas((prevFacturas) =>
      prevFacturas.filter((facturaSeleccionada) => facturaSeleccionada.numFactura !== factura.numFactura)
    );
    setMontoAPagar((prevMonto) => (prevMonto -= parseFloat(factura.monto.replace(/,/g, ""))));
  };

  const generarToken = async (numAbonado, montoAPagar) => {
    const respuestaToken = await postCISAGenerarToken(numAbonado, montoAPagar);
    setGenerarTokenRespuesta(respuestaToken);
  };

  const handleMetodoDePago = (metodoDePago) => {
    setMetodoDePagoSeleccionado(metodoDePago);
  };

  // Restablece las facturas seleccionadas y el monto a pagar
  const resetValues = () => {
    setFacturasSeleccionadas([]);
    setMontoAPagar(0);
    setMetodoDePagoSeleccionado(null);
  };

  const construirAvisoPagoObject = (tipoDePago) => {
    return tipoDePago === "SINPE"
      ? {
          origin: "PAGO SINPE",
          amount: montoAPagar.toString(),
          phoneNumber: abonadoInfo.telefono,
          documentId: abonado, // numero de abonado
          description:
            facturasSeleccionadas.length > 1
              ? `PAGO FACTURAS: ${facturasSeleccionadas.map((factura) => factura.numFactura).join(", ")}`
              : `PAGO FACTURA: ${facturasSeleccionadas[0].numFactura}`,
          invoiceNumber:
            facturasSeleccionadas.length > 1
              ? facturasSeleccionadas.map((factura) => factura.numFactura).join(",")
              : facturasSeleccionadas[0].numFactura,
          additionalInfo: "PAGO FACTURAS PENDIENTES",
          userName: abonadoInfo.nombre,
          codEmpresa: process.env.REACT_APP_ASADA_NUMBER,
          apiToken: asada.apiToken,
          apiKeyEstablishment: asada.apiKeyEstablishment,
        }
      : {
          login: asada.tarjetaLogin,
          secretKey: asada.tarjetaSecretKey,
          codAbonado: abonado, // numero de abonado
          nombreAbonado: abonadoInfo.nombre,
          urlRetorno:
            process.env.REACT_APP_NODE_ENV === "production"
              ? `https://${window.location.hostname}/servicios?`
              : "http://localhost:3000/servicios?",
          codEmpresa: process.env.REACT_APP_ASADA_NUMBER,
          reference:
            facturasSeleccionadas.length > 1
              ? facturasSeleccionadas.map((factura) => factura.numFactura).join(",")
              : facturasSeleccionadas[0].numFactura,
          description:
            facturasSeleccionadas.length > 1
              ? `PAGO FACTURAS: ${facturasSeleccionadas.map((factura) => factura.numFactura).join(", ")}`
              : `PAGO FACTURA: ${facturasSeleccionadas[0].numFactura}`,
          total: montoAPagar.toString(),
        };
  };

  const ejecutarAvisoPagoSinpe = async () => {
    const avisoPago = construirAvisoPagoObject("SINPE");
    const respuestaAvisoPago = await sinpeEjecutarAvisoPago(avisoPago);
    setRespuestaAvisoDePago(respuestaAvisoPago.codRespuesta);
  };

  const ejecutarAvisoPagoTarjeta = async () => {
    const avisoPago = construirAvisoPagoObject("TARJETA");
    const respuestaAvisoPago = await tarjetaEjecutarAvisoPago(avisoPago);
    console.log(respuestaAvisoDePago);
    setProcessUrl(respuestaAvisoPago?.processUrl);
  };

  return (
    <motion.div exit="out" animate="in" initial="out" variants={pageVariants} transition={pageTransition}>
      <section>
        <div className="container mx-auto">
          <div className="title text-center py-6 text-3xl lg:text-6xl">
            <h1 className="border-b pb-5">Cancelar Facturas Pendientes</h1>
          </div>
          <div className="py-6 px-4">
            <p>
              <span className="font-bold">Abonado</span>: {abonadoInfo ? abonadoInfo.nombre : "Cargando..."}
            </p>
            <Wizard>
              <div>
                {facturasPendientes ? (
                  <FacturasPendientesList
                    facturas={facturasPendientes}
                    facturasSeleccionadas={facturasSeleccionadas}
                    agregarFactura={agregarFactura}
                    quitarFactura={quitarFactura}
                  />
                ) : (
                  "Cargando..."
                )}
              </div>
              <MetodosDePago
                facturasSeleccionadas={facturasSeleccionadas}
                montoAPagar={montoAPagar}
                abonado={abonadoInfo ? abonadoInfo.nombre : null}
                resetValues={resetValues}
                handleMetodoDePago={handleMetodoDePago}
              />

              {/* MOSTRAR ELEMENTOS RELACIONADOS A PAGO CON SINPE */}
              {metodoDePagoSeleccionado === "SINPE" && (
                <EnviarCodigo
                  abonado={abonadoInfo ? abonadoInfo : null}
                  generarToken={generarToken}
                  numAbonado={abonado}
                  montoAPagar={montoAPagar}
                  resetValues={resetValues}
                />
              )}
              {metodoDePagoSeleccionado == "SINPE" && (
                <VerificarCodigo
                  codigoVerificacion={generarTokenRespuesta ? generarTokenRespuesta.token : null}
                  ejecutarAvisoPago={ejecutarAvisoPagoSinpe}
                  resetValues={resetValues}
                />
              )}
              {metodoDePagoSeleccionado == "SINPE" && (
                <PagoConfirmado codRespuesta={respuestaAvisoDePago} abonado={abonadoInfo ? abonadoInfo : null} />
              )}

              {/* MOSTRAR ELEMENTOS RELACIONADOS A PAGO CON TARJETA */}

              {metodoDePagoSeleccionado === "TARJETA" && (
                <EjecutarPagoTarjeta
                  resetValues={resetValues}
                  asada={asada}
                  ejecutarAvisoPagoTarjeta={ejecutarAvisoPagoTarjeta}
                />
              )}
              {metodoDePagoSeleccionado === "TARJETA" && (
                <RedireccionarPagoTarjeta processUrl={processUrl} asada={asada} />
              )}
            </Wizard>
            {Number(respuestaAvisoDePago) !== 0 ? (
              <div>
                <p className="my-4">
                  <span className="font-bold">Monto a pagar</span>: {formatearMonto(Math.abs(montoAPagar))}{" "}
                </p>
              </div>
            ) : (
              <></>
            )}
            <div className="flex flex-col items-start mb-4 border-t pt-4 text-xs">
              <p className="mb-2">El uso de este servicio está sujeto a los términos y condiciones.</p>
              <ul className="flex gap-2">
                <li>
                  <NavLink to="/terminos-y-condiciones" target="_blank" className="hover:underline">
                    Ver términos y condiciones
                  </NavLink>
                </li>
                |
                <li>
                  <NavLink to="/faq" target="_blank" className="hover:underline">
                    Ver preguntas frecuentes
                  </NavLink>
                </li>
                |
                <li>
                  <NavLink to="/privacidad" target="_blank" className="hover:underline">
                    Ver privacidad de la información
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className={`modal ${isModalOpen ? "modal-open" : ""}  px-3`}>
        <div className="modal-box max-w-2xl">
          <div className="flex items-center flex-col mt-4 ">
            <p>Al continuar utilizando este sitio, aceptas nuestros términos y condiciones.</p>
            <div className="flex flex-wrap gap-3 p-5">
              <NavLink to="/faq" target="_blank" className="flex-grow">
                <article className="rounded-md cursor-pointer shadow-xl flex flex-col items-center p-3 bg-base-200 hover:scale-[.97] transition-all">
                  <img src="https://asadascr.com/icons/faq.svg" alt="Preguntas frecuentes" className="w-16 h-16" />
                  <p className="text-sm text-center">Preguntas frecuentes</p>
                </article>
              </NavLink>
              <NavLink to="/terminos-y-condiciones" target="_blank" className="flex-grow">
                <article className="rounded-md cursor-pointer shadow-xl flex flex-col items-center p-3 bg-base-200 hover:scale-[.97] transition-all">
                  <img src="https://asadascr.com/icons/terms.svg" alt="Términos y condiciones" className="w-16 h-16" />
                  <p className="text-sm text-center">Términos y condiciones</p>
                </article>
              </NavLink>
              <NavLink to="/privacidad" target="_blank" className="flex-grow">
                <article className="rounded-md cursor-pointer shadow-xl flex flex-col items-center p-3 bg-base-200 hover:scale-[.97] transition-all">
                  <img src="https://asadascr.com/icons/privacy.svg" alt="Privacidad de datos" className="w-16 h-16" />
                  <p className="text-sm text-center">Privacidad de la información</p>
                </article>
              </NavLink>
            </div>
            <button onClick={closeModal} className="btn btn-accent mt-4">
              Continuar
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Pagar;
