import { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import AsadaContext from "../context/asada/asadaContext";
import {
  AboutUsHero,
  AboutUsJuntaDirectiva,
  AboutUsMV,
  // AboutUsValues,
} from "./sections/AboutUs";
import { motion } from "framer-motion";

// Framer motion variants
const pageVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  duration: 0.5,
};

function AboutUs() {
  const { asada, getSingleAsada } = useContext(AsadaContext);
  const { mision, vision, integrantes, fotoEstaticas } = asada;

  const { hash } = useLocation();
  const historiaRef = useRef();
  const mvRef = useRef();
  const valoresRef = useRef();
  const juntaRef = useRef();

  useEffect(() => {
    const fetchSingleAsada = async (asadaId) => await getSingleAsada(asadaId);
    if (Object.keys(asada).length === 0) fetchSingleAsada(process.env.REACT_APP_ASADA_ID);

    setTimeout(() => {
      checkHashInURL();
    }, 800);
  }, []);

  function checkHashInURL() {
    switch (hash) {
      case "#historia":
        historiaRef.current.scrollIntoView();
        break;
      case "#mv":
        mvRef.current.scrollIntoView();
        break;
      // case "#valores":
      //   valoresRef.current.scrollIntoView();
      //   break;
      case "#junta":
        juntaRef.current.scrollIntoView();
        break;
      default:
        window.scrollTo(0, 0);
        break;
    }
  }

  return (
    <motion.div exit="out" animate="in" initial="out" variants={pageVariants} transition={pageTransition}>
      <div ref={historiaRef}>
        <AboutUsHero imagenes={fotoEstaticas} asada={asada} />
      </div>
      <div ref={mvRef}>
        <AboutUsMV mision={mision} vision={vision} />
      </div>
      {/*    <div ref={valoresRef}>
        <AboutUsValues />
      </div> */}
      <div ref={juntaRef}>
        <AboutUsJuntaDirectiva integrantes={integrantes} />
      </div>
    </motion.div>
  );
}

export default AboutUs;
