import { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LoginContext from "../context/login/LoginContext";
import axios from "axios";

const BancoProcesadorContext = createContext();

export const BancoProcesadorProvider = ({ children }) => {
    const { user } = useContext(LoginContext);
    const [isLoading, setIsLoading] = useState(false);
    const [bancos, setBancos] = useState(null);
    const [banco, setBanco] = useState(null);

    // Get all bancos (ADMIN)
    const getAllBancosProcesadores = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(
                process.env.REACT_APP_NODE_ENV === "production"
                    ? `${process.env.REACT_APP_BASE_URL}/api/v1/bancos-procesadores`
                    : `http://localhost:5050/api/v1/bancos-procesadores`,
                {
                    withCredentials: true, // Include credentials (cookies) in the request
                }
            );

            const data = response.data;
            const bancos = data.bancos;
            setBancos(bancos);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Get single banco
    const getSingleBancoProcesador = async (bancoId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(
                process.env.REACT_APP_NODE_ENV === "production"
                    ? `${process.env.REACT_APP_BASE_URL}/api/v1/bancos-procesadores/${bancoId}`
                    : `http://localhost:5050/api/v1/bancos-procesadores/${bancoId}`
            );
            const data = await response.data;
            setBanco(data.banco);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Create a banco (ADMIN)
    const createBancoProcesador = async (banco) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                process.env.REACT_APP_NODE_ENV === "production"
                    ? `${process.env.REACT_APP_BASE_URL}/api/v1/bancos-procesadores`
                    : `http://localhost:5050/api/v1/bancos-procesadores`,
                banco,
                {
                    withCredentials: true, // Include credentials (cookies) in the request
                }
            );

            return response;
        } catch (error) {
            console.error("Error creating banco:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Update a banco (ADMIN)
    const updateBancoProcesador = async (banco, bancoId) => {
        setIsLoading(true);
        try {
            const response = await axios.patch(
                process.env.REACT_APP_NODE_ENV === "production"
                    ? `${process.env.REACT_APP_BASE_URL}/api/v1/bancos-procesadores/${bancoId}`
                    : `http://localhost:5050/api/v1/bancos-procesadores/${bancoId}`,
                banco,
                {
                    withCredentials: true, // Include credentials (cookies) in the request
                }
            );
            return response;
        } catch (error) {
            console.error("Error updating banco:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Delete a banco (ADMIN)
    const deleteBancoProcesador = async (bancoId) => {
        setIsLoading(true);
        try {
            const response = await axios.delete(
                process.env.REACT_APP_NODE_ENV === "production"
                    ? `${process.env.REACT_APP_BASE_URL}/api/v1/bancos-procesadores/${bancoId}`
                    : `http://localhost:5050/api/v1/bancos-procesadores/${bancoId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true, // Include credentials (cookies) in the request
                }
            );
            console.log(response);
            return response;
        } catch (error) {
            console.error("Error deleting banco:", error);
            throw new Error(`deleting: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <BancoProcesadorContext.Provider
            value={{
                getAllBancosProcesadores,
                getSingleBancoProcesador,
                createBancoProcesador,
                updateBancoProcesador,
                deleteBancoProcesador,
                setBanco,
                isLoading,
                bancos,
                banco,
            }}
        >
            {children}
        </BancoProcesadorContext.Provider>
    );
};

export default BancoProcesadorContext;
