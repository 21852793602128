import AdminLayout from "./components/layout/AdminLayout";
import ClientLayout from "./components/layout/ClientLayout";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import PostsClient from "./components/posts/PostsClient";
import AboutUs from "./pages/AboutUs";
import Projects from "./pages/Projects";
import Services from "./pages/Services";
import Pagar from "./pages/Pagar";
import Contact from "./pages/Contact";
import Documentation from "./pages/Documentation";
import Jobs from "./components/jobs/Jobs";
import TanksClient from "./components/tanks/TanksClient";
import GradientesClient from "./components/quiebraGradientes/GradientesClient";
/* Admin layout components */
import Dashboard from "./components/layout/Dashboard";
import ImagenesAsada from "./components/asada/ImagenesAsada";
import Contacto from "./components/asada/Contacto";
import ContactoAdd from "./components/asada/ContactoAdd";
import ContactoUpdate from "./components/asada/ContactoUpdate";
import DocsAdmin from "./components/layout/DocsAdmin";
import GeneralInfo from "./components/asada/GeneralInfo";
import UserLogin from "./components/users/UserLogin";
import SuggestionList from "./components/suggestions/SuggestionList";
import SuggestionItem from "./components/suggestions/SuggestionItem";
import FormsList from "./components/forms/FormsList";
import FormAdd from "./components/forms/FormAdd";
import FormItem from "./components/forms/FormItem";
import JobList from "./components/jobs/JobList";
import JobItem from "./components/jobs/JobItem";
import PostsAdmin from "./components/posts/PostsAdmin";
import PostAdd from "./components/posts/PostAdd";
import PostItem from "./components/posts/PostItem";
import { ToastContainer } from "react-toastify";
import JuntaDirectiva from "./components/juntaDirectiva/JuntaDirectiva";
import JuntaDirectivaAdd from "./components/juntaDirectiva/JuntaDirectivaAdd";
import JuntaDirectivaItem from "./components/juntaDirectiva/JuntaDirectivaItem";
import Users from "./components/users/Users";
import UserAdd from "./components/users/UserAdd";
import UserItem from "./components/users/UserItem";
import UserPasswordChange from "./components/users/UserPasswordChange";
import ProjectList from "./components/projects/ProjectList";
import ProjectAdd from "./components/projects/ProjectAdd";
import ProjectItem from "./components/projects/ProjectItem";
import TanksAdmin from "./components/tanks/TanksAdmin";
import TankAdd from "./components/tanks/TankAdd";
import TankItem from "./components/tanks/TankItem";
import GradientesAdmin from "./components/quiebraGradientes/GradientesAdmin";
import GradienteAdd from "./components/quiebraGradientes/GradienteAdd";
import GradienteItem from "./components/quiebraGradientes/GradienteItem";
import InformesList from "./components/informes/InformesList";
import ReglamentosList from "./components/reglamentos/ReglamentosList";
import ReglamentosAdd from "./components/reglamentos/ReglamentosAdd";
import ReglamentosItem from "./components/reglamentos/ReglamentosItem";
import InformesAdd from "./components/informes/InformesAdd";
import InformesItem from "./components/informes/InformesItem";
import AsadaList from "./components/asada/admin/AsadaList";
/* FRAMER MOTION */
import { AnimatePresence } from "framer-motion";
import TransparenciaList from "./components/transparencia/TransparenciaList";
import TransparenciaAdd from "./components/transparencia/TransparenciaAdd";
import TransparenciaItem from "./components/transparencia/TransparenciaItem";
import Gallery from "./pages/Gallery";
import LayoutProvider from "./components/layout/LayoutProvider";
import AsadaItem from "./components/asada/admin/AsadaItem";
import AsadaAdd from "./components/asada/admin/AsadaAdd";
import SugerenciaList from "./components/suggestions/admin/SugerenciaList";
import InformesListAdmin from "./components/informes/admin/InformesListAdmin";
import FormulariosListAdmin from "./components/forms/admin/FormulariosListAdmin";
import ReglamentosListAdmin from "./components/reglamentos/admin/ReglamentosListAdmin";
import TransparenciaListAdmin from "./components/transparencia/admin/TransparenciaListAdmin";
import SolicitudesListAdmin from "./components/jobs/admin/SolicitudesListAdmin";
import TanquesListAdmin from "./components/tanks/admin/TanqueListAdmin";
import GradienteListAdmin from "./components/quiebraGradientes/admin/GradienteListAdmin";
import ProyectoListAdmin from "./components/projects/admin/ProyectoListAdmin";
import PublicacionesListAdmin from "./components/posts/admin/PublicacionesListAdmin";
import ContactoListAdmin from "./components/asada/admin/ContactoListAdmin";
import IntegrantesListAdmin from "./components/juntaDirectiva/admin/IntegrantesListAdmin";
import NotFound from "./components/shared/NotFound";
import PreguntasFrecuentes from "./components/servicios/PreguntasFrecuentes";
import BancosProcesadoresAdmin from "./components/bancosProcesadores/BancosProcesadoresAdmin";
import BancosProcesadoresAdminAdd from "./components/bancosProcesadores/BancosProcesadoresAdminAdd";
import BancosProcesadoresAdminItem from "./components/bancosProcesadores/BancosProcesadoresAdminItem";
import TerminosYCondiciones from "./components/servicios/TerminosYCondiciones";
import ContratoDePrivacidad from "./components/servicios/ContratoDePrivacidad";
import AsadaGeneralInfo from "./components/asada/admin/AsadaGeneralInfo";
import AsadaConfiguraciones from "./components/asada/admin/AsadaConfiguraciones";
import Historia from "./pages/sections/AboutUs/Historia"; 
import PozosListAdmin from "./components/pozos/admin/PozosListAdmin";
import PozoAdd from "./components/pozos/PozoAdd";
import PozoItem from "./components/pozos/PozoItem";
import PozoAdmin from "./components/pozos/PozoAdmin";
import PozosClient from "./components/pozos/PozosClient";
import ThemeSelectPage from './pages/ThemeSelectPage';

const theme = localStorage.getItem('theme') || 'defecto';
document.querySelector('html').setAttribute('data-theme', theme);

function App() {
  const location = useLocation();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LayoutProvider>
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<ClientLayout />}>
              <Route index element={<Home />} />
              <Route path="nosotros" element={<AboutUs />} />
              <Route path="historia" element={<Historia />} />
              <Route path="publicaciones" element={<PostsClient />} />
              <Route path="proyectos" element={<Projects />} />
              <Route path="servicios" element={<Services />} />
              <Route path="servicios/pagar" element={<Pagar />} />
              <Route path="faq" element={<PreguntasFrecuentes />} />
              <Route
                path="terminos-y-condiciones"
                element={<TerminosYCondiciones />}
              />
              <Route path="privacidad" element={<ContratoDePrivacidad />} />
              <Route path="contacto" element={<Contact />} />
              <Route path="contacto/curriculum" element={<Jobs />} />
              <Route path="documentacion" element={<Documentation />} />
              <Route path="tanques" element={<TanksClient />} />
              <Route path="quiebraGradientes" element={<GradientesClient />} />
              <Route path="pozos" element={<PozosClient />} />
              <Route path="galeria" element={<Gallery />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            {/* <Route path="/admin" element={<AdminLayout />}>
              <Route path="dashboard" element={<Dashboard />}>
                <Route path="general" element={<GeneralInfo />} />
                <Route path="imagenes" element={<ImagenesAsada />} />
                <Route path="contacto" element={<Contacto />} />
                <Route path="contacto/add" element={<ContactoAdd />} />
                <Route path="contacto/:id" element={<ContactoUpdate />} />
                <Route path="juntaDirectiva" element={<JuntaDirectiva />} />
                <Route path="theme" element={<ThemeSelectPage />} />

                <Route
                  path="juntaDirectiva/add"
                  element={<JuntaDirectivaAdd />}
                />
                <Route
                  path="juntaDirectiva/:id"
                  element={<JuntaDirectivaItem />}
                />
                <Route path="users" element={<Users />} />
                <Route path="users/add" element={<UserAdd />} />
                <Route path="users/:id" element={<UserItem />} />
                <Route
                  path="users/:id/changePassword"
                  element={<UserPasswordChange />}
                />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="asadas" element={<AsadaList />} />
              <Route path="asadas/:id" element={<AsadaGeneralInfo />}>
                <Route path="general" element={<AsadaItem />} />
                <Route
                  path="configuraciones"
                  element={<AsadaConfiguraciones />}
                />
              </Route>
              <Route path="asadas/add" element={<AsadaAdd />} />
              <Route
                path="bancos-procesadores"
                element={<BancosProcesadoresAdmin />}
              />
              <Route
                path="bancos-procesadores/add"
                element={<BancosProcesadoresAdminAdd />}
              />
              <Route
                path="bancos-procesadores/:id"
                element={<BancosProcesadoresAdminItem />}
              />
              <Route path="suggestions" element={<SuggestionList />} />
              <Route path="suggestions/:id" element={<SuggestionItem />} />
              <Route path="sugerencias" element={<SugerenciaList />} />
              <Route path="sugerencias/:id" element={<SuggestionItem />} />
              <Route path="documentacion" element={<DocsAdmin />}>
                <Route path="informes" element={<InformesListAdmin />} />
                <Route path="informes/:id" element={<InformesItem />} />
                <Route path="informes/add" element={<InformesAdd />} />
                <Route path="formularios" element={<FormulariosListAdmin />} />
                <Route path="formularios/add" element={<FormAdd />} />
                <Route path="formularios/:id" element={<FormItem />} />
                <Route path="reglamentos" element={<ReglamentosListAdmin />} />
                <Route path="reglamentos/add" element={<ReglamentosAdd />} />
                <Route path="reglamentos/:id" element={<ReglamentosItem />} />
                <Route
                  path="transparencia"
                  element={<TransparenciaListAdmin />}
                />
                <Route
                  path="transparencia/add"
                  element={<TransparenciaAdd />}
                />
                <Route
                  path="transparencia/:id"
                  element={<TransparenciaItem />}
                />
              </Route>
              <Route path="solicitudes" element={<SolicitudesListAdmin />} />
              <Route path="solicitudes/:id" element={<JobItem />} />
              <Route path="tanques" element={<TanquesListAdmin />} />
              <Route path="tanques/add" element={<TankAdd />} />
              <Route path="tanques/:id" element={<TankItem />} />
              <Route path="gradiente" element={<GradienteListAdmin />} />
              <Route path="gradiente/add" element={<GradienteAdd />} />
              <Route path="gradiente/:id" element={<GradienteItem />} />
              <Route path="proyectos" element={<ProyectoListAdmin />} />
              <Route path="proyectos/add" element={<ProjectAdd />} />
              <Route path="proyectos/:id" element={<ProjectItem />} />
              <Route
                path="publicaciones"
                element={<PublicacionesListAdmin />}
              />
              <Route path="publicaciones/add" element={<PostAdd />} />
              <Route path="publicaciones/:id" element={<PostItem />} />
              <Route path="contactos" element={<ContactoListAdmin />} />
              <Route path="contactos/add" element={<ContactoAdd />} />
              <Route path="contactos/:id" element={<ContactoUpdate />} />
              <Route path="integrantes" element={<IntegrantesListAdmin />} />
              <Route path="integrantes/add" element={<JuntaDirectivaAdd />} />
              <Route path="integrantes/:id" element={<JuntaDirectivaItem />} />

              <Route path="docs" element={<DocsAdmin />}>
                <Route path="formularios" element={<FormsList />} />
                <Route path="formularios/add" element={<FormAdd />} />
                <Route path="formularios/:id" element={<FormItem />} />
                <Route path="informes" element={<InformesList />} />
                <Route path="informes/add" element={<InformesAdd />} />
                <Route path="informes/:id" element={<InformesItem />} />
                <Route path="reglamentos" element={<ReglamentosList />} />
                <Route path="reglamentos/add" element={<ReglamentosAdd />} />
                <Route path="reglamentos/:id" element={<ReglamentosItem />} />
                <Route path="transparencia" element={<TransparenciaList />} />
                <Route
                  path="transparencia/add"
                  element={<TransparenciaAdd />}
                />
                <Route
                  path="transparencia/:id"
                  element={<TransparenciaItem />}
                />
              </Route>
              <Route path="jobs" element={<JobList />} />
              <Route path="jobs/:id" element={<JobItem />} />
              <Route path="posts" element={<PostsAdmin />} />
              <Route path="posts/add" element={<PostAdd />} />
              <Route path="posts/:id" element={<PostItem />} />
              <Route path="projects" element={<ProjectList />} />
              <Route path="projects/add" element={<ProjectAdd />} />
              <Route path="projects/:id" element={<ProjectItem />} />
              <Route path="tanks" element={<TanksAdmin />} />
              <Route path="tanks/add" element={<TankAdd />} />
              <Route path="tanks/:id" element={<TankItem />} />
              <Route path="gradientes" element={<GradientesAdmin />} />
              <Route path="gradientes/add" element={<GradienteAdd />} />
              <Route path="gradientes/:id" element={<GradienteItem />} />
              <Route path="pozos" element={<PozoAdmin />} />
              <Route path="pozos/add" element={<PozoAdd />} />
              <Route path="pozos/:id" element={<PozoItem />} />
              <Route path="pozosadmin" element={<PozosListAdmin />} />
              <Route path="pozosadmin/add" element={<PozoAdd />} />
              <Route path="pozosadmin/:id" element={<PozoItem />} />

              <Route path="login" element={<UserLogin />} />
              <Route path="*" element={<NotFound />} />
            </Route> */}
          </Routes>
        </AnimatePresence>
      </LayoutProvider>
    </>
  );
}

export default App;
