import { useContext, useEffect } from "react";
import AsadaContext from "../context/asada/asadaContext";
import {
  LandingPage,
  Cards,
  Tanques,
  Gradientes,
  HomeProjects,
  HomeServicesHome,
  HomePhotoGallery,
  HomePayments,
  Pozos,
} from "./sections/Home";

import { motion } from "framer-motion";

// Framer motion variants
const pageVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  duration: 0.5,
};

function Home() {
  const { asada, getSingleAsada } = useContext(AsadaContext);

  useEffect(() => {
    const fetchSingleAsada = async (asadaId) => await getSingleAsada(asadaId);
    fetchSingleAsada(process.env.REACT_APP_ASADA_ID);
  }, []);

  return (
    <motion.div
      exit='out'
      animate='in'
      initial='out'
      variants={pageVariants}
      transition={pageTransition}
    >
      <LandingPage asada={asada} />
      <Cards asada={asada} />
      <Tanques asada={asada} />
      {asada && asada?.gradientes > 0 && (
        <Gradientes asada={asada} />
      )}
      {asada && asada?.pozos > 0 && (
        <Pozos asada={asada} />
      )}
      <HomeServicesHome asada={asada} />
      <HomePayments asada={asada} />
      <HomeProjects asada={asada} />
      <HomePhotoGallery fotos={asada.fotos} />
    </motion.div>
  );
}

export default Home;
