import { useEffect, useState, useContext, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BancoProcesadorContext from "../../context/bancoProcesadorContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; //React-Toastify CSS
import Modal from "../shared/Modal";

function BancosProcesadoresAdminItem() {
    const {
        getSingleBancoProcesador,
        updateBancoProcesador,
        deleteBancoProcesador,
        banco,
        isLoading,
        setBanco,
    } = useContext(BancoProcesadorContext);
    const navigate = useNavigate();
    const params = useParams();
    const { id } = params;
    const [newAcronimo, setNewAconimo] = useState("");
    const [newNombre, setNewNombre] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    let { acronimo, nombre } = banco || {};

    useEffect(() => {
        const fetchBanco = async (bancoId) => {
            await getSingleBancoProcesador(bancoId);
        };
        fetchBanco(id);
    }, []);

    useLayoutEffect(() => {
        return () => {
            setBanco(null);
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const bancoObj = {
            acronimo: newAcronimo || acronimo,
            nombre: newNombre || nombre,
        };

        await updateBancoProcesador(bancoObj, id);
        toast.info(`Banco procesador actualizado exitosamente`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        if (
            window.confirm(
                "Se eliminará permanentemente de la base de datos el registro del banco. Esta acción no se puede revertir.\n ¿Seguro que desea continuar?"
            )
        ) {
            try {
                await deleteBancoProcesador(id);
                toast.info(`Banco procesador actualizado exitosamente`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate(-1);
            } catch (error) {
                toast.error(
                    `Error al eliminar banco procesador: ${error.message}`,
                    {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
        document.body.style.overflow = "hidden";
    };

    const closeModal = () => {
        setIsModalOpen(false);
        document.body.style.overflow = "auto";
    };

    return (
        <div className="rounded-lg shadow-lg card bg-base-100 ">
            <div className="card-body ">
                <h2 className="text-2xl my-4 font-bold card-title">
                    <button
                        onClick={() => navigate(-1)}
                        className="btn btn-outline btn-accent btn-sm hover:text-white"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 "
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                    Editar banco procesador
                </h2>

                <div className="flex justify-center text-lg">
                    <form className="w-full " onSubmit={handleSubmit}>
                        <div className="flex">
                            <div className="flex-1 w-1/2">
                                <div className="w-full px-3">
                                    <label
                                        className="block tracking-wide  text-xs font-bold mb-2"
                                        htmlFor="grid-acronimo"
                                    >
                                        Acrónimo *
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-acronimo"
                                        type="text"
                                        required
                                        name="acronimo"
                                        placeholder="Ingresar acrónimo del banco"
                                        defaultValue={acronimo}
                                        onChange={(e) =>
                                            setNewAconimo(e.target.value)
                                        }
                                    />
                                </div>

                                <div className="w-full px-3">
                                    <label
                                        className="block tracking-wide  text-xs font-bold mb-2"
                                        htmlFor="grid-nombre"
                                    >
                                        Nombre *
                                    </label>
                                    <textarea
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-nombre"
                                        type="text"
                                        required
                                        name="nombre"
                                        placeholder="Ingresar nombre del banco procesador"
                                        defaultValue={nombre}
                                        onChange={(e) =>
                                            setNewNombre(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <small className="ml-1 text-2xs text-gray-500">
                            * Campos requeridos
                        </small>
                        <div className="divider"></div>

                        <div className="card-actions justify-start">
                            <button
                                className={`btn btn-success hover:bg-green-700 capitalize ${
                                    isLoading ? "loading" : ""
                                }`}
                                type="submit"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                                </svg>
                                &nbsp;Guardar cambios
                            </button>
                            <button
                                className="btn btn-error hover:bg-red-700 capitalize"
                                type="button"
                                onClick={handleDelete}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                &nbsp;Borrar Banco
                            </button>
                            <button
                                type="button"
                                className="btn btn-ghost ml-2"
                                onClick={openModal}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
                <div>
                    <p className="font-light text-lg">Consideraciones</p>
                    <ul className="list-disc">
                        <li className="font-light text-sm my-1">
                            Los campos requeridos están marcados con un
                            asterisco (*).
                        </li>
                    </ul>
                </div>
            </Modal>
        </div>
    );
}

export default BancosProcesadoresAdminItem;
