import { createContext, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LoginContext from "../login/LoginContext";
import baseUrl from '../../utils/baseUrl';
import axios from 'axios';

import { toast } from 'react-toastify';

const PostContext = createContext();

export const PostProvider = ({ children }) => {
  const { user } = useContext(LoginContext);
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [publicaciones, setPublicaciones] = useState([]);
  const [publicacion, setPublicacion] = useState({});
  const [post, setPost] = useState({});

  // Get all posts
  const getAllPublicaciones = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/api/v1/publicaciones?aid=${asadaId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      const publicaciones = data.publicaciones;
      setPublicaciones(publicaciones);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Get all publicaciones private
  const getAllPublicacionesPrivate = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/api/v1/publicaciones/private?aid=${asadaId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      const publicaciones = data.publicaciones;
      setPublicaciones(publicaciones);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Get single publicacion
  const getSinglePublicacion = async (publicacionId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/api/v1/publicaciones/${publicacionId}`
      );
      const data = await response.data;
      const publicacion = data.publicacion;
      setPublicacion(publicacion);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Create a publicacion
  const createPublicacion = async (newPublicacion, asadaId) => {
    setIsLoading(true);
    try {
      let response;
      if (asadaId) {
        response = await axios.post(
          `${baseUrl}/api/v1/publicaciones?aid=${asadaId}`,
          newPublicacion,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          }
        );
      } else {
        response = await axios.post(
          `${baseUrl}/api/v1/publicaciones?aid=${process.env.REACT_APP_ASADA_ID}`,
          newPublicacion,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          }
        );
      }

      const data = await response.data;
      const publicacion = data.publicacion;

      setPublicaciones((prevPublicaciones) => [
        ...prevPublicaciones,
        publicacion,
      ]);

      toast.success(`Nueva publicación agregada: ${publicacion.titulo}`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (asadaId) await getAllPublicacionesPrivate(asadaId);
      else await getAllPublicaciones(process.env.REACT_APP_ASADA_ID);
    } catch (error) {
      console.log(error);
      toast.error(`Ha ocurrido un error al agregar la publicación.`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Update publicacion admin
  const updatePublicacionAdmin = async (
    newPublicacion,
    publicacionId,
    restore
  ) => {
    setIsLoading(true);
    try {
      const response = await axios.patch(
        `${baseUrl}/api/v1/publicaciones/${publicacionId}${
          restore ? '?restore=true' : ''
        }`,
        newPublicacion,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }
      );

      const data = await response.data;
      const publicacion = data.publicacion;

      await getSinglePublicacion(publicacionId);

      toast.info(`Publicación actualizada.`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(
        `Ha ocurrido un error al editar la publicación. Error: ${error}`,
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      throw new Error(`Error al actualizar publicación: ${newPublicacion}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Update a publicacion
  const updatePublicacion = async (publicacion, id) => {
    setIsLoading(true);
    try {
      const response = await axios.patch(
        `${baseUrl}/api/v1/publicaciones/${id}`,
        publicacion,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      const post = data.publicacion;
      setPublicacion(post);
      toast.info(`Se ha actualizado la información de la publicación`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      throw new Error(
        `Error al actualizar informacion de proyecto: ${publicacion.titulo}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const deletePublicacion = async (publicacionId, force) => {
    setIsLoading(true);
    try {
      await axios.delete(
        `${baseUrl}/api/v1/publicaciones/${publicacionId}${
          force ? '?force=true' : ''
        }`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );

      await getAllPublicaciones(process.env.REACT_APP_ASADA_ID);
      toast.info(`Se ha borrado el registro de la publicación`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      throw new Error(`Error al borrar publicación: ${publicacion.titulo}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete photo
  const deletePostPhoto = async (fotoId, publicacionId) => {
    setIsLoading(true);
    try {
      await axios.delete(`${baseUrl}/api/v1/fotos/${fotoId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      await getSinglePublicacion(publicacionId);
      toast.info(`Foto borrada de la publicación`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      throw new Error(
        `Error al actualizar informacion de la publicación: ${publicacion.titulo}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PostContext.Provider
      value={{
        setPost,
        isLoading,
        setIsLoading,
        posts,
        post,
        updatePublicacion,
        deletePostPhoto,
        getAllPublicaciones,
        publicaciones,
        publicacion,
        setPublicacion,
        setPublicaciones,
        getSinglePublicacion,
        createPublicacion,
        getAllPublicacionesPrivate,
        updatePublicacionAdmin,
        deletePublicacion,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};

export default PostContext;
