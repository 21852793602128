import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import defaultImg from "../../../assets/defaultImg.png";

function Gradientes({ asada }) {
  const { fotoEstaticas } = asada;
  const [gradienteImgFilename, setGradienteImgFilename] = useState("");

  useEffect(() => {
    if (fotoEstaticas !== undefined) {
      if (fotoEstaticas.length > 0) {
        const gradienteImg = fotoEstaticas.filter(
          (item) => item.isGradienteImg === true
        )[0];

        if (gradienteImg !== undefined) {
          setGradienteImgFilename(gradienteImg.path.split("/")[4]);
        }
      }
    }
  }, [fotoEstaticas]);

  return (
    <>
      <section className="quiebra-gradientes h-full mt-6 md:mt-0">
        <div className="quiebra-gradientes-wrapper grid grid-cols-1 md:grid-cols-2 ">
          <div className="quiebra-gradientes-image-container">
            <img
              src={
                gradienteImgFilename !== ''
                  ? `${process.env.REACT_APP_BASE_URL}/uploads/${gradienteImgFilename}`
                  : defaultImg
              }
              alt="Quiebragradientes"
            />
          </div>
          <div className="quiebra-gradientes-info flex flex-col justify-center items-center my-6 md:mb-0">
            <h2 className="xl:text-7xl lg:text-5xl md:text-4xl sm:text-4xl text-3xl mt-5 text-center px-3 xl:px-5 break-words max-w-full">
              Quiebragradientes
            </h2>

            <NavLink
              to="/quiebraGradientes"
              className="btn btn-primary hover:bg-secondary hover:scale-105 md:btn-md lg:btn-lg capitalize mt-5"
            >
              Ver más {''}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}

export default Gradientes;
