import { useContext, useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import formatearMonto from '../../utils/formatearMonto';
import AsadaContext from '../../context/asada/asadaContext';

function MetodosDePago({ facturasSeleccionadas, montoAPagar, abonado, resetValues, handleMetodoDePago }) {
    const asadaContext = useContext(AsadaContext);
    const { getMetodosDePagoAsada } = asadaContext;
    const [metodosDePagoAsada, setMetodosDePagoAsada] = useState([]);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);

    const {
        previousStep,
        nextStep,
    } = useWizard();

    useEffect(() => {
        const loadMetodosDePago = async () => {
            const metodosDePago = await getMetodosDePagoAsada();
            setMetodosDePagoAsada(metodosDePago);
        }
        loadMetodosDePago();
    }, []);

    const handleRadioBtnChange = () => {
        const sinpeRadio = document.querySelector('#sinpe');
        const tarjetaRadio = document.querySelector('#tarjeta');

        if (sinpeRadio !== null) {
            if (sinpeRadio.checked) {
                setIsBtnDisabled(false)
                handleMetodoDePago("SINPE")
            } 
        }

        if(tarjetaRadio !== null) {
            if (tarjetaRadio.checked) {
                setIsBtnDisabled(false)
                handleMetodoDePago("TARJETA")
            }
        }
    }

    const handlePreviousStep = () => {
        resetValues();
        previousStep();
    }

    const handleNextStep = () => {
        nextStep()
    }

    return (
        <div className="container">
            <h2 className="mb-4 font-bold">Métodos de pago</h2>
            <p className="mb-4 text-sm accent-teal-500">Estimado usuario, va a proceder a cancelar <span className='font-bold'>{facturasSeleccionadas.length} factura{facturasSeleccionadas.length > 1 ? "s" : ""} </span>por un monto de <span className='font-bold'>{formatearMonto(montoAPagar)}</span>, todas a nombre de “<span className='font-bold'>{abonado}</span>”.
                <br />Por favor, seleccione el método de pago:</p>
            <div className="form-control w-60 mb-4">
                {metodosDePagoAsada.length === 0 ? <p>No hay métodos de pago disponibles</p> :
                    <>
                        {metodosDePagoAsada.map((metodoDePago) => (
                            <label key={metodoDePago.metodoPagoId} className="label justify-start gap-3 cursor-pointer" onChange={handleRadioBtnChange}>
                                <input type="radio" name="radio-btn-pagos" className="radio checked:bg-accent" id={metodoDePago.metodoPagoId === 1 ? "sinpe" : "tarjeta"} />
                                <span className="label-text">{metodoDePago.metodoPagoId === 1 ? "SINPE" : "Tarjeta débito/crédito"}</span>
                            </label>
                        ))}
                    </>}
            </div>

            <div className='flex gap-3'>
                <button onClick={handlePreviousStep} className='btn bg-transparent hover:bg-accent capitalize'>Anterior</button>
                <button onClick={handleNextStep} className='btn bg-transparent hover:bg-accent capitalize' disabled={isBtnDisabled} >Siguiente</button>
            </div>
        </div>
    )
}

export default MetodosDePago;