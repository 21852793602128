import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AsadaContext from '../../context/asada/asadaContext';
import { NavLink } from 'react-router-dom';

function NavFooter() {
  const { asada, getSingleAsada } = useContext(AsadaContext);
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const fetchAsada = async () => {
      if (Object.keys(asada).length === 0) {
        await getSingleAsada(process.env.REACT_APP_ASADA_ID);
      }
    };

    fetchAsada();
  }, [asada, getSingleAsada]);

  return (
    <div className="container mx-auto grid place-items-center ">
      <div className="flex flex-col md:flex-row md:justify-around gap-5 py-2">
        <NavLink to="/" className={`btn btn-accent btn-outline capitalize`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
          </svg>
          &nbsp;Volver al inicio
        </NavLink>
        {pathname.includes('/quiebragradientes') ? null : (
          <NavLink
            to={`/quiebragradientes`}
            className={({ isActive }) =>
              `btn btn-accent btn-outline mt-2 md:mt-0 capitalize ${
                isActive ? 'text-red-700' : ''
              }`
            }
          >
            Quiebragradientes&nbsp;
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </NavLink>
        )}
        {pathname.includes('/tanques') ? null : (
          <NavLink
            to={`/tanques`}
            className={({ isActive }) =>
              `btn btn-accent btn-outline mt-2 md:mt-0 capitalize ${
                isActive ? 'btn-primary' : ''
              }`
            }
          >
            Tanques&nbsp;
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </NavLink>
        )}
        {pathname.includes('/pozos') ? null : (
          <NavLink
            to={`/pozos`}
            className={({ isActive }) =>
              `btn btn-accent btn-outline mt-2 md:mt-0 capitalize ${
                isActive ? 'btn-primary' : ''
              }`
            }
          >
            Pozos&nbsp;
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </NavLink>
        )}
      </div>
    </div>
  );
}

export default NavFooter;
