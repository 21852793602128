import { createContext, useState, useCallback  } from "react";
import { useNavigate } from 'react-router-dom';
import baseURL from '../utils/baseUrl';
import axios from 'axios';

const PozosContext = createContext();

export const PozosProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pozos, setPozos] = useState([]);
  const [pozo, setPozo] = useState({});

  const navigate = useNavigate();

  // Get all pozos
  const getAllPozos = useCallback(
    async (asadaId) => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${baseURL}/api/v1/pozos?aid=${asadaId}`,
          {
            withCredentials: true,
          }
        );
        setPozos(response.data.pozos);
      } catch (error) {
        console.error('Error fetching pozos:', error);
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [baseURL]
  );

  // Get all pozos private
  const getAllPozosPrivate = useCallback(
    async (asadaId) => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${baseURL}/api/v1/pozos/private?aid=${asadaId}`,
          {
            withCredentials: true,
          }
        );
        setPozos(response.data.pozos);
      } catch (error) {
        console.error('Error fetching private pozos:', error);
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [baseURL]
  );

  // Get single pozo
  const getSinglePozo = useCallback(
    async (pozoId) => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/api/v1/pozos/${pozoId}`, {
          withCredentials: true,
        });
        setPozo(response.data.pozo);
      } catch (error) {
        console.log(error);
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [baseURL]
  );

  // Create a pozo
  const createPozo = useCallback(
    async (newPozo, asadaId) => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${baseURL}/api/v1/pozos?aid=${
            asadaId || process.env.REACT_APP_ASADA_ID
          }`,
          newPozo,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
          }
        );
        setPozos((prevPozos) => [...prevPozos, response.data.pozo]);

        if (asadaId) await getAllPozosPrivate(asadaId);
        else await getAllPozos(process.env.REACT_APP_ASADA_ID);
      } catch (error) {
        console.error('Error creating pozo:', error);
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [baseURL, getAllPozos, getAllPozosPrivate]
  );

  // Update pozo
  const updatePozoAdmin = useCallback(
    async (newPozo, pozoId, restore) => {
      setIsLoading(true);
      try {
        const response = await axios.patch(
          `${baseURL}/api/v1/pozos/${pozoId}${restore ? '?restore=true' : ''}`,
          newPozo,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
          }
        );
        setPozo(response.data.pozo);
        await getSinglePozo(pozoId);
      } catch (error) {
        console.error('Error updating pozo admin:', error);
        throw new Error(`Error al actualizar pozo: ${newPozo}`);
      } finally {
        setIsLoading(false);
      }
    },
    [baseURL, getSinglePozo]
  );

  // Update a pozo
  const updatePozo = async (pozo, pozoId) => {
    setIsLoading(true);
    try {
      const response = await axios.patch(
        `${baseURL}/api/v1/pozos/${pozoId}`,
        pozo,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
        }
      );
      setPozo(response.data.pozo);
    } catch (error) {
      console.error('Error updating pozo:', error);
      throw new Error(
        `Error al actualizar informacion del pozo: ${pozo.nombre}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Delete photo
  const deletePozoPhoto = useCallback(
    async (fotoId, pozoId) => {
      setIsLoading(true);
      try {
        await axios.delete(`${baseURL}/api/v1/fotos/${fotoId}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        await getSinglePozo(pozoId);
      } catch (error) {
        console.error('Error deleting pozo photo:', error);
        throw new Error(`Error al actualizar informacion del pozo.`);
      } finally {
        setIsLoading(false);
      }
    },
    [baseURL, getSinglePozo]
  );

  // Delete pozo
  const deletePozo = useCallback(
    async (pozoId, force) => {
      setIsLoading(true);
      try {
        await axios.delete(
          `${baseURL}/api/v1/pozos/${pozoId}${force ? '?force=true' : ''}`,
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        );
        await getAllPozos(process.env.REACT_APP_ASADA_ID);
        navigate(-1);
      } catch (error) {
        console.log(error);
        throw new Error(`Error al borrar pozo`);
      } finally {
        setIsLoading(false);
      }
    },
    [baseURL, getAllPozos, navigate]
  );

  return (
    <PozosContext.Provider
      value={{
        isLoading,
        getAllPozos,
        getAllPozosPrivate,
        getSinglePozo,
        createPozo,
        updatePozoAdmin,
        updatePozo,
        deletePozoPhoto,
        deletePozo,
        setIsLoading,
        setPozo,
        pozo,
        pozos,
      }}
    >
      {children}
    </PozosContext.Provider>
  );
};

export default PozosContext;