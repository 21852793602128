import axios from "axios";
import { createContext, useState, useContext } from "react";
import LoginContext from "../login/LoginContext";
import baseURL from '../../utils/baseUrl';
const SuggestionContext = createContext();

export const SuggestionProvider = ({ children }) => {
  const { user } = useContext(LoginContext);

  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [sugerencias, setSugerencias] = useState([]);
  const [sugerencia, setSugerencia] = useState(null);
  const [suggestion, setSuggestion] = useState({});

  // Fetch suggestions from DB
  const getSuggestions = async () => {
    setIsLoading(true);
    const response = await fetch('/api/suggestions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = await response.json();

    setSuggestions(data.suggestions);
    setIsLoading(false);
  };

  // Fetch sugerencias from DB
  const getAllSugerencias = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${baseURL}/api/v1/sugerencias?aid=${asadaId}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const data = await response.data;
    const sugerencias = data.sugerencias;
    setSugerencias(sugerencias);
    setIsLoading(false);
  };

  // Fetch sugerencias from DB
  const getAllSugerenciasPrivate = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${baseURL}/api/v1/sugerencias?aid=${asadaId}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const data = await response.data;
    const sugerencias = data.sugerencias;
    setSugerencias(sugerencias);
    setIsLoading(false);
  };

  // Get suggestion by ID
  const getSuggestion = async (id) => {
    setIsLoading(true);
    const response = await fetch(`/api/suggestions/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = await response.json();
    setSuggestion(data.suggestion[0]);
    setIsLoading(false);
  };

  // Get single sugerencia by ID
  const getSingleSugerencia = async (sugerenciaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${baseURL}/api/v1/sugerencias/${sugerenciaId}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const data = await response.data;
    const sugerencia = data.sugerencia;
    setSugerencia(sugerencia);
    setIsLoading(false);
  };

  // Add suggestion to DB
  const addSuggestion = async (newSuggestion) => {
    try {
      setIsLoading(true);
      const response = await fetch('/api/suggestions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newSuggestion),
      });

      const data = await response.json();

      setSuggestions([data, ...suggestions]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      throw new Error('Error al agregar sugerencia');
    }
  };

  // Create sugerencia
  const createSugerencia = async (newSugerencia) => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${baseURL}/api/v1/sugerencias?aid=${process.env.REACT_APP_ASADA_ID}`,
        newSugerencia,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = await response.data;
      const sugerencia = data.sugerencia;

      setSuggestions((prevSugerencias) => [...prevSugerencias, sugerencia]); // Best practice
    } catch (error) {
      console.log(error);
      throw new Error('Error al agregar sugerencia');
    } finally {
      setIsLoading(false);
    }
  };

  // Update suggestion to DB
  const updateSuggestion = async (suggestion) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `/api/suggestions/${suggestion.idsuggestions}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify(suggestion),
        }
      );

      const data = await response.json();

      setIsLoading(false);

      return data;
    } catch (error) {
      console.log(error);
      throw new Error(`Error al actualizar sugerencia: ${suggestion}`);
    }
  };

  // Update sugerencia to DB
  const updateSugerencia = async (sugerencia) => {
    const { id: sugerenciaId } = sugerencia;
    try {
      setIsLoading(true);
      const response = await axios.patch(
        `${baseURL}/api/v1/sugerencias/${sugerenciaId}`,
        sugerencia,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = await response.data;
      const sugerenciaUpdated = data.sugerencia;
      setSugerencia(sugerenciaUpdated);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(`Error al actualizar sugerencia: ${suggestion}`);
    }
  };

  // Send email (text only)
  const sendEmail = async (emailObj) => {
    const {
      token,
      empresa,
      correoDestino,
      formData: { nombre, apellidos, asunto, mensaje, correo },
    } = emailObj;

    const asuntoBody = `AsadasCR - Nuevo mensaje: ${asunto}`;
    const messageBody = `Ha recibido un nuevo mensaje por parte de ${nombre} ${apellidos} (${correo}). Mensaje: "${mensaje}." Para revisar el mensaje visite https://asadascr.com/admin`;

    const emailPayload = `<?xml version="1.0" encoding="utf-8"?>
      <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Body>
          <Correos_EnviarTexto xmlns="https://www.cisaweb.com/AcueductosRecibos">
            <token>${token}</token>
            <empresa>${empresa}</empresa>
            <Asunto>${asuntoBody}</Asunto>
            <Mensaje>${messageBody}</Mensaje>
            <correo_destino>${correoDestino}</correo_destino>
          </Correos_EnviarTexto>
        </soap:Body>
      </soap:Envelope>`;

    try {
      await axios.post(
        'https://www.cisaweb.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx',
        emailPayload,
        {
          headers: {
            'Content-Type': 'text/xml; charset=utf-8',
            SOAPAction:
              'https://www.cisaweb.com/AcueductosRecibos/Correos_EnviarTexto',
          },
        }
      );
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  // Send email with attachment
  const sendEmailWithAttachment = async (emailObj) => {
    const {
      token,
      empresa,
      correoDestino,
      formData: { nombre, apellidos, asunto, mensaje, correo },
    } = emailObj;

    const asuntoBody = `AsadasCR - ${asunto}`;
    const messageBody =
      `Ha recibido un correo de parte de ${nombre} ${apellidos}\n\n` +
      `Correo remitente: ${correo}\n\n` +
      `Mensaje:\n${mensaje}\n\n` +
      `Gracias,\nEquipo de AsadasCR`;

    const emailPayload = `<?xml version="1.0" encoding="utf-8"?>
<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
  <soap:Body>
    <Correos_EnviarDocumento xmlns="https://www.cisaweb.com/AcueductosRecibos">
      <token>${token}</token>
      <empresa>${empresa}}</empresa>
      <Asunto>${asuntoBody}</Asunto>
      <Mensaje>${messageBody}</Mensaje>
      <correo_destino>${correoDestino}</correo_destino>
      <pdf>base64Binary</pdf>
      <nombre_pdf>string</nombre_pdf>
    </Correos_EnviarDocumento>
  </soap:Body>
</soap:Envelope>`;

    try {
      await axios.post(
        'https://www.cisaweb.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx',
        emailPayload,
        {
          headers: {
            'Content-Type': 'text/xml; charset=utf-8',
            SOAPAction:
              'https://www.cisaweb.com/AcueductosRecibos/Correos_EnviarDocumento',
          },
        }
      );
    } catch (error) {
      console.error('Error sending email with attachment:', error);
    }
  };

  return (
    <SuggestionContext.Provider
      value={{
        suggestions,
        addSuggestion,
        isLoading,
        getSuggestions,
        getSuggestion,
        suggestion,
        setSuggestion,
        updateSuggestion,
        getAllSugerencias,
        sugerencias,
        sugerencia,
        createSugerencia,
        getSingleSugerencia,
        setSugerencia,
        updateSugerencia,
        getAllSugerenciasPrivate,
        sendEmail,
        sendEmailWithAttachment,
      }}
    >
      {children}
    </SuggestionContext.Provider>
  );
};

export default SuggestionContext;
