import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { NavLink } from "react-router-dom";
import defaultImg from "../../../assets/defaultImg.png";

// Framer motion variants
const pageVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};
const pageTransition = {
  duration: 0.5,
};

function HomePhotoGallery({ fotos }) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (fotos !== undefined) {
      if (fotos.length > 0) {
        const firstSixFotos = fotos.slice(0, 6);
        setImages(firstSixFotos);
      }
    }
  }, [fotos]);

  return (
    <section className="photo-gallery pb-12 ">
      <h1 className='text-center text-3xl sm:"text-4xl md:text-5xl lg:text-7xl pt-11 mb-12'>
        Galería
      </h1>
      <div className="divider my-12 px-12"></div>
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 px-3 gap-3">
        {images.length > 0 &&
          images.map((img, index) => (
            <div className="portfolio-box cursor-pointer" key={index}>
              <img
                src={`${process.env.REACT_APP_BASE_URL}/uploads/${
                  img.path.split('/')[4]
                }`}
                alt="Imagen de galería"
                className="object-cover h-full w-full"
              />
            </div>
          ))}
      </div>
      <div className="btn-container">
        <NavLink
          to="galeria"
          className="btn btn-primary hover:bg-secondary hover:scale-105 capitalize btn-lg my-12"
        >
          Ver más fotos
        </NavLink>
      </div>
    </section>
  );
}

export default HomePhotoGallery;
