import { useState, useEffect } from "react";
import { useWizard } from "react-use-wizard";
import FacturaItem from "./FacturaItem";

const FacturasPendientesList = ({ facturas, facturasSeleccionadas, quitarFactura, agregarFactura }) => {
  const [error, setError] = useState(null);
  const { nextStep } = useWizard();

  const handleAgregarFactura = (factura) => {
    agregarFactura(factura);
  };

  const handleQuitarFactura = (factura) => {
    quitarFactura(factura);
  };

  const isAnyFacturaSelected = () => {
    return facturasSeleccionadas.length > 0;
  };

  const checkSelectedFacturas = () => {
    for (const facturaSeleccionada of facturasSeleccionadas) {
      for (const facturaPendiente of facturas) {
        // El formato de la fecha que proporciona el WS está en el siguiente formato: "MM - AAAA"
        // Hay que dividir el string y luego castearlo a una fecha que JavaScript pueda interpretar
        const fechaSeleccionadaParts = facturaSeleccionada.fecha.split(" - ");
        const fechaPendienteParts = facturaPendiente.fecha.split(" - ");

        // Casteo del string para poder convertir a un formato de fecha válido
        const fechaSeleccionada = new Date(`${fechaSeleccionadaParts[1]}-${fechaSeleccionadaParts[0]}`);
        const fechaPendiente = new Date(`${fechaPendienteParts[1]}-${fechaPendienteParts[0]}`);

        if (fechaSeleccionada > fechaPendiente && !facturasSeleccionadas.includes(facturaPendiente)) {
          // Mostrar un error porque hay una factura seleccionada con una fecha mayor a una factura pendiente no seleccionada
          setError(
            <p>
              Error: Hay facturas seleccionadas con fechas posteriores a facturas pendientes no seleccionadas.
              <br />
              Por favor, asegúrese de seleccionar las facturas pendientes más antiguas antes de pagar las posteriores.
            </p>
          );
          return;
        }
      }
    }
    nextStep();
  };

  return (
    <div>
      {facturas.length === 0 ? (
        <p>No hay facturas pendientes</p>
      ) : (
        <>
          <h2 className="mb-4 font-bold">Facturas Pendientes</h2>
          <p className="mb-4 text-sm">Seleccione las facturas que desea cancelar:</p>
          <div className="flex flex-col md:flex-row md:flex-wrap gap-3 mb-4">
            {facturas &&
              facturas.map((factura, index) => (
                <FacturaItem
                  key={index}
                  factura={factura}
                  handleAgregarFactura={handleAgregarFactura}
                  handleQuitarFactura={handleQuitarFactura}
                />
              ))}
          </div>
          {error && <p className="text-red-500 mb-4 text-sm tracking-wide">{error}</p>}
          <div>
            <button
              onClick={checkSelectedFacturas}
              className="btn bg-transparent hover:bg-accent capitalize"
              disabled={!isAnyFacturaSelected()}
            >
              Siguiente
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default FacturasPendientesList;
