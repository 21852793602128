import { createContext, useState, useEffect } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import X2JS from "x2js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; //React-Toastify CSS

const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(user));
    }, [user]);

    const cisaLogin = async (userCredentials) => {
        setIsLoading(true);
        const { email: usuario, password: contraseña } = userCredentials;
        const token = sessionStorage.getItem("cisaToken");

        // Encrypt password
        const encrypted = encryptPassword(contraseña);

        try {
            // Send password to MOGEAS to get actual hash
            const response1 = await axios.post(
                process.env.REACT_APP_NODE_ENV === "production"
                    ? `${process.env.REACT_APP_BASE_URL}/api/v1/auth/hash`
                    : `http://localhost:5050/api/v1/auth/hash`,
                {
                    encrypted,
                }
            );
            const data1 = await response1.data;

            // Get actual hash
            const hashedPassword = data1.hashedContrasena;

            const xml = `<?xml version="1.0" encoding="utf-8"?>
  <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
      <UsuarioValidaAcceso xmlns="https://www.cisaweb.com/AcueductosRecibos">
        <token>${token}</token>
        <usuario>${usuario}</usuario>
        <contraseña>${hashedPassword}</contraseña>
      </UsuarioValidaAcceso>
    </soap:Body>
  </soap:Envelope>`;

            const url =
                "https://cisaweb.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx?op=UsuarioValidaAcceso";

            // POST to CISA WS
            const response = await fetch(url, {
                body: xml,
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "text/xml; charset=utf-8",
                },
            });

            const text = await response.text();

            const x2js = new X2JS();

            const data = x2js.xml2js(text);

            const resultsArr =
                data.Envelope.Body.UsuarioValidaAccesoResponse
                    .UsuarioValidaAccesoResult.anyType;

            const user = {
                empresa: Number(resultsArr[0].__text),
                nombreUsuario: String(resultsArr[1].__text),
                userId: Number(resultsArr[2].__text),
                role: String(resultsArr[3].__text),
                accesoPermitido: Boolean(resultsArr[4].__text),
            };

            if (Number(user.empresa) === 0) {
                setIsLoading(false);
                toast.error("Credenciales inválidas", {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            const loginResponse = await axios.post(
                process.env.REACT_APP_NODE_ENV === "production"
                    ? `${process.env.REACT_APP_BASE_URL}/api/v1/auth/login-cisa`
                    : "http://localhost:5050/api/v1/auth/login-cisa",
                {
                    user,
                },
                {
                    withCredentials: true,
                }
            );

            const { user: myUser } = await loginResponse.data;

            // Save the token in session storage
            setUser(myUser);

            document.cookie = `accessToken=${myUser.accessToken}; path=/; secure; SameSite=None`;
            document.cookie = `refreshToken=${myUser.refreshToken}; path=/; secure; SameSite=None`;

            sessionStorage.setItem("cisaToken", token);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const logout = async () => {
        try {
            localStorage.removeItem("user");
            document.cookie =
                "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            document.cookie =
                "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            setUser(null);
        } catch (error) {
            console.log(error);
        }
    };

    const encryptPassword = (strClave) => {
        return CryptoJS.AES.encrypt(
            strClave,
            process.env.REACT_APP_HASH_KEY
        ).toString();
    };

    return (
        <LoginContext.Provider
            value={{
                user,
                setUser,
                cisaLogin,
                isLoading,
                setIsLoading,
                logout,
            }}
        >
            {children}
        </LoginContext.Provider>
    );
};

export default LoginContext;
