function ListaRecaudadores() {
  return (
    <div className='flex items-center flex-col'>
      <h1 className='font-bold text-2xl md:text-3xl'>
        Recaudadores Autorizados
      </h1>
      <p className='my-3 text-center'>
        Cancele su factura con el número de NIS, de abonado o de medidor en
        cualquiera de los siguientes recaudadores:
      </p>
      <div className='grid grid-cols-2'>
        <div>
          <ul className='list-disc px-3 text-left gap-6'>
            <li>
              <a
                href='https://www.bncr.fi.cr/'
                target='_blank'
                className='hover:bg-accent rounded-full p-1 px-2'
                rel='noreferrer'
              >
                Banco Nacional
              </a>
            </li>
            <li>
              <a
                href='https://www.bancopopular.fi.cr/'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                Banco Popular
              </a>
            </li>
            <li>
              <a
                href='https://www.promerica.fi.cr/'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2 flex flex-nowrap'
                rel='noreferrer'
              >
                Banco Promerica
              </a>
            </li>
            <li>
              <a
                href='https://bnservicios.bncr.fi.cr/'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                BN Servicios
              </a>
            </li>
            <li>
              <a
                href='https://www.cajadeande.fi.cr/'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                Caja de ANDE
              </a>
            </li>
            <li>
              <a
                href='https://coocique.fi.cr/'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                Coocique
              </a>
            </li>
            <li>
              <a
                href='https://coopeamistadrl.com/servicios/pago-servicios-publicos/'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                CoopeAmistad
              </a>
            </li>
            <li>
              <a
                href='https://www.coopeande1.com/'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                CoopeAnde #1
              </a>
            </li>
            <li>
              <a
                href='https://www.coopejudicial.fi.cr/'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                CoopeJudicial
              </a>
            </li>
            <li>
              <a
                href='https://www.coopeservidores.fi.cr/es/'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                CoopeServidores
              </a>
            </li>
            <li>
              <a
                href='https://correos.go.cr/personas/'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2 flex flex-nowrap'
                rel='noreferrer'
              >
                Correos de Costa Rica
              </a>
            </li>
            <li>
              <a
                href='https://www.cruzroja.or.cr/'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                Cruz Roja
              </a>
            </li>
            <li>
              <a
                href='https://www.gente.sv/'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                Gente
              </a>
            </li>
            <li>
              <a
                href='https://www.gollotienda.com/golloservicios'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                Gollo
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ul className='list-disc px-3 text-left'>
            <li>
              <a
                href='https://www.grupomutual.fi.cr/'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2 flex flex-nowrap'
                rel='noreferrer'
              >
                Grupo Mutual Alajuela
              </a>
            </li>
            <li className='hover:bg-accent rounded-full py-1 px-2'>
              <a
                href='https://payweb.grupotecnologicocr.net/'
                target='_blank'
                rel='noreferrer'
              >
                Grupo Tecnológico Gamma
              </a>
            </li>
            <li>
              <a
                href='https://www.bncr.fi.cr/banca-en-linea'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                Internet Banking
              </a>
            </li>

            <li className='hover:bg-accent rounded-full py-1 px-2'>
              <a
                href='https://www.baccredomatic.com/es-cr/personas/pagos-automaticos'
                target='_blank'
                rel='noreferrer'
              >
                Pago Automático de Recibo
              </a>
            </li>
            <li>
              <a
                href='https://www.facebook.com/Pagobotscr/'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                PagoBots
              </a>
            </li>
            <li>
              <a
                href='https://www.facebook.com/PayserCR'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                Payser
              </a>
            </li>
            <li>
              <a
                href='http://www.qpago.com/'
                target='_blank'
                rel='noreferrer'
                className='hover:bg-accent rounded-full py-1 px-2'
              >
                Qpago
              </a>
            </li>
            <li>
              <a
                href='https://www.scotiabankcr.com/acerca/canales/pago-servicios-publicos.aspx'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                Scotiabank
              </a>
            </li>
            <li>
              <a
                href='https://www.coopeservidores.fi.cr/uploads/CMS/Reglamento/aed7b69e72b6e831f879457fe5e88dfeb173e187.pdf'
                target='_blank'
                rel='noreferrer'
                className='hover:bg-accent rounded-full py-1 px-2'
              >
                Servimás
              </a>
            </li>
            <li>
              <a
                href='https://teledolar.com/'
                target='_blank'
                rel='noreferrer'
                className='hover:bg-accent rounded-full py-1 px-2'
              >
                Teledora
              </a>
            </li>
            <li>
              <a
                href='https://www.tiendasekono.com/pague-servicios'
                target='_blank'
                className='hover:bg-accent rounded-full py-1 px-2'
                rel='noreferrer'
              >
                Tiendas Ekono
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ListaRecaudadores;
