import { SocialIcon } from "react-social-icons";
import { Link, NavLink } from "react-router-dom";

function Footer({ asada }) {
  const { direccion, nombre, contactos } = asada;

  let correoPrincipal = undefined;
  let telefonoPrincipal = undefined;
  let facebook = undefined;
  let whatsapp = undefined;

  if (contactos && contactos.length > 0) {
    correoPrincipal = contactos.filter((contacto) => contacto.tipo === "correo")[0]; // Get the very first item
    telefonoPrincipal = contactos.filter((contacto) => contacto.tipo === "fijo")[0]; // Get the very first item
    facebook = contactos.filter((contacto) => contacto.tipo === "facebook")[0]; // Get the very first item
    whatsapp = contactos.filter((contacto) => contacto.tipo === "whatsapp")[0]; // Get the very first item
  }

  // const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="flex flex-col items-center py-16 footer bg-accent-focus bg-gradient-to-b from-primary to-primary-focus">
        <div className="flex flex-col lg:flex-row container mx-auto px-2 lg:px-0 max-w-[1168px]">
          <div className="flex flex-col gap-3 items-center justify-center md:self-start col-span-3 xl:col-span-1 mb-6 xl:mb-0 lg:mr-10 h-[280px] p-4">
            <h1 className="text-3xl lg:text-6xl font-bold text-center md:text-left lg:mr-5">
              {nombre}
            </h1>
            <blockquote className="text-2xl italic text-left">
              "Cuidar del agua es cuidar de todos nosotros."
            </blockquote>
            {/*  <p>{currentYear}</p> */}
          </div>
          <div className="flex flex-col gap-2 justify-center place-items-center lg:place-items-start w-full">
            <p className="footer-title text-xl capitalize ">Navegación</p>
            <Link to="/" className="link link-hover text-sm ">
              Inicio
            </Link>
            <Link to="/nosotros" className="link link-hover text-sm ">
              Nosotros
            </Link>
            <Link to="/servicios" className="link link-hover text-sm ">
              Servicios
            </Link>
            <Link to="/proyectos" className="link link-hover text-sm ">
              Proyectos
            </Link>
            <Link to="/documentacion" className="link link-hover text-sm ">
              Documentación
            </Link>
            <Link to="/contacto" className="link link-hover text-sm ">
              Contacto
            </Link>
          </div>

          <div className="flex flex-col gap-2 justify-center place-items-center lg:place-items-start w-full mt-12 lg:mt-0">
            <p className="footer-title capitalize text-bold text-xl">
              De interés
            </p>
            <Link to="/faq" className="link link-hover text-sm ">
              Preguntas frecuentes
            </Link>
            <Link to="/publicaciones" className="link link-hover text-sm ">
              Publicaciones
            </Link>
            <Link to="/galeria" className="link link-hover text-sm ">
              Galería
            </Link>
            <Link
              to="/contacto"
              className="link link-hover text-sm  text-center"
            >
              Buzón de sugerencias
            </Link>{' '}
            <Link
              to="/servicios"
              className="link link-hover text-sm text-center"
            >
              Consulta de recibos
            </Link>
            <Link
              to="/contacto"
              className="link link-hover text-sm text-center"
            >
              Trabaja con nosotros
            </Link>
          </div>

          <div className="flex flex-col gap-1 justify-center place-items-center lg:place-items-start w-full mt-12 lg:mt-0">
            <p className="footer-title capitalize text-xl">
              Oficinas Centrales
            </p>
            <div className="flex place-items-center">
              <address className="text-sm max-w-xs text-lef">
                {direccion && direccion}
              </address>
              {/* <span className="flex py-1 cursor-pointer mr-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="#FFFFFF"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </span> */}
            </div>
            <div className="flex place-items-center">
              <p className="text-sm">
                +506{' '}
                {telefonoPrincipal !== undefined
                  ? telefonoPrincipal.info
                  : 'sin teléfono'}
              </p>
              {/* <span className="flex py-1 cursor-pointer mr-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 "
                  viewBox="0 0 20 20"
                  fill="#FFFFFF"
                  stroke="#000000"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>
              </span> */}
            </div>
            <div className="flex place-items-center">
              <p className="text-sm ">
                {correoPrincipal !== undefined ? (
                  <a
                    href={`mailto:${correoPrincipal.info}`}
                    className="hover:underline hover:text-accent"
                  >
                    {correoPrincipal.info}
                  </a>
                ) : (
                  'sin correo'
                )}
              </p>
              {/* <span className="flex py-1 cursor-pointer text-base-100 items-center mr-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 "
                  viewBox="0 0 20 20"
                  fill="#FFFFFF"
                  stroke="#000000"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
              </span> */}
            </div>
            <div className="mt-12 lg:mt-6">
              <p className=" font-bold text-xl">Encuéntranos en: </p>
              <div className="flex gap-2 mt-2 justify-center">
                <SocialIcon
                  url={
                    facebook !== undefined
                      ? facebook.info
                      : 'https://www.facebook.com/'
                  }
                  style={{ width: '42px', height: '42px' }}
                  className="transition hover:scale-125  duration-300  cursor-pointer "
                  target="_blank"
                  fgColor="#fff"
                />

                <SocialIcon
                  url={`mailto:${
                    correoPrincipal !== undefined
                      ? correoPrincipal.info
                      : 'sincorreo@sincorreo.com'
                  }`}
                  style={{ width: '42px', height: '42px' }}
                  className="transition hover:scale-125 duration-300  cursor-pointer"
                  fgColor="#fff"
                />
                {/* WHATSAPP */}
                <a
                  href={`https://wa.me/506${
                    whatsapp !== undefined ? whatsapp.info : ''
                  } `}
                  target="_blank"
                  style={{ width: '42px', height: '42px' }}
                >
                  <img
                    src="https://asadascr.com/icons/whatsapp.svg"
                    alt="WhatsApp icon"
                    className="transition hover:scale-125 duration-300 cursor-pointer"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex border-t-[1px] lg:min-w-[1168px] px-2 lg:px-0 py-3 tracking-wide">
          <NavLink to="/terminos-y-condiciones" className="hover:underline">
            Términos y condiciones
          </NavLink>
          |
          <NavLink to="/privacidad" className="hover:underline">
            Privacidad de datos
          </NavLink>
        </div>
      </footer>
    </>
  );
}

export default Footer;
