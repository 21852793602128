import { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LoginContext from "../login/LoginContext";
import { toast } from "react-toastify";
import axios from "axios";

const QuiebraGradientesContext = createContext();

export const QuiebraGradientesProvider = ({ children }) => {
  const { user } = useContext(LoginContext);

  const [isLoading, setIsLoading] = useState(true);
  const [gradientes, setGradientes] = useState([]);
  const [gradiente, setGradiente] = useState({});

  const navigate = useNavigate();

  // Get all gradientes
  const getAllGradientes = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes?aid=${asadaId}`
          : `http://localhost:5050/api/v1/gradientes?aid=${asadaId}`,
        { withCredentials: true }
      );
      const data = await response.data;
      const gradientes = data.gradientes;
      setGradientes(gradientes);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Get all gradientes private
  const getAllGradientesPrivate = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes/private?aid=${asadaId}`
          : `http://localhost:5050/api/v1/gradientes/private?aid=${asadaId}`,
        { withCredentials: true }
      );
      const data = await response.data;
      const gradientes = data.gradientes;
      setGradientes(gradientes);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Get single gradiente
  const getSingleGradiente = async (gradienteId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes/${gradienteId}`
          : `http://localhost:5050/api/v1/gradientes/${gradienteId}`,
        { withCredentials: true }
      );
      const data = await response.data;
      const gradiente = data.gradiente;
      setGradiente(gradiente);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Create a gradiente
  const createGradiente = async (newGradiente, asadaId) => {
    setIsLoading(true);
    try {
      let response;
      if (asadaId) {
        response = await axios.post(
          process.env.REACT_APP_NODE_ENV === "production"
            ? `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes?aid=${asadaId}`
            : `http://localhost:5050/api/v1/gradientes?aid=${asadaId}`,
          newGradiente,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      } else {
        response = await axios.post(
          process.env.REACT_APP_NODE_ENV === "production"
            ? `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes?aid=${process.env.REACT_APP_ASADA_ID}`
            : `http://localhost:5050/api/v1/gradientes?aid=${process.env.REACT_APP_ASADA_ID}`,
          newGradiente,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      }

      const data = await response.data;
      const gradiente = data.gradiente;

      setGradientes(...gradientes, gradiente);

      toast.success(`Nuevo quiebragradiente agregado.`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (asadaId) await getAllGradientesPrivate(asadaId);
      else await getAllGradientes(process.env.REACT_APP_ASADA_ID);
    } catch (error) {
      console.log(error);
      toast.error(`Ha ocurrido un error al agregar el quiebragradiente. Error: ${error}`, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Update gradiente admin
  const updateGradienteAdmin = async (newGradiente, gradienteId, restore) => {
    setIsLoading(true);
    try {
      const response = await axios.patch(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes/${gradienteId}${restore ? "?restore=true" : ""}`
          : `http://localhost:5050/api/v1/gradientes/${gradienteId}${restore ? "?restore=true" : ""}`,
        newGradiente,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      const data = await response.data;
      const gradiente = data.gradiente;

      await getSingleGradiente(gradienteId);
      toast.info(`Quiebragradientes actualizado`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(`Ha ocurrido un error al editar el quiebragradientes. Error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(`Error al actualizar quiebragradientes: ${newGradiente}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Update gradiente
  const updateGradiente = async (gradiente, gradienteId) => {
    setIsLoading(true);
    try {
      const response = await axios.patch(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes/${gradienteId}`
          : `http://localhost:5050/api/v1/gradientes/${gradienteId}`,
        gradiente,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      const data = await response.data;
      const gradient = data.gradiente;
      setIsLoading(false);
      toast.info(`Se ha actualizado la información del quiebragradientes`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      throw new Error(`Error al actualizar informacion de quiebragradientes: ${gradiente.name}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete photo
  const deleteGradientePhoto = async (fotoId, gradienteId) => {
    setIsLoading(true);
    try {
      await axios.delete(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/fotos/${fotoId}`
          : `http://localhost:5050/api/v1/fotos/${fotoId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      await getSingleGradiente(gradienteId);
      toast.info(`Foto borrada del quiebragradientes`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      throw new Error(`Error al actualizar informacion del quiebragradientes: ${gradiente.name}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete quiebragradientes
  const deleteGradiente = async (gradienteId, force) => {
    setIsLoading(true);
    try {
      await axios.delete(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/gradientes/${gradienteId}${force ? "?force=true" : ""}`
          : `http://localhost:5050/api/v1/gradientes/${gradienteId}${force ? "?force=true" : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      await getAllGradientes(process.env.REACT_APP_ASADA_ID);
      toast.info(`Se ha borrado el registro del quiebragradientes y las fotos asociadas`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      throw new Error(`Error al borrar quiebragradientes: ${gradiente.name}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <QuiebraGradientesContext.Provider
      value={{
        isLoading,
        setIsLoading,
        gradiente,
        gradientes,
        updateGradiente,
        setGradiente,
        deleteGradientePhoto,
        deleteGradiente,
        getAllGradientes,
        createGradiente,
        getSingleGradiente,
        getAllGradientesPrivate,
        updateGradienteAdmin,
      }}
    >
      {children}
    </QuiebraGradientesContext.Provider>
  );
};

export default QuiebraGradientesContext;
