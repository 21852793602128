import { createContext, useState, useCallback } from 'react';
import baseURL from '../utils/baseUrl';
import axios from 'axios';

const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [config, setConfig] = useState({});

  // Get all config from asada
  const getConfigByAsadaId = useCallback(
    async (asadaId) => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${baseURL}/api/v1/asadas/${asadaId}/config`,
          {
            withCredentials: true,
          }
        );
        setConfig(response.data.configs);
      } catch (error) {
        console.error('Error fetching asada config:', error);
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [baseURL]
  );

  // Update a pozo
  const updateConfig = async (newConfig, asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${baseURL}/api/v1/asadas/${asadaId}/unidad-medida`,
        newConfig,
        {
          withCredentials: true,
        }
      );
      setConfig(response.data.configs);
    } catch (error) {
      console.error('Error updating config:', error);
      throw new Error(
        `Error al actualizar la configuracion de la asada: ${error}}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ConfigContext.Provider
      value={{
        isLoading,
        setIsLoading,
        config,
        getConfigByAsadaId,
        setConfig,
        updateConfig,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContext;
