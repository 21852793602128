import { useContext, useEffect, useState } from 'react';
import PozosContext from '../../context/pozosContext';
import ConfigContext from '../../context/configContext';
import PozosPhotosClient from './PozosPhotosClient';
import Spinner from '../shared/Spinner';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { motion, AnimatePresence } from 'framer-motion';
import NavFooter from '../shared/NavFooter';

// Create our number formatter.
const formatter = new Intl.NumberFormat('es-CR', {
  style: 'currency',
  currency: 'CRC',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        transform: 'scale(1.2)',
        right: '25px',
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        transform: 'scale(1.2)',
        left: '25px',
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
}

// Framer motion variants
const pageVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};
const pageTransition = {
  duration: 0.5,
};

function FadeInWhenVisible({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={{
        visible: {
          opacity: 1,
          transition: {
            delay: 0.6,
          },
        },
        hidden: { opacity: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}

function PozosClient() {
  const { isLoading, pozos, getAllPozos } = useContext(PozosContext);
  const { config, getConfigByAsadaId } = useContext(ConfigContext);

  const [unidadMedida, setUnidadMedida] = useState('');

  // Options for the carouse Slider
  const settings = {
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    const fetchPozos = async () => {
      try {
        await getAllPozos(process.env.REACT_APP_ASADA_ID);
      } catch (error) {
        console.error('Error fetching tanks:', error);
      }
    };

    const fetchConfig = async () => {
      try {
        const asadaId = process.env.REACT_APP_ASADA_ID;

        if (!asadaId) {
          console.warn('No se encontró "aid" ni variable de entorno');
          return;
        }
        await getConfigByAsadaId(asadaId);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };

    fetchConfig();
    fetchPozos();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (config && config.unidad_medida) {
      setUnidadMedida(config.unidad_medida);
    }
  }, [config]);

  return (
    <motion.div
      exit="out"
      animate="in"
      initial="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <section>
        <div className="text-center pb-16 pt-6 text-3xl lg:text-7xl">
          <h1 className="border-b pb-5">Pozos de agua</h1>
        </div>
        <div className="posts-section w-full">
          <div className="bg py-12 flex justify-center">
            <div className="container mx-auto flex justify-center">
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-12 ">
                {/* Card */}

                {!isLoading && pozos.length !== 0 ? (
                  pozos.map((pozo, i) => {
                    return (
                      <AnimatePresence key={pozo.id}>
                        <motion.div
                          key={pozo.id}
                          idx={i}
                          className="card md:w-[380px] mx-6 bg-base-100 shadow-xl overflow-hidden overflow-x-hidden"
                          initial="hidden"
                          whileInView="visible"
                          exit="hidden"
                          viewport={{ once: true }}
                          transition={{ duration: 5 }}
                          variants={{
                            visible: {
                              opacity: 1,
                              transition: {
                                delay: 0.3,
                              },
                            },
                            hidden: {
                              opacity: 0,
                              transition: { duration: 0.3 },
                            },
                          }}
                        >
                          <div className="photos-container relative">
                            <div className={`w-full h-full`}>
                              <Slider {...settings}>
                                {!isLoading ? (
                                  pozo.fotos.map((photo, i) => {
                                    return (
                                      <PozosPhotosClient
                                        photo={photo}
                                        key={i}
                                        idphoto={photo.id}
                                      />
                                    );
                                  })
                                ) : (
                                  <Spinner />
                                )}
                              </Slider>
                            </div>
                          </div>
                          <div className="card-body pb-4 pt-2 px-4 overflow-y-auto">
                            <FadeInWhenVisible>
                              <motion.h2
                                className="font-medium text-3xl "
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                transition={{ duration: 0.8 }}
                                variants={{
                                  visible: {
                                    opacity: 1,
                                    y: '0',
                                    transition: {
                                      delay: 0.8,
                                      duration: 0.5,
                                    },
                                  },
                                  hidden: { opacity: 0, y: '50px' },
                                }}
                              >
                                {pozo.nombre}
                              </motion.h2>

                              <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                variants={{
                                  visible: {
                                    opacity: 1,
                                    transition: {
                                      delay: 1.3,
                                    },
                                  },
                                  hidden: { opacity: 0 },
                                }}
                              >
                                <small>
                                  &nbsp;
                                  <span className="font-bold">
                                    Capacidad:
                                  </span>{' '}
                                  {pozo.capacidad} {unidadMedida}
                                </small>
                              </motion.div>
                              <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                transition={{ duration: 0.8 }}
                                variants={{
                                  visible: {
                                    opacity: 1,
                                    transition: {
                                      delay: 1.3,
                                    },
                                  },
                                  hidden: { opacity: 0 },
                                }}
                              >
                                <small>
                                  &nbsp;
                                  <span className="font-bold">
                                    Ubicación:
                                  </span>{' '}
                                  {pozo.ubicacion}
                                </small>
                              </motion.div>
                              <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                transition={{ duration: 0.8 }}
                                variants={{
                                  visible: {
                                    opacity: 1,
                                    transition: {
                                      delay: 1.3,
                                    },
                                  },
                                  hidden: { opacity: 0 },
                                }}
                              >
                                <small>
                                  &nbsp;
                                  <span className="font-bold">Costo: </span>
                                  {formatter.format(pozo.costo)}
                                </small>
                              </motion.div>
                              <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                transition={{ duration: 0.8 }}
                                variants={{
                                  visible: {
                                    opacity: 1,
                                    transition: {
                                      delay: 1.3,
                                    },
                                  },
                                  hidden: { opacity: 0 },
                                }}
                              >
                                <small>
                                  &nbsp;
                                  <span className="font-bold">
                                    Proveedor:
                                  </span>{' '}
                                  {pozo.proveedor}
                                </small>
                              </motion.div>
                            </FadeInWhenVisible>
                          </div>
                        </motion.div>
                      </AnimatePresence>
                    );
                  })
                ) : (
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    exit="hidden"
                    viewport={{ once: true }}
                    transition={{ duration: 5 }}
                    variants={{
                      visible: {
                        opacity: 1,
                        transition: {
                          delay: 0.3,
                        },
                      },
                      hidden: {
                        opacity: 0,
                        transition: { duration: 0.3 },
                      },
                    }}
                  >
                    <h1 className="text-4xl max-w-3xl text-center text-white leading-loose mx-4">
                      No hay pozos de agua
                    </h1>
                  </motion.div>
                )}
              </div>
            </div>
          </div>
        </div>
        <NavFooter />
      </section>
    </motion.div>
  );
}

export default PozosClient;
