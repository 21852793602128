import { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import bancoProcesadorContext from "../../context/bancoProcesadorContext";

function RedireccionarPagoTarjeta({ processUrl, asada }) {
  const [isLoading, setIsLoading] = useState(true);
  const { bancoProcesadorId } = asada;
  const { banco, getSingleBancoProcesador } = useContext(bancoProcesadorContext);

  useEffect(() => {
    const fetchData = async () => {
      await getSingleBancoProcesador(bancoProcesadorId);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (processUrl != null) {
      setTimeout(() => {
        window.open(processUrl, "_blank");
        setIsLoading(false);
      }, 3000);
    }
  }, [processUrl, setIsLoading]);

  return (
    <div className="container">
      <h2 className="mb-4 font-bold">Pago con tarjeta de crédito/débito</h2>
      <p className="mb-4 text-sm">
        Su transacción está en proceso con{" "}
        <span className="font-bold">
          {banco.nombre} ({banco.acronimo})
        </span>
        .
      </p>
      <p className="mb-4 text-sm">En unos instantes será redirigido a un enlace de pago seguro.</p>
      <div className="flex gap-3">
        <NavLink className="btn bg-transparent hover:bg-accent capitalize" disabled={isLoading} to={`/servicios`}>
          De&nbsp;<span className="lowercase">acuerdo</span>
        </NavLink>
      </div>
      <figure className="w-28 h-10 mt-4">
        <img src="https://static.placetopay.com/placetopay-logo.svg" alt="Evertec logo"></img>
      </figure>
    </div>
  );
}

export default RedireccionarPagoTarjeta;
