import { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import FormContext from "../context/forms/FormContext";
import TransparenciaContext from "../context/transparencia/TransparenciaContext";
import ReglamentosContext from "../context/reglamentos/ReglamentosContext";
import InformesContext from "../context/informes/InformesContext";
import AsadaContext from "../context/asada/asadaContext";
import { Header, Transparencia, Formularios, Informes, Reglamentos } from "./sections/Documentation";

import { motion } from "framer-motion";

// Framer motion variants
const pageVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  duration: 0.5,
};

function Documentation() {
  const { formularios, getAllFormularios } = useContext(FormContext);
  const { transparencias, getAllTransparencias } = useContext(TransparenciaContext);
  const { informes, getAllInformes } = useContext(InformesContext);
  const { reglamentos, getAllReglamentos } = useContext(ReglamentosContext);
  const {
    asada: { fotoEstaticas },
    getSingleAsada,
  } = useContext(AsadaContext);

  const { hash } = useLocation();
  const transparenciaRef = useRef();
  const formulariosRef = useRef();
  const informesRef = useRef();
  const reglamentosRef = useRef();

  useEffect(() => {
    const fetchTransparenciaDocs = async () => {
      if (Object.keys(transparencias).length === 0) await getAllTransparencias(process.env.REACT_APP_ASADA_ID);
    };
    const fetchForms = async () => {
      if (Object.keys(formularios).length === 0) await getAllFormularios(process.env.REACT_APP_ASADA_ID);
    };
    const fetchInformes = async () => {
      if (Object.keys(informes).length === 0) await getAllInformes(process.env.REACT_APP_ASADA_ID);
    };
    const fetchReglamentos = async () => {
      if (Object.keys(reglamentos).length === 0) await getAllReglamentos(process.env.REACT_APP_ASADA_ID);
    };
    const fetchSingleAsada = async (asadaId) => await getSingleAsada(asadaId);

    fetchSingleAsada(process.env.REACT_APP_ASADA_ID);
    fetchTransparenciaDocs();
    fetchForms();
    fetchReglamentos();
    fetchInformes();
    setTimeout(() => {
      checkHashInURL();
    }, 800);
  }, []);

  function checkHashInURL() {
    switch (hash) {
      case "#transparencia":
        transparenciaRef.current.scrollIntoView();
        break;
      case "#formularios":
        formulariosRef.current.scrollIntoView();
        break;
      case "#informes":
        informesRef.current.scrollIntoView();
        break;
      case "#reglamentos":
        reglamentosRef.current.scrollIntoView();
        break;
      default:
        window.scrollTo(0, 0);
        break;
    }
  }

  return (
    <motion.div exit="out" animate="in" initial="out" variants={pageVariants} transition={pageTransition}>
      <section>
        <Header fotoEstaticas={fotoEstaticas} />
        <div className="container mx-auto">
          {/* TRANSPARENCIA */}
          <div ref={transparenciaRef}>
            <Transparencia documentos={transparencias} />
          </div>
          {/* FORMS */}
          <div ref={formulariosRef}>
            <Formularios forms={formularios} />
          </div>
          {/* INFORMES */}
          <div ref={informesRef}>
            <Informes informes={informes} />
          </div>
          {/* REGLAMENTOS */}
          <div ref={reglamentosRef}>
            <Reglamentos reglamentos={reglamentos} />
          </div>
        </div>
      </section>
    </motion.div>
  );
}

export default Documentation;
