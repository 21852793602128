import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import JobContext from "../../context/jobs/JobContext";
import { motion } from "framer-motion";
import { crearTokenCISA } from '../../utils/cisaWS';
import AsadaContext from '../../context/asada/asadaContext';
import { toast } from 'react-toastify';

function setInputFilter(textbox, inputFilter) {
  [
    'input',
    'keydown',
    'keyup',
    'mousedown',
    'mouseup',
    'select',
    'contextmenu',
    'drop',
  ].forEach(function (event) {
    textbox.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty('oldValue')) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = '';
      }
    });
  });
}

// Framer motion variants
const pageVariants = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: '-100vw',
  },
};
const pageTransition = {
  duration: 0.5,
};

function Jobs() {
  const navigate = useNavigate();
  const { asada, contactos } = useContext(AsadaContext);
  const { createSolicitud } = useContext(JobContext);

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [cv, setCV] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setInputFilter(
      document.getElementById('grid-phoneNumber'),
      function (value) {
        return /^\d*$/.test(value);
      }
    );
  }, []);

  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const onLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const onPhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };
  const onCVChange = (e) => {
    setCV(e.target.files[0]);
  };

  const resetForm = () => {
    setName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setCV('');
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const fd = new FormData();
    fd.set('nombre', name);
    fd.set('apellidos', lastName);
    fd.set('correo', email);
    fd.set('telefono', phoneNumber);
    fd.set('curriculum', cv, cv.name);

    try {
      createSolicitud(fd);
    } catch (error) {
      console.error(error);
      toast.error(
        'Ha ocurrido un error al crear la solicitud. Por favor contacte al administrador del sitio.'
      );
    } finally {
      setIsLoading(false);
    }

    // Create CISA token
    const token = await crearTokenCISA();

    // Get email from contacts
    const correoDestino = contactos.filter((item) => item.tipo === 'correo')[0]
      ?.info;
    const empresa = asada?.num_empresa_cisa;
    const asunto = `AsadasCR - Archivo adjunto: ${name} ${lastName}`;
    const messageBody = `Ha recibido una nueva solicitud de empleo por parte de ${name} ${lastName} (${email}). El currículum se encuentra adjunto a este correo. Para ver más información visite https://asadascr.com/admin`;

    // Convert PDF to base64
    const convertPDFToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
      });
    };

    try {
      const pdfBase64 = await convertPDFToBase64(cv);
      const nombrePdf = cv.name;

      // Construct SOAP request
      const soapRequest = `<?xml version="1.0" encoding="utf-8"?>
      <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Body>
          <Correos_EnviarDocumento xmlns="https://www.cisaweb.com/AcueductosRecibos">
            <token>${token}</token>
            <empresa>${empresa}</empresa>
            <Asunto>${asunto}</Asunto>
            <Mensaje>${messageBody}</Mensaje>
            <correo_destino>${correoDestino}</correo_destino>
            <pdf>${pdfBase64}</pdf>
            <nombre_pdf>${nombrePdf}</nombre_pdf>
          </Correos_EnviarDocumento>
        </soap:Body>
      </soap:Envelope>`;

      const response = await fetch(
        'https://www.cisaweb.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'text/xml; charset=utf-8',
            SOAPAction:
              'https://www.cisaweb.com/AcueductosRecibos/Correos_EnviarDocumento',
          },
          body: soapRequest,
        }
      );

      if (response.ok) {
        const responseText = await response.text();
        console.log('SOAP response:', responseText);
        toast.success('El currículum ha sido enviado exitosamente', {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
        });
        resetForm();
      } else {
        console.error('Error in SOAP request:', response.statusText);
      }
    } catch (error) {
      console.error('Error converting PDF to base64:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      exit="out"
      animate="in"
      initial="out"
      variants={pageVariants}
      transition={pageTransition}
      className="container mx-auto"
    >
      <section>
        <div className="px-2">
          <div className="title text-center py-6 text-3xl lg:text-7xl">
            <h1 className="border-b pb-5">Trabaja con Nosotros</h1>
          </div>

          <div className="grid place-items-center my-12">
            <form
              className="w-full max-w-lg"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block tracking-wide text-gray-200 text-xs font-bold mb-2"
                    htmlFor="grid-nombre"
                  >
                    Nombre
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-nombre"
                    type="text"
                    placeholder="Ingresar nombre aquí"
                    required
                    onChange={onNameChange}
                    value={name}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block tracking-wide text-gray-200 text-xs font-bold mb-2"
                    htmlFor="grid-apellidos"
                  >
                    Apellidos
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last_name"
                    type="text"
                    placeholder="Ingresar apellidos aquí"
                    required
                    name="last_name"
                    onChange={onLastNameChange}
                    value={lastName}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <label
                    className="block tracking-wide text-gray-200 text-xs font-bold mb-2"
                    htmlFor="grid-email"
                  >
                    Correo electrónico
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-email"
                    type="email"
                    placeholder="correo@ejemplo.com"
                    required
                    name="email"
                    onChange={onEmailChange}
                    value={email}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <label
                    className="block tracking-wide text-gray-200 text-xs font-bold mb-2"
                    htmlFor="grid-phoneNumber"
                  >
                    Número de teléfono
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-phoneNumber"
                    placeholder="Ingresar número aquí"
                    required
                    name="phoneNumber"
                    onChange={onPhoneNumberChange}
                    value={phoneNumber}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <label
                    className="block tracking-wide text-gray-200 text-xs font-bold mb-2"
                    htmlFor="grid-cv"
                  >
                    Currículum
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-cv"
                    type="file"
                    placeholder="Curriculum"
                    required
                    name="cv"
                    onChange={onCVChange}
                    accept=".pdf"
                  />
                </div>
              </div>

              <div className="flex flex-wrap gap-1 mb-4">
                <button
                  href={`mailto:acueductolalucha@gmail.com`}
                  type="submit"
                  disabled={isLoading}
                  className={`w-full btn btn-primary hover:scale-105 md:btn-md capitalize ${
                    isLoading ? 'loading loading-spinner loading-sm' : ''
                  }`}
                >
                  {' '}
                  {!isLoading && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                  )}
                  &nbsp;Enviar currículum
                </button>
                <button
                  type="button"
                  className="  
                        w-full
                        btn
                        btn-slate-300
                        btn-outline
                        hover:bg-slate-300
                        hover:scale-105
                        btn-md capitalize"
                  onClick={() => navigate(-1)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                      clipRule="evenodd"
                    />
                  </svg>{' '}
                  &nbsp; Volver a Contacto
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </motion.div>
  );
}

export default Jobs;
