import { NavLink } from "react-router-dom";
import ocultarCorreo from "../../utils/ocultarCorreo";

function PagoConfirmado({ codRespuesta, abonado }) {
    const { correo } = abonado;
    const correoOculto = ocultarCorreo(correo);

    return (
        <div className='container'>
            <h2 className="mb-4 font-bold">Aviso de pago finalizado</h2>
            {Number(codRespuesta) === 0 ? (
                <p className='mb-4 text-sm'>Estimado usuario, su transacción se está procesando en este momento.<br />
                    Recibirá un correo electrónico con la confirmación de pago cuando finalice el proceso.<br />
                    La información será remitida al siguiente buzón: <span className="font-bold">{correoOculto}</span>.</p>
            ) : (
                <p className='mb-4 text-sm'>Ha ocurrido un problema al realizar el aviso de pago.
                    <br />Por favor contacte al administrador del sitio.</p>
            )}

            <div className='flex gap-3'>
                <NavLink
                    to={`/servicios`}
                    className='btn bg-transparent hover:bg-accent capitalize'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" height="24" viewBox="0 -960 960 960" width="24"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/></svg>
                    <span>Volver <span className="lowercase">a</span> Servicios</span>
                </NavLink>
            </div>
        </div>
    )
}

export default PagoConfirmado;