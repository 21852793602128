import React, { useEffect, useState, useContext } from "react";
import AsadaContext from "../../context/asada/asadaContext";
import { motion } from "framer-motion";
import { terminosYCondiciones } from "../../utils/datosInfo";

// Framer motion variants
const pageVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};
const pageTransition = {
  duration: 0.5,
};

function TerminosYCondiciones() {
  const [terminosYCondicionesModificado, setTerminosYCondicionesModificado] = useState(null);
  const { asada } = useContext(AsadaContext);

  useEffect(() => {
    let terminosYCondicionesModificado = terminosYCondiciones;
    const { nombre, cedulaJuridica } = asada;

    // Actualiza los valores de ASADA_COMERCIO, CEDULA_JURIDICA y HOSTNAME con los valores obtenidos del Context
    if (nombre && terminosYCondicionesModificado.includes("ASADA_COMERCIO")) {
      terminosYCondicionesModificado = terminosYCondicionesModificado.replace(/ASADA_COMERCIO/g, nombre);
    }
    if (cedulaJuridica && terminosYCondicionesModificado.includes("CEDULA_JURIDICA")) {
      terminosYCondicionesModificado = terminosYCondicionesModificado.replace(/CEDULA_JURIDICA/g, cedulaJuridica);
    }
    if (terminosYCondicionesModificado.includes("HOSTNAME")) {
      terminosYCondicionesModificado = terminosYCondicionesModificado.replace(/HOSTNAME/g, window.location.hostname);
    }

    setTerminosYCondicionesModificado(terminosYCondicionesModificado);
  }, [asada, terminosYCondiciones]);

  return (
    <>
      <motion.div exit="out" animate="in" initial="out" variants={pageVariants} transition={pageTransition}>
        <div className="container mx-auto px-4">
          <div className="text-center py-6 text-3xl lg:text-4xl">
            <div className="text-center">
              <h1>Contrato de Términos y Condiciones</h1>
            </div>
          </div>
          <div className="grid place-items-start mb-8 mt-1 mockup-window bg-base-300 border-0">
            <pre className="whitespace-pre-wrap text-justify bg-base-200 px-6 py-4">
              <span className="font-sans">{terminosYCondicionesModificado}</span>
            </pre>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default TerminosYCondiciones;
