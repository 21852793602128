import React, { useEffect, useState, useContext } from "react";
import AsadaContext from "../../context/asada/asadaContext";
import { motion, AnimatePresence } from "framer-motion";
import { preguntasFrecuentes } from "../../utils/datosInfo";

// Framer motion variants
const pageVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};
const pageTransition = {
  duration: 0.5,
};

const Accordion = ({ listaPreguntas }) => {
  const [isOpen, setIsOpen] = useState({});

  const toggleAccordion = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <>
      {listaPreguntas.map((item, index) => (
        <div className="py-2 w-full" key={index}>
          <button onClick={() => toggleAccordion(index)} className="flex justify-between w-full mb-2">
            <span className="font-medium text-xl text-left">{item.question}</span>
            <svg className="fill-accent shrink-0 ml-8" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
              <rect
                y="7"
                width="16"
                height="2"
                rx="1"
                className={`transform origin-center transition duration-200 ease-out ${isOpen[index] && "!rotate-180"}`}
              />
              <rect
                y="7"
                width="16"
                height="2"
                rx="1"
                className={`transform origin-center rotate-90 transition duration-200 ease-out ${
                  isOpen[index] && "!rotate-180"
                }`}
              />
            </svg>
          </button>
          <div
            className={`grid overflow-hidden transition-all duration-300 ease-in-out text-sm ${
              isOpen[index] ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
            }`}
          >
            <div className="overflow-hidden bg-base-200 p-3 rounded-xl">{item.answer}</div>
          </div>
        </div>
      ))}
    </>
  );
};

function PreguntasFrecuentes() {
  const [preguntas, setPreguntas] = useState([]);
  const { asada } = useContext(AsadaContext);

  useEffect(() => {
    const { nombre, metodoPagos } = asada;

    // Actualiza los valores de ASADA_COMERCIO y ASADA_METODOS_PAGO con los valores obtenidos del Context
    const preguntasActualizadas = preguntasFrecuentes.map((pregunta) => {
      if (nombre) {
        if (pregunta.answer.includes("ASADA_COMERCIO")) {
          pregunta.answer = pregunta.answer.replace("ASADA_COMERCIO", nombre);
        }
      }
      if (metodoPagos) {
        if (pregunta.answer.includes("ASADA_METODOS_PAGO")) {
          let metodosPagoLista = metodoPagos
            .map((metodo) => (metodo.descripcion === "TC/TD" ? "tarjeta de crédito o débito" : metodo.descripcion))
            .join(", o ");
          pregunta.answer = pregunta.answer.replace("ASADA_METODOS_PAGO", metodosPagoLista);
        }
      }
      return pregunta;
    });

    setPreguntas(preguntasActualizadas);
  }, [asada]);

  return (
    <>
      <motion.div exit="out" animate="in" initial="out" variants={pageVariants} transition={pageTransition}>
        <div className="container mx-auto px-4">
          <div className="text-center py-6 text-3xl lg:text-4xl">
            <div className="text-center">
              <h1>Preguntas Frecuentes sobre</h1>
              <h1 className="border-b pb-5">Pagos Electrónicos</h1>
            </div>
          </div>
          <p className="">
            En esta página, usted encontrará una sección de preguntas y respuestas que le ayudará a aclarar sus dudas
            sobre los pagos electrónicos procesados a través de <span className="font-bold">Placetopay</span>.
          </p>
          <div className="grid place-items-start my-4">{preguntas && <Accordion listaPreguntas={preguntas} />}</div>
        </div>
      </motion.div>
    </>
  );
}

export default PreguntasFrecuentes;
