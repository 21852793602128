import React, { useContext } from "react";
import InformesContext from "../../../context/informes/InformesContext";
import { motion } from "framer-motion";
import { FaDownload } from "react-icons/fa";

function Informes({ informes }) {
  const { downloadInformeDocumentClient } = useContext(InformesContext);

  const handleInformeDownload = (id) => downloadInformeDocumentClient(id); // Download informe

  return (
    <>
      <div className='text-center py-6 mt-8'>
        <motion.h2
          className='pb-5 text-3xl md:text-5xl lg:text-6xl'
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: {
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.5,
              },
            },
            hidden: { opacity: 0 },
          }}
        >
          Informes
        </motion.h2>
        <div className='flex justify-center px-2'>
          <motion.p
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
            variants={{
              visible: {
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.5,
                },
              },
              hidden: { opacity: 0 },
            }}
            className='max-w-xl'
          >
            Todos los informes presentados a las diferentes instituciones
            nacionales se encuentran disponibles en esta sección.
          </motion.p>
        </div>
      </div>
      <div className='grid lg:grid-cols-2 xl:grid-cols-3 sm:justify-center gap-5 px-12'>
        {informes.length !== 0 &&
          informes.map((informe, i) => {
            return (
              <motion.div
                className='card lg:card-side sm:card-side bg-base-100 shadow-xl '
                key={informe.id}
                initial='hidden'
                whileInView='visible'
                viewport={{ once: true }}
                transition={{ delay: i * 0.3 }}
                variants={{
                  visible: {
                    opacity: 1,
                  },
                  hidden: { opacity: 0 },
                }}
              >
                <div className='card-body max-h-64'>
                  <h2 className='card-title justify-center text-center'>
                    {informe.nombre}
                  </h2>
                  <p className='flex justify-center overflow-y-auto'>
                    {informe.descripcion}
                  </p>
                  <motion.div
                    className='justify-center mt-3 card-actions'
                    initial='hidden'
                    whileInView='visible'
                    viewport={{ once: true }}
                    transition={{ delay: 0.5 }}
                    variants={{
                      visible: {
                        opacity: 1,
                      },
                      hidden: { opacity: 0 },
                    }}
                  >
                    <div className='card-actions'>
                      <button
                        className='btn btn-accent btn-outline btn-sm capitalize'
                        onClick={() => {
                          handleInformeDownload(informe.id);
                        }}
                      >
                        Descargar&nbsp;
                        <FaDownload />
                      </button>
                    </div>
                  </motion.div>
                </div>
              </motion.div>
            );
          })}
      </div>
    </>
  );
}

export default Informes;
