import { createContext, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import LoginContext from "../login/LoginContext";
import FileDownload from "js-file-download";

import { toast } from "react-toastify";

const TransparenciaContext = createContext();

export const TransparenciaProvider = ({ children }) => {
  const { user } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(true);

  const [documentos, setDocumentos] = useState([]);
  const [documento, setDocumento] = useState({});
  const [transparencias, setTransparencias] = useState([]);
  const [transparencia, setTransparencia] = useState({});

  const params = useParams();
  const navigate = useNavigate();

  // Get all transparencias
  const getTransparencias = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/transparencias?aid=${asadaId}`);
    const data = await response.data;
    const transparencias = data.transparencias;
    setDocumentos(transparencias);
    setIsLoading(false);
  };

  // Get all transparencias
  const getAllTransparencias = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias?aid=${asadaId}`
          : `http://localhost:5050/api/v1/transparencias?aid=${asadaId}`,
        { withCredentials: true }
      );
      const data = await response.data;
      const transparencias = data.transparencias;
      setTransparencias(transparencias);
    } catch (error) {
      console.log("Error fetching data: ", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Get all transparencias private
  const getAllTransparenciasPrivate = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/private?aid=${asadaId}`
          : `http://localhost:5050/api/v1/transparencias/private?aid=${asadaId}`,
        { withCredentials: true }
      );
      const data = await response.data;
      const transparencias = data.transparencias;
      setTransparencias(transparencias);
    } catch (error) {
      console.log("Error fetching data: ", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Get single transparencia document
  const getSingleTransparencia = async (transparenciaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${transparenciaId}`
          : `http://localhost:5050/api/v1/transparencias/${transparenciaId}`,
        { withCredentials: true }
      );
      const data = await response.data;
      const transparencia = data.transparencia;
      setTransparencia(transparencia);
    } catch (error) {
      console.log("Error fetching data: ", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Download document as attachement
  const downloadTransparenciaDocument = async () => {
    setIsLoading(true);
    const { id } = params,
      download = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${id}/download`, {
        responseType: "blob",
      }),
      response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${id}`),
      file = await response.data.ddoc,
      fileName = file.split("-")[0],
      fileExt = file.split(".")[1];

    FileDownload(download.data, `${fileName}.${fileExt}`);
    setIsLoading(false);
  };

  // Download documento
  const downloadTransparenciaDocumentClient = async (id) => {
    setIsLoading(true);
    try {
      const download = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${id}/download`
          : `http://localhost:5050/api/v1/transparencias/${id}/download`,
        {
          responseType: "blob",
          withCredentials: true,
        }
      );
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${id}`
          : `http://localhost:5050/api/v1/transparencias/${id}`,
        { withCredentials: true }
      );
      const data = await response.data;
      const { file } = data.transparencia;

      FileDownload(download.data, `${file.split("/")[5]}`);
    } catch (error) {
      console.log("Error downloading transparencia document: ", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Display/show transparencia document in different tab
  const displayDocumento = async (transparenciaId) => {
    const response = await axios.get(
      process.env.REACT_APP_NODE_ENV === "production"
        ? `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${transparenciaId}/display`
        : `http://localhost:5050/api/v1/transparencias/${transparenciaId}/display`,
      {
        responseType: "blob",
        withCredentials: true,
      }
    );
    const data = await response.data;

    const file = new Blob([data], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  // Create transparencia document
  const createTransparencia = async (newTransparencia, asadaId) => {
    setIsLoading(true);
    try {
      let response;
      if (asadaId) {
        response = await axios.post(
          process.env.REACT_APP_NODE_ENV === "production"
            ? `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias?aid=${asadaId}`
            : `http://localhost:5050/api/v1/transparencias?aid=${asadaId}`,
          newTransparencia,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      } else {
        response = await axios.post(
          process.env.REACT_APP_NODE_ENV === "production"
            ? `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias?aid=${process.env.REACT_APP_ASADA_ID}`
            : `http://localhost:5050/api/v1/transparencias?aid=${process.env.REACT_APP_ASADA_ID}`,
          newTransparencia,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      }

      const data = await response.data;
      const transparencia = data.transparencia;
      setTransparencias(...transparencias, transparencia);
      toast.success(`Se ha agregado un nuevo documento de transparencia a la ASADA. Nombre: ${transparencia.nombre}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (asadaId) await getAllTransparenciasPrivate(asadaId);
    } catch (error) {
      console.log(error);
      toast.error(`Ha ocurrido un error al agregar el documento de transparencia. Error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Update transparencia admin
  const updateTransparenciaAdmin = async (newTransparencia, transparenciaId, restore) => {
    setIsLoading(true);
    try {
      const response = await axios.patch(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${transparenciaId}${
              restore ? "?restore=true" : ""
            }`
          : `http://localhost:5050/api/v1/transparencias/${transparenciaId}${restore ? "?restore=true" : ""}`,
        newTransparencia,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      const data = await response.data;
      const transparencia = data.transparencia;
      await getSingleTransparencia(transparenciaId);

      toast.info(`Documento de transparencia actualizado: "${transparencia.nombre}"`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(`Ha ocurrido un error al editar el documento de transparencia. Error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(`Error al actualizar documento de transparencia: ${newTransparencia}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Update documento
  const updateTransparencia = async (newTransparencia, transparenciaId) => {
    try {
      setIsLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${transparenciaId}`,
        newTransparencia,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = await response.data;
      const transparencia = await data.transparencia;

      // await getDocumentos();
      await getSingleTransparencia(transparenciaId);
      setIsLoading(false);
      toast.info(`Documento de transparencia actualizado: "${transparencia.nombre}"`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(`Error al actualizar documento: ${newTransparencia}. ${error}`);
    }
  };

  // Delete form
  const deleteTransparencia = async (transparenciaId, force) => {
    setIsLoading(true);
    try {
      await axios.delete(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/transparencias/${transparenciaId}${force ? "?force=true" : ""}`
          : `http://localhost:5050/api/v1/transparencias/${transparenciaId}${force ? "?force=true" : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      await getAllTransparencias(process.env.REACT_APP_ASADA_ID);

      toast.info(`Se ha borrado el registro del documento de transparencia`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      throw new Error(`Error al borrar documento de transparencia: ${documento.nombre}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <TransparenciaContext.Provider
      value={{
        documento,
        documentos,
        isLoading,
        setIsLoading,
        setDocumento,
        updateTransparencia,
        downloadTransparenciaDocument,
        downloadTransparenciaDocumentClient,
        displayDocumento,
        deleteTransparencia,
        getTransparencias,
        transparencias,
        transparencia,
        setTransparencia,
        setTransparencias,
        getAllTransparencias,
        createTransparencia,
        getSingleTransparencia,
        getAllTransparenciasPrivate,
        updateTransparenciaAdmin,
      }}
    >
      {children}
    </TransparenciaContext.Provider>
  );
};

export default TransparenciaContext;
