import { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LoginContext from "../login/LoginContext";
import { toast } from "react-toastify";
import axios from "axios";

const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const { user } = useContext(LoginContext);

  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState({});
  const [proyectos, setProyectos] = useState([]);
  const [proyecto, setProyecto] = useState({});

  const navigate = useNavigate();

  // Get all projects
  const getAllProyectos = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos?aid=${asadaId}`
          : `http://localhost:5050/api/v1/proyectos?aid=${asadaId}`,
        { withCredentials: true }
      );
      const data = await response.data;
      const proyectos = data.proyectos;
      setProyectos(proyectos);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Get all projects
  const getAllProyectosPrivate = async (asadaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos/private?aid=${asadaId}`
          : `http://localhost:5050/api/v1/proyectos/private?aid=${asadaId}`,
        { withCredentials: true }
      );
      const data = await response.data;
      const proyectos = data.proyectos;
      setProyectos(proyectos);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Get single proyecto
  const getSingleProyecto = async (proyectoId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos/${proyectoId}`
          : `http://localhost:5050/api/v1/proyectos/${proyectoId}`,
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      const proyecto = data.proyecto;
      setProyecto(proyecto);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Create a proyecto
  const createProyecto = async (newProyecto, asadaId) => {
    setIsLoading(true);
    try {
      let response;
      if (asadaId) {
        response = await axios.post(
          process.env.REACT_APP_NODE_ENV === "production"
            ? `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos?aid=${asadaId}`
            : `http://localhost:5050/api/v1/proyectos?aid=${asadaId}`,
          newProyecto,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      } else {
        response = await axios.post(
          process.env.REACT_APP_NODE_ENV === "production"
            ? `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos?aid=${process.env.REACT_APP_ASADA_ID}`
            : `http://localhost:5050/api/v1/proyectos?aid=${process.env.REACT_APP_ASADA_ID}`,
          newProyecto,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      }

      const data = await response.data;
      const proyecto = data.proyecto;

      setProyectos(...proyectos, proyecto);

      toast.success(`Nuevo proyecto agregado a la ASADA: ${proyecto.nombre}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (asadaId) await getAllProyectosPrivate(asadaId);
      else await getAllProyectos(process.env.REACT_APP_ASADA_ID);
    } catch (error) {
      console.log(error);
      toast.error(`Ha ocurrido un error al agregar el proyecto. Error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete photo
  const deleteProjectPhoto = async (fotoId, proyectoId) => {
    setIsLoading(true);
    try {
      await axios.delete(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/fotos/${fotoId}`
          : `http://localhost:5050/api/v1/fotos/${fotoId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      await getSingleProyecto(proyectoId);

      toast.info(`Foto borrada del proyecto`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      throw new Error(`Error al borrar foto del proyecto: ${project.name}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Update proyecto admin
  const updateProyectoAdmin = async (newProyecto, proyectoId, restore) => {
    setIsLoading(true);
    try {
      const response = await axios.patch(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos/${proyectoId}${restore ? "?restore=true" : ""}`
          : `http://localhost:5050/api/v1/proyectos/${proyectoId}${restore ? "?restore=true" : ""}`,
        newProyecto,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      const data = await response.data;
      const proyecto = data.proyecto;

      await getSingleProyecto(proyectoId);
      toast.info(`Proyecto actualizado.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.error(`Ha ocurrido un error al editar el proyecto. Error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(`Error al actualizar proyecto: ${newProyecto}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Update a proyecto
  const updateProyecto = async (proyecto, id) => {
    setIsLoading(true);
    try {
      const response = await axios.patch(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos/${id}`
          : `http://localhost:5050/api/v1/proyectos/${id}`,
        proyecto,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      const proyect = data.proyecto;
      setProyecto(proyect);
      toast.info(`Se ha actualizado la información del proyecto.`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      throw new Error(`Error al actualizar informacion de proyecto: ${proyecto.nombre}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete a proyecto
  const deleteProyecto = async (proyectoId, force) => {
    setIsLoading(true);
    try {
      await axios.delete(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_BASE_URL}/api/v1/proyectos/${proyectoId}${force ? "?force=true" : ""}`
          : `http://localhost:5050/api/v1/proyectos/${proyectoId}${force ? "?force=true" : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      await getAllProyectos(process.env.REACT_APP_ASADA_ID);

      toast.info(`Se ha borrado el registro del proyecto`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error(`Ha ocurrido un error al borrar el proyecto`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(`Error al borrar proyecto: ${proyecto.nombre}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        isLoading,
        setIsLoading,
        setProject,
        project,
        projects,
        deleteProjectPhoto,
        getAllProyectos,
        proyectos,
        proyecto,
        createProyecto,
        deleteProyecto,
        setProyecto,
        setProyectos,
        updateProyecto,
        getSingleProyecto,
        getAllProyectosPrivate,
        updateProyectoAdmin,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContext;
