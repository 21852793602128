import { useEffect, useContext, useState, useLayoutEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import JuntaDirectivaContext from "../../context/juntaDirectiva/JuntaDirectivaContext";
import LoginContext from "../../context/login/LoginContext";
import Modal from "../shared/Modal";
import Spinner from "../shared/Spinner";

function JuntaDirectivaItem() {
  const { integrante, getSingleIntegrante, setIntegrante, isLoading, deleteIntegrante, updateIntegrante } =
    useContext(JuntaDirectivaContext);
  const { user } = useContext(LoginContext);

  const [newName, setNewName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newPosition, setNewPosition] = useState("");
  const [newVigency, setNewVigency] = useState("");
  const [newPhoto, setNewPhoto] = useState("");
  const [forceDelete, setForceDelete] = useState(false);
  const [restore, setRestore] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const fetchIntegrante = async () => {
      await getSingleIntegrante(id);
    };
    fetchIntegrante();
  }, []);

  useEffect(() => {
    if (Object.keys(integrante).length > 0) {
      const shortDate = integrante.vigencia.split("T")[0];

      document.getElementById("grid-vigency").value = shortDate;
    }
  }, [integrante]);

  useLayoutEffect(() => {
    return () => {
      setIntegrante({});
    };
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "Se eliminará de la base de datos el registro del integrante de la Junta Directiva. \n ¿Seguro que desea continuar?"
      )
    ) {
      await deleteIntegrante(id, forceDelete);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fd = new FormData();

    // If name is not modified, use the current integrante's name
    if (newName === "") fd.append("nombre", integrante.nombre);
    // If is modified
    else fd.append("nombre", newName);

    if (newLastName === "") fd.append("apellidos", integrante.apellidos);
    else fd.append("apellidos", newLastName);

    if (newPosition === "") fd.append("cargo", integrante.cargo);
    else fd.append("cargo", newPosition);

    if (newVigency === "") fd.append("vigencia", integrante.vigencia);
    else fd.append("vigencia", newVigency);

    if (newPhoto !== "") fd.append("foto", newPhoto, newPhoto.name);
    else fd.append("foto", null);

    try {
      if (user.role === "admin") await updateIntegrante(fd, id, restore);
      else await updateIntegrante(fd, id);
    } catch (error) {
      console.log(error);
    }
  };

  const onNameChange = (e) => setNewName(e.target.value);
  const onLastNameChange = (e) => setNewLastName(e.target.value);
  const onPositionChange = (e) => setNewPosition(e.target.value);
  const onVigencyChange = (e) => setNewVigency(e.target.value);
  const onPhotoChange = (e) => setNewPhoto(e.target.files[0]);

  const handleDeleteChange = () => {
    setForceDelete((prev) => !prev);
  };
  const handleRestoreChange = () => {
    setRestore((prev) => !prev);
  };

  return (
    <div className="rounded-lg rounded-tl-none p-7 shadow-lg card bg-base-100 border-t-none ">
      <h2 className="text-2xl my-4 font-bold card-title">
        <button onClick={() => navigate(-1)} className="btn btn-outline btn-accent btn-sm hover:text-white">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        Editar información de integrante
      </h2>
      {integrante !== null || integrante !== undefined ? (
        <div className="text-lg flex justify-center">
          <form onSubmit={handleSubmit}>
            <div className="flex">
              <div className="flex-1 w-1/2">
                <div className="w-full px-3">
                  <label className="block tracking-wide text-xs font-bold mb-2" htmlFor="grid-name">
                    Nombre *
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-name"
                    type="text"
                    required
                    name="nombre"
                    placeholder="Nombre del miembro"
                    defaultValue={integrante.nombre}
                    onChange={onNameChange}
                  />
                </div>
                <div className="w-full px-3">
                  <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-desc">
                    Apellidos *
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-desc"
                    type="text"
                    required
                    name="apellidos"
                    placeholder="Apellidos del integrante"
                    defaultValue={integrante.apellidos}
                    onChange={onLastNameChange}
                  />
                </div>
                <div className="w-full px-3">
                  <label className="block tracking-wide text-xs font-bold mb-2" htmlFor="grid-vigency">
                    Vigencia *
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-vigency"
                    type="date"
                    required
                    name="vigencia"
                    placeholder="Vigencia"
                    defaultValue={integrante.vigencia}
                    onChange={onVigencyChange}
                  />
                </div>
                <div className="w-full px-3">
                  <label className="block tracking-wide  text-xs font-bold mb-2" htmlFor="grid-message">
                    Cargo *
                  </label>

                  <div className="form-control">
                    {integrante.cargo !== undefined && (
                      <select
                        className="select select-md select-accent w-full mb-2 "
                        defaultValue={integrante.cargo}
                        onChange={onPositionChange}
                      >
                        <option value={"presidente"}>Presidente</option>
                        <option value={"vicePresidente"}>Vice Presidente</option>
                        <option value={"secretario"}>Secretario(a)</option>
                        <option value={"tesorero"}>Tesorero(a)</option>
                        <option value={"fiscal"}>Fiscal</option>
                        <option value={"vocal"}>Vocal 1</option>
                      </select>
                    )}
                  </div>
                </div>
                <div className="w-full px-3 flex gap-2 mt-4">
                  {user && user.role === "admin" && (
                    <>
                      <input type="checkbox" id="force" className="h-5 w-5" onChange={handleDeleteChange} />
                      <label htmlFor="force" className="cursor-pointer tracking-wide  text-xs font-bold mb-2">
                        &nbsp;Borrar permanentemente
                      </label>

                      {integrante.deletedAt && (
                        <>
                          <input type="checkbox" id="restaurar" className="h-5 w-5" onChange={handleRestoreChange} />
                          <label htmlFor="restaurar" className="cursor-pointer tracking-wide  text-xs font-bold mb-2">
                            &nbsp;Restaurar
                          </label>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="flex-1 w-1/2">
                <div className="px-3 ">
                  <label className="block  tracking-wide  text-xs font-bold mb-2 " htmlFor="grid-photos">
                    Foto *
                  </label>
                  <div className="p-2 mb-3 h-[380px]">
                    {integrante.foto && (
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/uploads/${integrante.foto.split("/")[4]}`}
                        alt="Member photo"
                        className="object-contain h-full w-full"
                      />
                    )}
                  </div>
                  <div className="form-control">
                    <input
                      className="appearance-none  block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-photos"
                      type="file"
                      onChange={onPhotoChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="divider"></div>
            <div className="card-actions justify-start">
              <button
                className={`btn btn-success hover:bg-green-700 capitalize  ${isLoading ? "loading" : ""}`}
                type="submit"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                </svg>
                &nbsp;Guardar cambios
              </button>
              <button className="btn btn-error hover:bg-red-700 capitalize" type="button" onClick={handleDelete}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                &nbsp;Borrar Integrante
              </button>
              <button type="button" className="btn btn-ghost ml-2" onClick={openModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>
              </button>
            </div>
          </form>
          <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
            <div>
              <p className="font-light text-lg">Consideraciones</p>
              <ul className="list-disc">
                <li className="font-light text-sm my-1">Los campos requeridos están marcados con un asterisco (*).</li>
                <li className="font-light text-sm my-1">El nombre debe tener más de 3 y menos de 70 caracteres.</li>
                <li className="font-light text-sm my-1">
                  Los apellidos deben tener menos de 100 caracteres entre ambos.
                </li>
                <li className="font-light text-sm my-1">La foto debe ser en formato png, jpg, o jpeg.</li>
                <li className="font-light text-sm my-1">
                  Las dimensiones recomendadas para la foto son 580x680px (orientación vertical).
                </li>
                <li className="font-light text-sm my-1">
                  Para reemplazar la foto actual debe seleccionar una nueva foto y hacer clic en el botón 'Guardar
                  Cambios'.
                </li>
                {user && user.role === "admin" && (
                  <>
                    <li className="font-light text-sm my-1">
                      Para borrar un registro permanentemente de la base de datos marque la casilla 'Borrar
                      permanentemente' y luego presione el botón 'Borrar Integrante'. Para hacer un 'soft-delete'
                      desmarque la casilla.
                    </li>
                    <li className="font-light text-sm my-1">
                      Para restaurar un registro que fue 'soft-deleted' marque la casilla 'Restaurar' y luego presione
                      el botón 'Guardar Cambios'. Esta casilla solamente aparece cuando un registro ha sido
                      'soft-deleted'.
                    </li>
                  </>
                )}
              </ul>
            </div>
          </Modal>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default JuntaDirectivaItem;
