import { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LoginContext from "../login/LoginContext";
import { toast } from "react-toastify";
import axios from "axios";
import baseURL from '../../utils/baseUrl';

const AsadaContext = createContext();

export const AsadaProvider = ({ children }) => {
  const { user } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(true);
  const [asada, setAsada] = useState({});
  const [asadas, setAsadas] = useState([]);
  const [contactos, setContactos] = useState([]);
  const [contacto, setContacto] = useState({});
  const [facturas, setFacturas] = useState([]);
  const [detalles, setDetalles] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [nombreAbonado, setNombreAbonado] = useState('');

  const navigate = useNavigate();

  // Get asada
  const getAsada = async () => {
    setIsLoading(true);
    const response = await axios.get(`/api/asada`);
    const data = await response.data;
    await getAsadaContactos();
    setAsada(data);
    setIsLoading(false);
  };

  // Get single asada
  const getSingleAsada = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      process.env.REACT_APP_NODE_ENV === 'production'
        ? `${baseURL}/api/v1/asadas/${asadaId}`
        : `http://localhost:5050/api/v1/asadas/${asadaId}`
    );
    const data = await response.data;
    setAsada(data.asada);
    setIsLoading(false);
  };

  // Get single asada
  const getSingleAsadaByNumeroEmpresa = async (numEmpresa) => {
    setIsLoading(true);
    const response = await axios.get(`${baseURL}/api/v1/asadas/${numEmpresa}`);
    const data = await response.data;
    setAsada(data.asada);
    setIsLoading(false);
  };

  // Create an ASADA
  const createAsada = async (newAsada) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${baseURL}/api/v1/asadas`, newAsada, {
        withCredentials: true, // Include credentials (cookies) in the request
      });

      const { asada } = response.data;
      setAsadas((prevAsadas) => [...prevAsadas, asada]);

      return asada;
    } catch (error) {
      console.error(
        'Error creating ASADA:',
        error.response?.data?.message || error.message || 'Unknown error'
      );
      throw new Error(
        error.response?.data?.message || 'Failed to create ASADA'
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Get All Asadas (ADMIN)
  const getAllAsadas = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseURL}/api/v1/asadas`, {
        withCredentials: true, // Include credentials (cookies) in the request
      });

      const data = response.data;
      const asadas = data.asadas;
      setAsadas(asadas);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Get asada contactos
  const getContactos = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${baseURL}/api/v1/contactos?aid=${asadaId}`
    );
    const data = await response.data;
    setContactos(data.contactos);
    setIsLoading(false);
  };

  // Get asada contactos private
  const getAllContactosPrivate = async (asadaId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${baseURL}/api/v1/contactos/private?aid=${asadaId}`,
      {
        withCredentials: true,
      }
    );
    const data = await response.data;
    setContactos(data.contactos);
    setIsLoading(false);
  };

  // Get asada contactos
  const getAsadaContactos = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `${baseURL}/api/asada/${process.env.REACT_APP_ASADA_ID}/contactos`
    );
    const data = await response.data;
    setContactos(data);
    setIsLoading(false);
  };

  // Get asada contactos
  const getAllContactos = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `${baseURL}/api/v1/contactos?aid=${process.env.REACT_APP_ASADA_ID}`
    );
    const data = await response.data;
    const contactos = data.contactos;
    setContactos(contactos);
    setIsLoading(false);
  };

  // Get asada contacto
  const getAsadaContacto = async (contactoId) => {
    setIsLoading(true);
    const response = await axios.get(
      `${baseURL}/api/v1/contactos/${contactoId}`
    );
    const data = await response.data;
    const contacto = data.contacto;
    setContacto(contacto);
    setIsLoading(false);
  };

  const getAllFotosEstaticas = async (asadaId) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        process.env.REACT_APP_NODE_ENV === 'production'
          ? `${baseURL}/api/v1/asadas/${asadaId}/imagenes`
          : `http://localhost:5050/api/v1/asadas/${asadaId}/imagenes`
      );
      const { fotosEstaticas } = await res.data;
      setImagenes(fotosEstaticas);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Create asada contacto
  const createAsadaContacto = async (contacto, asadaId) => {
    try {
      setIsLoading(true);
      let response;
      if (asadaId) {
        response = await axios.post(
          `${baseURL}/api/v1/contactos?asadaId=${asadaId}`,
          contacto,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      } else {
        response = await axios.post(
          `${baseURL}/api/v1/contactos?asadaId=${process.env.REACT_APP_ASADA_ID}`,
          contacto,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  // Create asada image
  const createAsadaImagen = async (img, asadaId) => {
    const type = img.get('type');
    setIsLoading(true);
    try {
      await axios.post(
        process.env.REACT_APP_NODE_ENV === 'production'
          ? `${baseURL}/api/v1/asadas/${asadaId}/imagenes?type=${type}`
          : `http://localhost:5050/api/v1/asadas/${asadaId}/imagenes?type=${type}`,
        img,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }
      );
      toast.info(`Imagen agregada`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      toast.error(`Ha ocurrido un error: ${error}`, {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete asada image
  const deleteAsadaImagen = async (imageId) => {
    const asadaId = process.env.REACT_APP_ASADA_ID;
    setIsLoading(true);
    try {
      const response = await axios.delete(
        process.env.REACT_APP_NODE_ENV === 'production'
          ? `${baseURL}/api/v1/asadas/${asadaId}/imagenes/${imageId}`
          : `http://localhost:5050/api/v1/asadas/${asadaId}/imagenes/${imageId}`,
        {
          withCredentials: true, // Include credentials (cookies) in the request
        }
      );
      return response.status;
    } catch (error) {
      console.log(error);
      throw new Error(`Error al borrar foto de la Asada`);
    } finally {
      setIsLoading(false);
    }
  };

  // Patch asada
  const patchAsada = async (asada) => {
    setIsLoading(true);
    try {
      await axios.patch(`/api/asada/${process.env.REACT_APP_ASADA_ID}`, asada, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
      });

      await getAsada();
      setIsLoading(false);
      toast.info(`Se ha actualizado la información de la ASADA`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      throw new Error(`Error al actualizar informacion la ASADA`);
    }
  };

  // Patch asada contacto
  const patchAsadaContacto = async (contactoObj, contactoId, restore) => {
    setIsLoading(true);
    try {
      await axios.patch(
        `${baseURL}/api/v1/contactos/${contactoId}${
          restore ? '?restore=true' : ''
        }`,
        contactoObj,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      setIsLoading(false);
      toast.info(`Se ha actualizado la información del contacto`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error(`Error al actualizar informacion del contacto`);
    }
  };

  // Delete contacto
  const deleteAsadaContacto = async (contactoId, force) => {
    setIsLoading(true);
    try {
      await axios.delete(
        `${baseURL}/api/v1/contactos/${contactoId}${
          force ? '?force=true' : ''
        }`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      await getAllContactos(); // This automatically uses the AsadaId from .env

      toast.info(`Contacto borrado de la ASADA`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error(
        'Ha ocurrido un error al borrar contacto. Por favor contacte al administrador del sitio',
        {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
        }
      );
      throw new Error(`Error al borrar contacto: ${contacto}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete asada
  const deleteAsadaAdmin = async (asadaId, forceDelete) => {
    setIsLoading(true);
    try {
      const url = `${baseURL}/api/v1/asadas/${asadaId}${
        forceDelete ? '?force=true' : ''
      }`;
      const config = {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      };

      await axios.delete(
        `${baseURL}/api/v1/asadas/${asadaId}/${forceDelete} ? "?force=true" : ""}`,
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );
      await getAllAsadas(); // Actualizar el listado de asadas

      const toastMessage = forceDelete
        ? 'Se ha borrado permanentemente el registro de la ASADA'
        : 'Se ha borrado el registro de la ASADA';

      toast.info(toastMessage, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      navigate(-1); // Navegar hacia atrás
    } catch (error) {
      console.error(
        'Error al borrar ASADA:',
        error.response?.data?.message || error.message
      );
      toast.error(
        `Error al borrar ASADA: ${
          error.response?.data?.message || error.message
        }`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Update asada
  const updateAsada = async (asada) => {
    setIsLoading(true);
    try {
      const asadaResult = await axios.patch(
        process.env.REACT_APP_NODE_ENV === 'production'
          ? `${baseURL}/api/v1/asadas/${user.asadaId}`
          : `http://localhost:5050/api/v1/asadas/${user.asadaId}`,
        asada,
        {
          withCredentials: true,
        }
      );

      toast.info(`Se ha actualizado la información de la ASADA`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      throw new Error(`Error al actualizar informacion la ASADA ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Update asada
  const updateAsadaAdmin = async (asada, id, restore) => {
    setIsLoading(true);
    try {
      await axios.patch(
        process.env.REACT_APP_NODE_ENV === 'production'
          ? `${baseURL}/api/v1/asadas/${id}${restore ? '?restore=true' : ''}`
          : `http://localhost:5050/api/v1/asadas/${id}${
              restore ? '?restore=true' : ''
            }`,
        asada,
        {
          withCredentials: true,
        }
      );

      toast.info(`Se ha actualizado la información de la ASADA`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
      toast.info(`Ha ocurrido un error al actualizar la ASADA: ${error}`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(`Error al actualizar informacion la ASADA ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const getCISABuscarRecibosPendientes = async (abonado) => {
    let xmls = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Body>
          <Consulta_BuscarRecibosPendientes xmlns="https://www.cisaweb.com/AcueductosRecibos">
            <token>${sessionStorage.getItem('cisaToken')}</token>
            <empresa>${process.env.REACT_APP_ASADA_NUMBER}</empresa>
            <abonado>${abonado}</abonado>
          </Consulta_BuscarRecibosPendientes>
        </soap:Body>
      </soap:Envelope>`;

    const url =
      process.env.REACT_APP_CISA_ENV === 'production'
        ? 'https://www.cisaweb.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx?op=Consulta_BuscarRecibosPendientes'
        : 'https://shlcisa.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx?op=Consulta_BuscarRecibosPendientes';

    try {
      setIsLoading(true);
      const response = await fetch(url, {
        body: xmls,
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'text/xml; charset=UTF-8',
        },
      });

      const text = await response.text();

      let parser = new DOMParser();
      let xmlDoc = parser.parseFromString(text, 'application/xml');
      const textContent = xmlDoc.getElementsByTagName(
        'Consulta_BuscarRecibosPendientesResponse'
      )[0].textContent;

      if (textContent === '' || textContent.length === 0) {
        setIsLoading(false);
        setFacturas([]);
      } else {
        const facturas = Array.from(
          xmlDoc.getElementsByTagName('NewDataSet')[0].children
        ).map((item) => item.children);

        const facturasJSON = _fromHtmlCollectionToArray(facturas);

        setFacturas(facturasJSON);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCISANombreAbonado = async (abonado) => {
    setIsLoading(true);
    let xmls = `<?xml version="1.0" encoding="utf-8"?>
      <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Body>
          <Consulta_ObtenerNombre xmlns="https://www.cisaweb.com/AcueductosRecibos">
            <token>${sessionStorage.getItem('cisaToken')}</token>
            <empresa>${process.env.REACT_APP_ASADA_NUMBER}</empresa>
            <abonado>${abonado}</abonado>
          </Consulta_ObtenerNombre>
        </soap:Body>
      </soap:Envelope>`;

    const url =
      process.env.REACT_APP_CISA_ENV === 'production'
        ? 'https://www.cisaweb.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx?op=Consulta_ObtenerNombre'
        : 'https://shlcisa.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx?op=Consulta_ObtenerNombre';

    const response = await fetch(url, {
      body: xmls,
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'text/xml; charset=utf-8',
      },
    });
    const text = await response.text();
    let parser = new DOMParser();
    try {
      let xmlDoc = parser.parseFromString(text, 'application/xml');
      const textContent = xmlDoc.getElementsByTagName(
        'Consulta_ObtenerNombreResult'
      )[0].textContent;

      if (textContent === '' || textContent.length === 0) {
        setIsLoading(false);
        setNombreAbonado('Sin nombre');
      } else {
        setNombreAbonado(textContent);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* 
    <emp_codigo>7953</emp_codigo>
    <emp_nombre>Santa Elena de Monteverde</emp_nombre>
  */

  const getCISAReciboDetalle = async (abonado, factura) => {
    let xmls = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Body>
        <Consulta_Pendientes_Detalle xmlns="https://www.cisaweb.com/AcueductosRecibos">
          <token>${sessionStorage.getItem('cisaToken')}</token>
          <empresa>${process.env.REACT_APP_ASADA_NUMBER}</empresa>
          <abonado>${abonado}</abonado>
          <factura>${factura}</factura>
        </Consulta_Pendientes_Detalle>
      </soap:Body>
    </soap:Envelope>`;

    const url =
      process.env.REACT_APP_CISA_ENV === 'production'
        ? 'https://www.cisaweb.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx?op=Consulta_Pendientes_Detalle'
        : 'https://shlcisa.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx?op=Consulta_Pendientes_Detalle';

    const response = await fetch(url, {
      body: xmls,
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'text/xml; charset=utf-8',
      },
    });

    const text = await response.text();
    const parser = new DOMParser();
    try {
      const xmlDoc = parser.parseFromString(text, 'text/xml');
      const detalles = Array.from(
        xmlDoc.getElementsByTagName('NewDataSet')[0].children
      )
        .map((item) => item.children)
        .map((item) => item);

      const arr = [];
      detalles.forEach((item) => {
        arr.push({
          rubro: item[0].textContent,
          descripcion: item[1].textContent,
          valor: item[2].textContent,
          tipo: item[3].textContent,
        });
      });

      setDetalles(arr);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCISAWebToken = async () => {
    let xmls = `<?xml version="1.0" encoding="utf-8"?>
      <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Body>
          <Token_CrearToken xmlns="https://www.cisaweb.com/AcueductosRecibos">
            <usuario>usuario_cisa</usuario>
            <clave>F6DD8281-95D6-497A-9E8A-C631E5B1D215</clave>
          </Token_CrearToken>
        </soap:Body>
      </soap:Envelope>`;

    const url =
      process.env.REACT_APP_CISA_ENV === 'production'
        ? 'https://www.cisaweb.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx?op=Token_CrearToken'
        : 'https://shlcisa.com/WSAcueductosRecibos/WSAcueductosRecibos.asmx?op=Token_CrearToken';

    try {
      const response = await fetch(url, {
        body: xmls,
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'text/xml; charset=utf-8',
        },
      });

      const text = await response.text();

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(text, 'text/xml');
      const token = xmlDoc.getElementsByTagName('Token_CrearTokenResult')[0]
        .textContent;

      // Save the token in session storage
      sessionStorage.setItem('cisaToken', token);
    } catch (error) {
      console.log(error);
    }
  };

  const _fromHtmlCollectionToArray = (arr) => {
    const newArr = [];
    arr.forEach((item) => {
      const factura = {
        factura: item[0].textContent,
        mes: item[1].textContent,
        ano: item[2].textContent,
        periodo_char: item[3].textContent,
        periodo: item[4].textContent,
        vencimiento: item[5].textContent,
        monto: item[6].textContent,
        total_factura: item[7].textContent,
        facturacion: item[8].textContent,
        estado: item[9].textContent,
      };

      newArr.push(factura);
    });

    return newArr;
  };

  const getMetodosDePagoAsada = async () => {
    const url = `${
      process.env.REACT_APP_NODE_ENV === 'development'
        ? 'http://localhost:5050'
        : baseURL
    }/api/v1/asadas/${process.env.REACT_APP_ASADA_ID}/metodos-pago`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.data;
    return data.metodosDePago;
  };

  const createMetodoDePagoAsada = async (asadaId, metodoPagoId) => {
    const url = `${
      process.env.REACT_APP_NODE_ENV === 'development'
        ? 'http://localhost:5050'
        : baseURL
    }/api/v1/asadas/${asadaId}/metodos-pago`;

    const response = await axios.post(
      url,
      { metodoPagoId },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    const data = await response.data;
    return data.metodosDePagoAsada;
  };

  const deleteMetodoDePagoAsada = async (asadaId, metodoPagoId) => {
    const url = `${
      process.env.REACT_APP_NODE_ENV === 'development'
        ? 'http://localhost:5050'
        : baseURL
    }/api/v1/asadas/${asadaId}/metodos-pago/${metodoPagoId}`;

    const response = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.data;
    return data.message;
  };

  return (
    <AsadaContext.Provider
      value={{
        asada,
        getAsada,
        facturas,
        setFacturas,
        updateAsada,
        isLoading,
        setIsLoading,
        getCISAWebToken,
        getCISABuscarRecibosPendientes,
        getCISANombreAbonado,
        getCISAReciboDetalle,
        nombreAbonado,
        detalles,
        setDetalles,
        createAsadaContacto,
        contactos,
        setContactos,
        getAsadaContactos,
        getAsadaContacto,
        patchAsada,
        createAsada,
        deleteAsadaAdmin,
        contacto,
        setContacto,
        patchAsadaContacto,
        deleteAsadaContacto,
        imagenes,
        createAsadaImagen,
        deleteAsadaImagen,
        getSingleAsada,
        getContactos,
        getAllFotosEstaticas,
        getAllContactos,
        asadas,
        setAsadas,
        getAllAsadas,
        setAsada,
        updateAsadaAdmin,
        getAllContactosPrivate,
        getSingleAsadaByNumeroEmpresa,
        getMetodosDePagoAsada,
        createMetodoDePagoAsada,
        deleteMetodoDePagoAsada,
      }}
    >
      {children}
    </AsadaContext.Provider>
  );
};

export default AsadaContext;
