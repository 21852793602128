import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useContext, useEffect, useState } from 'react';
import AsadaContext from '../../context/asada/asadaContext';
import Spinner from '../shared/Spinner';
import baseUrl from '../../utils/baseUrl';

function ClientLayout() {
  const { asada, getSingleAsada } = useContext(AsadaContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSingleAsada = async (asadaId) => {
      if (Object.keys(asada).length === 0) await getSingleAsada(asadaId);
    };
    fetchSingleAsada(process.env.REACT_APP_ASADA_ID);
  }, []);

  useEffect(() => {
    if (Object.keys(asada).length !== 0) {
      const fetchColorScheme = async () => {
        try {
          const idAsada = asada.id;
          const response = await fetch(
            `${baseUrl}/api/v1/asadas/${idAsada}/colorscheme`
          );

          if (!response.ok) {
            console.log(`HTTP error! status: ${response.status}`);
            return;
          }

          const data = await response.json();
          const currentTheme = localStorage.getItem('theme') || 'defecto';
          const { esquema_colores: newTheme } = data;

          // Verifica si el esquema de colores ha cambiado
          if (newTheme && currentTheme !== newTheme) {
            localStorage.setItem('theme', newTheme);
            document.querySelector('html').setAttribute('data-theme', newTheme);
          } else {
            document
              .querySelector('html')
              .setAttribute('data-theme', currentTheme);
          }
        } catch (error) {
          console.error('Error fetching color scheme:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchColorScheme();
    }
  }, [asada]);

  if (loading) {
    return (
      <div className="grid place-items-center h-screen">
        <Spinner />;
      </div>
    );
  }

  return (
    <>
      <Navbar asada={asada} />
      <main className="mx-auto">
        <Outlet asada={asada} />
      </main>
      <Footer asada={asada} />
    </>
  );
}

export default ClientLayout;
